// alertUtils.ts
import { encryptAES } from "../../srp-lib/srp";

interface AlertData {
  title: string;
  description: string;
  recipients: { address: string; buildId: string; communityId: string }[];
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  activeButtons: string[];
}

export async function submitAlert(data: AlertData, onAlertCreated: () => Promise<void>) {
  const { title, description, recipients, startDate, endDate, startTime, endTime, activeButtons } = data;

  const startDateTimeISO = new Date(`${startDate}T${startTime}`).toISOString();
  const endDateTimeISO = new Date(`${endDate}T${endTime}`).toISOString();

  const savedKKey = localStorage.getItem("K_Key");
  const savedToken = localStorage.getItem("authToken");

  if (!savedKKey || !savedToken) {
    throw new Error("Missing encryption key or token.");
  }

  const encryptedData = {
    communityId: encryptAES(savedKKey, recipients[0]?.communityId || ""),
    title: encryptAES(savedKKey, title),
    description: encryptAES(savedKKey, description),
    startingAt: encryptAES(savedKKey, startDateTimeISO),
    endingAt: encryptAES(savedKKey, endDateTimeISO),
    alertTypes: activeButtons.map((button) => encryptAES(savedKKey, button)),
    buildings: recipients.map((recipient) => encryptAES(savedKKey, recipient.buildId)),
  };

  const response = await fetch("https://mileva.rs/admin/alert/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: savedToken,
      "community-id": encryptedData.communityId || "",
    },
    body: JSON.stringify({
      title: encryptedData.title,
      description: encryptedData.description,
      starting_at: encryptedData.startingAt,
      ending_at: encryptedData.endingAt,
      alert_types: encryptedData.alertTypes,
      buildings: encryptedData.buildings,
    }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message || 'Failed to submit alert');
  }

  await onAlertCreated();
  return responseData;
}