// src/services/api/AuthApi.ts

import { ApiService } from '../ApiService';
import { decrypt, encryptAES } from '../../../src/pages/srp-lib/srp';

// Интерфейсы для сырых ответов API
export interface SignInStep1Response {
  s: string;  // base64
  B: string;  // base64
}

export interface SignInStep2Response {
  HAMK: string;      // base64
  tmp_token: string;
  timeout: string;   // зашифровано
  status: string;    // зашифровано
}

export interface OtpVerificationResponse {
  token: string;
  status: string;    // зашифровано
}

export interface ResendOtpResponse {
  tmp_token: string;
  timeout: string;   // зашифровано
  status: string;    // зашифровано
}


// Интерфейсы для расшифрованных ответов
export interface DecryptedSignInStep2Response {
  HAMK: string;
  tmp_token: string;
  timeout: number;
  status: string;
}

export interface DecryptedOtpVerificationResponse {
  token: string;
  status: string;
}

export interface DecryptedResendOtpResponse {
  tmp_token: string;
  timeout: number;
  status: string;
}

export interface SignInStep2Params {
  phoneNumber: string;
  M: string;
  K_Key: string;
}

// Возможные статусы ответов
export enum AuthStatus {
  // Общие статусы
  BAD_PHONE = 'BAD_PHONE',
  AUTH_FAILED = 'AUTH_FAILED',
  STILL_THROTTLED = 'STILL_THROTTLED',
  OTP_SENDED = 'OTP_SENDED',
  THROTTLED = 'THROTTLED',
  OTP_NOT_EXPIRED = 'OTP_NOT_EXPIRED',
  
  // Статусы OTP
  NO_TMP_SESSION = 'NO_TMP_SESSION',
  BAD_ENCRYPTION = 'BAD_ENCRYPTION',
  SESSION_REVOKED = 'SESSION_REVOKED',
  OTP_EXPIRED = 'OTP_EXPIRED',
  ATTEMPTS_WASTED = 'ATTEMPTS_WASTED',
  OTP_WRONG = 'OTP_WRONG',
  OTP_APPROVED = 'OTP_APPROVED',
  
  // Статусы повторной отправки
  AUTH_EXPIRED = 'AUTH_EXPIRED',
  OTP_RESENDED = 'OTP_RESENDED'
}

// Константы для ошибок
export const AUTH_ERRORS = {
  THROTTLED: 'Слишком частые запросы (не чаще одного раза в 10 секунд)',
  BAD_REQUEST: 'Неверный метод запроса',
  MISSING_CREDENTIALS: 'Отсутствуют обязательные поля',
  BAD_PHONE_FORMAT: 'Неверный формат номера телефона',
  NO_SESSION_FOUND: 'Сессия не найдена',
  BAD_ENCRYPTION: 'Ошибка шифрования',
  NO_TMP_SESSION: 'Временная сессия не найдена',
  OTP_EXPIRED: 'Код подтверждения истек',
  ATTEMPTS_WASTED: 'Превышено количество попыток ввода кода',
  OTP_WRONG: 'Неверный код подтверждения',
  AUTH_EXPIRED: 'Время авторизации истекло'
};

export class AuthApi {
  private static getKKey(): string | null {
    return localStorage.getItem("K_Key");
  }

  private static getAuthToken(): string | null {
    return localStorage.getItem("authToken");
  }

  // Шаг 1 авторизации SRP
  static async signInStep1(phoneNumber: string, A: string): Promise<SignInStep1Response> {
    const response = await ApiService.request<SignInStep1Response>({
      url: "https://mileva.rs/admin/auth/signin/step1",
      method: 'POST',
      body: {
        phone_number: phoneNumber,
        A: A
      },
      requiresAuth: false
    });

    if (!response.success || !response.data) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }

    return response.data;
  }

  // Шаг 2 авторизации SRP
  static async signInStep2({ phoneNumber, M, K_Key }: SignInStep2Params): Promise<DecryptedSignInStep2Response> {
    if (!K_Key) {
      throw new Error('K_Key is required for step 2');
    }

    const response = await ApiService.request<SignInStep2Response>({
      url: "https://mileva.rs/admin/auth/signin/step2",
      method: 'POST',
      body: {
        phone_number: phoneNumber,
        M: M
      },
      requiresAuth: false
    });

    if (!response.success || !response.data) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }

    const decryptedTimeout = decrypt(K_Key, response.data.timeout, 'timeout');
    const decryptedStatus = decrypt(K_Key, response.data.status, 'status');

    if (!decryptedStatus) {
      throw new Error('Phone number or password is incorrect');
    }

    return {
      HAMK: response.data.HAMK,
      tmp_token: response.data.tmp_token,
      timeout: parseInt(decryptedTimeout || '300', 10),
      status: decryptedStatus
    };
  }

  // Подтверждение OTP кода
  static async confirmOtp(
    tmpToken: string, 
    otpCode: string, 
    K_Key: string
  ): Promise<DecryptedOtpVerificationResponse> {
    if (!K_Key) {
      throw new Error('K_Key is required for OTP confirmation');
    }
  
    const encryptedOtp = encryptAES(K_Key, otpCode);
    if (!encryptedOtp) {
      throw new Error('Failed to encrypt OTP code');
    }
  
    const response = await ApiService.request<OtpVerificationResponse>({
      url: "https://mileva.rs/admin/auth/confirm",
      method: 'POST',
      body: {
        tmp_token: tmpToken,
        otp_code: encryptedOtp
      },
      requiresAuth: false
    });
  
    if (!response.success || !response.data) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  
 
    const decryptedStatus = decrypt(K_Key, response.data.status, 'status');
    if (!decryptedStatus) {
      throw new Error('Failed to decrypt status');
    }
  
    // Обработка различных статусов
    switch (decryptedStatus) {
      case AuthStatus.OTP_EXPIRED:
        throw new Error('Code has expired. Please request a new one');
      case AuthStatus.ATTEMPTS_WASTED:
        throw new Error('Too many attempts. Please request a new code');
      case AuthStatus.OTP_WRONG:
        throw new Error('Invalid code. Please try again');
      case AuthStatus.NO_TMP_SESSION:
        throw new Error('Session expired. Please log in again');
    }
  
    return {
      token: response.data.token,
      status: decryptedStatus
    };
  }
  
  static async resendOtp(
    tmpToken: string, 
    K_Key: string
  ): Promise<DecryptedResendOtpResponse> {
    if (!K_Key) {
      throw new Error('K_Key is required for OTP resend');
    }
  
    const response = await ApiService.request<ResendOtpResponse>({
      url: "https://mileva.rs/admin/auth/resend",
      method: 'POST',
      body: {
        tmp_token: tmpToken
      },
      requiresAuth: false
    });
  
    if (!response.success || !response.data) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  
    

    const decryptedTimeout = decrypt(K_Key, response.data.timeout, 'timeout');
    const decryptedStatus = decrypt(K_Key, response.data.status, 'status');
      
    if (!decryptedStatus) {
      throw new Error('Failed to decrypt status');
    }
  
    // Обработка различных статусов
    switch (decryptedStatus) {
      case AuthStatus.STILL_THROTTLED:
        throw new Error('Please wait before requesting a new code');
      case AuthStatus.AUTH_EXPIRED:
        throw new Error('Authorization expired. Please log in again');
      case AuthStatus.NO_TMP_SESSION:
        throw new Error('Session expired. Please log in again');
    }
  
    return {
      tmp_token: response.data.tmp_token,
      timeout: parseInt(decryptedTimeout || '300', 10),
      status: decryptedStatus
    };
  }

  // Восстановление пароля
  static async recoverPassword(phoneNumber: string): Promise<void> {
    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/auth/passrecovery",
      method: 'POST',
      body: {
        phone_number: phoneNumber
      },
      requiresAuth: false
    });

    if (!response.success) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  }

  // Изменение пароля
  static async changePassword(phoneNumber: string): Promise<void> {
    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/auth/passchange",
      method: 'POST',
      body: {
        phone_number: phoneNumber
      },
      requiresAuth: false
    });

    if (!response.success) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  }

  // Выход из системы
  static async signOut(token?: string): Promise<void> {
    const currentToken = token || this.getAuthToken();
    if (!currentToken) {
      throw new Error('No auth token available');
    }

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/auth/signout",
      method: 'POST',
      body: {
        token: currentToken
      },
      requiresAuth: false
    });

    if (!response.success) {
      const errorMessage = AUTH_ERRORS[response.error as keyof typeof AUTH_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }

    // Очищаем локальное хранилище после успешного выхода
    localStorage.removeItem("authToken");
    localStorage.removeItem("K_Key");
  }
}