import crypto from "crypto";

const NGConstants = {
  N_2048: BigInt(
    "0xAC6BDB41324A9A9BF166DE5E1389582FAF72B6651987EE07FC3192943DB56050A37329CBB4A099ED8193E0757767A13DD52312AB4B03310DCD7F48A9DA04FD50E8083969EDB767B0CF6095179A163AB3661A05FBD5FAAAE82918A9962F0B93B855F97993EC975EEAA80D740ADBF4FF747359D041D5C33EA71D281E446B14773BCA97B43A23FB801676BD207A436C6481F1D2B9078717461A5B9D32E688F87748544523B524B0D57D5EA77A2775D2ECFA032CFBDBF52FB3786160279004E57AE6AF874E7303CE53299CCC041C7BC308D82A5698F3A8D0C38271AE35F8E9DBFBB694B5C803D89F7AE435DE236D525F54759B65E372FCD68EF20FA7111F9E4AFF73"
  ),
  g: BigInt(2),
};

let _noUsernameInX = false;
let _rfc5054Compat = false;

function rfc5054Enable(enable: boolean = true): void {
  _rfc5054Compat = enable;
}

// Функция для преобразования числа в байты
function longToBytes(n: bigint): Buffer {
  if (n === BigInt(0)) {
    return Buffer.from([0]); // Handle 0 explicitly
  }

  const byteArray: number[] = [];
  while (n > 0) {
    byteArray.unshift(Number(n & BigInt(0xff)));
    n >>= BigInt(8);
  }
  return Buffer.from(byteArray);
}

// Helper function to convert a string to a Buffer
function toBytes(input: string | Buffer): Buffer {
  return typeof input === "string" ? Buffer.from(input, "utf-8") : input;
}

function bytesToLong(bytes: Uint8Array): bigint {
  let result = BigInt(0);
  for (const byte of bytes) {
    result = (result << BigInt(8)) | BigInt(byte);
  }
  return result;
}

function H(
  hashAlgorithm: string,
  args: (bigint | Buffer)[],
  width?: number
): Buffer {
  const hash = crypto.createHash(hashAlgorithm);

  for (const arg of args) {
    let data: Buffer;
    if (typeof arg === "bigint") {
      data = longToBytes(arg);
    } else {
      data = arg;
    }

    if (width !== undefined && _rfc5054Compat) {
      const padding = Buffer.alloc(width - data.length);
      hash.update(padding);
    }

    hash.update(data);
  }

  return hash.digest();
}

function modPow(base: bigint, exp: bigint, mod: bigint): bigint {
  if (mod <= BigInt(0)) {
    throw new Error("Modulo must be greater than zero.");
  }

  let result = BigInt(1);
  base = ((base % mod) + mod) % mod; // Приведение base к положительному значению в пределах [0, mod)

  while (exp > BigInt(0)) {
    if (exp % BigInt(2) === BigInt(1)) {
      result = (result * base) % mod;
    }
    exp = exp >> BigInt(1); // Деление exp на 2
    base = (base * base) % mod;
  }

  return result;
}


function genX(
  hashAlgorithm: string,
  salt: Buffer,
  username: string,
  password: string
): bigint {
  const usernameBytes = _noUsernameInX ? Buffer.alloc(0) : toBytes(username);
  const passwordBytes = toBytes(password);

  // Hash username and password as "username:password"
  const userPassHash = H(hashAlgorithm, [
    Buffer.concat([usernameBytes, Buffer.from(":", "utf-8"), passwordBytes]),
  ]);

  // Hash the salt and userPassHash to compute x
  return bytesToLong(H(hashAlgorithm, [salt, userPassHash]));
}


function HNxorg(hashAlgorithm: string, N: bigint, g: bigint, rfc5054Compat: boolean = false): Buffer {
  const binN = longToBytes(N); // Convert N to bytes
  const binG = longToBytes(g); // Convert g to bytes

  // Add padding if RFC 5054 compatibility is enabled
  const padding = rfc5054Compat ? Buffer.alloc(binN.length - binG.length) : Buffer.alloc(0);

  const hN = crypto.createHash(hashAlgorithm).update(binN).digest(); // Hash of N
  const hg = crypto.createHash(hashAlgorithm).update(Buffer.concat([padding, binG])).digest(); // Hash of g with padding

  // XOR hN and hg
  const xorResult = Buffer.alloc(hN.length);
  for (let i = 0; i < hN.length; i++) {
    xorResult[i] = hN[i] ^ hg[i];
  }

  return xorResult; 
}

// Main calculate_M function
function calculateM(
  hashAlgorithm: string,
  N: bigint,
  g: bigint,
  I: string,
  s: Buffer,
  A: bigint,
  B: bigint,
  K: Buffer
): Buffer {
  const hash = crypto.createHash(hashAlgorithm);
 
  hash.update(HNxorg(hashAlgorithm, N, g));  
  hash.update(crypto.createHash(hashAlgorithm).update(I, "utf-8").digest());
  hash.update(s);
  hash.update(longToBytes(A));
  hash.update(longToBytes(B));
  hash.update(K);

  return hash.digest();
}



function calculateHAMK(
  hashAlgorithm: string,
  A: bigint,
  M: Buffer,
  K: Buffer
): Buffer {
  const hash = crypto.createHash(hashAlgorithm);

  hash.update(longToBytes(A));
  hash.update(M);
  hash.update(K);
  
  return hash.digest();
}

export class User {
  private N: bigint;
  private g: bigint;
  private k: bigint;
  private a: bigint;
  private A: bigint;
  private I: string;
  private p: string;
  private x!: bigint;
  private u!: bigint;
  private K!: Buffer;
  private S!: bigint;
  private v!: bigint;
  private H_AMK!: Buffer;
  private M!: Buffer;

  constructor(username: string, password: string) {
    this.N = NGConstants.N_2048;
    this.g = NGConstants.g;   
    this.k = bytesToLong(H("sha256", [this.N, this.g], longToBytes(this.N).length));     
    this.a = BigInt(`0x${crypto.randomBytes(256).toString("hex")}`) % this.N ;
    this.A = modPow(this.g, this.a, this.N);    
    this.I = username; 
    this.p = password; 
  }
 
  public getPrivateEphemeral(): bigint {
    return this.a;
  }

  public getPublicEphemeral(): bigint {
    return this.A;
  }

  public getUsername(): string {
    return this.I;
  }

  public getSessionKey(): Buffer {
    return this.K;
  }

  public processChallenge(s: Buffer, B: bigint): Buffer {
    if (B % this.N === BigInt(0)) {
      throw new Error("SRP-6a safety check failed.");
    }

    const width = longToBytes(this.N).length;
    this.u = bytesToLong(H("sha256", [this.A, B], width));    
    this.x = genX("sha256", s, this.I, this.p);   
    this.v = modPow(this.g, this.x, this.N);   
    this.S = modPow((B - this.k*this.v), (this.a + this.u*this.x), (this.N));    
    this.K = H("sha256", [this.S]);    
    this.M = calculateM("sha256", this.N, this.g, this.I, s, this.A, B, this.K); 

    return this.M;
  }
 
  public verifySession(HAMKServer: Buffer): boolean {     
    this.H_AMK = calculateHAMK("sha256", this.A, this.M, this.K);   
    return this.H_AMK.equals(HAMKServer);
  }
}

// PKCS7 unpadding function
function unpadPKCS7(data: string): string {
  const padLen = data.charCodeAt(data.length - 1);
  return data.slice(0, -padLen);
}

// Decrypt Function for AES-128 GCM
export function decrypt(K_Key:string, value: string, msg?: string): string | undefined {
  const K = Buffer.from(K_Key, "base64");
  if (!K) {
    console.error("Session key not available");
    return undefined;
  }

  
  // Pad or truncate the session key to 16 bytes (128 bits)
  const paddedKey = Buffer.concat([K, Buffer.alloc(16)]).subarray(0, 16);

  try {    
    const rawData = Buffer.from(value, "base64");
    if (rawData.length < 32) {
      throw new Error("Invalid encrypted data length");
    }

    // Extract nonce, tag, and ciphertext
    const nonce = rawData.subarray(0, 16); // First 16 bytes: nonce
    const tag = rawData.subarray(16, 32); // Next 16 bytes: tag
    const ciphertext = rawData.subarray(32); // Remaining bytes: ciphertext

    // Create decipher for AES-128-GCM
    const decipher = crypto.createDecipheriv("aes-128-gcm", paddedKey, nonce);
    decipher.setAuthTag(tag);
   
    let decrypted = decipher.update(ciphertext, undefined, "utf8");
    decrypted += decipher.final("utf8");
  
    if (msg === "timeout" || msg === "status") {
      return unpadPKCS7(decrypted);
    }
    return decrypted;

  } catch (error) {    
    return undefined; //Decryption failed
  }
}





// Encrypt Function
export function encryptAES(K_Key: string, value: string): string | null {
  if (!K_Key) {
    console.error("K_Key is not provided.");
    return null;
  }

  try {
    // Decode the Base64-encoded K_Key to a Buffer
    const K = Buffer.from(K_Key, "base64");

    // Pad or truncate the key to ensure it's 16 bytes (128 bits)
    const paddedKey = Buffer.concat([K, Buffer.alloc(16)]).subarray(0, 16);

    // Generate a random nonce
    const nonce = crypto.randomBytes(16);

    // Create cipher using AES-128-GCM
    const cipher = crypto.createCipheriv("aes-128-gcm", paddedKey, nonce);

    // Encrypt the value
    const ciphertext = Buffer.concat([cipher.update(value, "utf8"), cipher.final()]);

    // Get the authentication tag
    const tag = cipher.getAuthTag();

    // Concatenate nonce, tag, and ciphertext
    const result = Buffer.concat([nonce, tag, ciphertext]);

    // Return the result as a Base64-encoded string
    return result.toString("base64");
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
}
