// alertEditorConfig.ts
export interface DateTimeParams {
    activeButtons: string[];
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    formatDate: (date: string) => string;
  }
  
  // Конфигурация для Description
  export const DESCRIPTION = {
    template: `Dear tenants, we would like to inform you about a scheduled [type] affecting your address from [start] and is expected to be completed by [end].
    
Thank you for your cooperation!
Best regards,
The Building Management Team`,
    placeholder: "Enter your text here...",
    createParameters: ({
      activeButtons,
      startDate,
      startTime,
      endDate,
      endTime,
      formatDate
    }: DateTimeParams) => [
      {
        key: "type",
        value: activeButtons.length > 0 ? activeButtons.join(", ") : "[type]"
      },
      {
        key: "start",
        value: startDate && startTime ? `${formatDate(startDate)} ${startTime}` : "[start]"
      },
      {
        key: "end",
        value: endDate && endTime ? `${formatDate(endDate)} ${endTime}` : "[end]"
      }
    ]
  };
  
  // Конфигурация для Title
  export const TITLE = {
    template: "[type] on [start]",
    placeholder: "Enter title here...",
    createParameters: ({
      activeButtons,
      startDate,
      startTime,
      endDate,
      endTime,
      formatDate
    }: DateTimeParams) => [
      {
        key: "type",
        value: activeButtons.length > 0 ? activeButtons.join(", ") : "[type]"
      },
      {
        key: "start",
        value: startDate && startTime ? `${formatDate(startDate)} ${startTime}` : "[start]"
      },
      {
        key: "end",
        value: endDate && endTime ? `${formatDate(endDate)} ${endTime}` : "[end]"
      }
    ]
  };