// src/utils/phoneInputHandlers.ts
export class PhoneInputHandler {
    static handlePhoneKeyDown(
      e: React.KeyboardEvent<HTMLInputElement>,
      phoneNumber: string,
      setPhoneNumber: (value: string) => void
    ): void {
      const input = e.target as HTMLInputElement;
      const selectionStart = input.selectionStart || 0;
      const selectionEnd = input.selectionEnd || 0;
  
      if (
        (e.key === 'ArrowLeft' && selectionStart === 1) ||
        (e.key === 'Backspace' && selectionStart === 1)
      ) {
        e.preventDefault();
        input.setSelectionRange(1, 1);
        return;
      }
  
      if (e.key === 'Backspace' && selectionStart === 1 && phoneNumber.startsWith('+')) {
        e.preventDefault();
        return;
      }
  
      if (
        e.key === 'Delete' &&
        selectionStart === 0 &&
        phoneNumber.startsWith('+') &&
        (selectionEnd === 1 || phoneNumber.length === 1)
      ) {
        e.preventDefault();
        input.setSelectionRange(1, 1);
        return;
      }
  
      this.handleDelete(e, input, phoneNumber, setPhoneNumber);
    }
  
    private static handleDelete(
      e: React.KeyboardEvent<HTMLInputElement>,
      input: HTMLInputElement,
      phoneNumber: string,
      setPhoneNumber: (value: string) => void
    ): void {
      const selectionStart = input.selectionStart || 0;
      const selectionEnd = input.selectionEnd || 0;
  
      if (e.key === 'Backspace' && phoneNumber.length > 1 && selectionStart > 1) {
        const updatedPhoneNumber =
          phoneNumber.slice(0, selectionStart - 1) + phoneNumber.slice(selectionEnd);
  
        e.preventDefault();
  
        const cleanedPhoneNumber = updatedPhoneNumber.endsWith('-')
          ? updatedPhoneNumber.slice(0, -1)
          : updatedPhoneNumber;
  
        setPhoneNumber(cleanedPhoneNumber);
  
        setTimeout(() => {
          input.setSelectionRange(selectionStart - 1, selectionStart - 1);
        }, 0);
        return;
      }
  
      if (e.key === 'Delete' && phoneNumber.startsWith('+') && selectionStart > 0) {
        const updatedPhoneNumber =
          phoneNumber.slice(0, selectionStart) + phoneNumber.slice(selectionEnd + 1);
  
        e.preventDefault();
  
        const cleanedPhoneNumber = updatedPhoneNumber.endsWith('-')
          ? updatedPhoneNumber.slice(0, -1)
          : updatedPhoneNumber;
  
        setPhoneNumber(cleanedPhoneNumber);
  
        setTimeout(() => {
          input.setSelectionRange(selectionStart, selectionStart);
        }, 0);
      }
    }
  }