// EnterBehaviorExtension.ts
import { Extension } from "@tiptap/core"

export const EnterBehaviorExtension = Extension.create({
  name: "enterBehavior",

  addKeyboardShortcuts() {
    return {
      // Игнорируем обычный Enter
      Enter: () => true,

      // Обрабатываем Shift+Enter
      "Shift-Enter": () => {
        // setHardBreak создаст постоянный элемент <br> в структуре документа
        return this.editor.commands.setHardBreak();
      },
    }
  },
})