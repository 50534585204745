import React, { useState, useEffect} from "react";
import styles from "./DatePicker.module.css";

interface CalendarProps {
  closeCalendar: () => void;
  setDate: (date: string) => void;
  calendarType: "start" | "end";
  startDate?: string;  // Делаем опциональным, для случаев когда не требуется
 
}

const Calendar: React.FC<CalendarProps> = ({ 
  closeCalendar, 
  setDate, 
  calendarType,
  startDate 
}) => {
  const today = new Date();  
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  


  const handleDateSelect = (date: Date) => {
    const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;
    setDate(dateString);
    closeCalendar();
  };

  const generateCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    const startDay = firstDayOfMonth.getDay();
  
    const weeks = [];
    let currentDay = 1;
  
    for (let i = 0; i < 6; i++) {
      const days = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startDay) {
          days.push(null); // Пустые ячейки до первого дня месяца
        } else if (currentDay > daysInMonth) {
          days.push(null); // Пустые ячейки после последнего дня месяца
        } else {
          const currentDate = new Date(currentYear, currentMonth, currentDay);
          const todayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          const startDateMidnight = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : null;
  
          const isToday =
            currentDate.getDate() === todayMidnight.getDate() &&
            currentDate.getMonth() === todayMidnight.getMonth() &&
            currentDate.getFullYear() === todayMidnight.getFullYear();
  
          const isDisabled =
            (calendarType === "start" && currentDate < todayMidnight && !isToday) ||
            (calendarType === "end" &&
              ((startDateMidnight && currentDate < startDateMidnight) || currentDate < todayMidnight));
  
          days.push({ day: currentDay++, disabled: isDisabled });
        }
      }
      weeks.push(days);
    }
    return weeks;
  };
  

  const goToPreviousMonth = () => {
    if (currentYear === today.getFullYear() && currentMonth === today.getMonth()) return;
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const goToPreviousYear = () => {
    if (currentYear > today.getFullYear()) {
      setCurrentYear(currentYear - 1);
    }
  };

  const goToNextYear = () => {
    setCurrentYear(currentYear + 1);
  };

  const weeks = generateCalendar();
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const isPreviousMonthDisabled =
  currentYear === today.getFullYear() && currentMonth === today.getMonth();

  const handleClick = (e: React.MouseEvent) => {
    console.log('Calendar click:', e.target);
    e.stopPropagation();
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    // Если клик был именно по оверлею (не по его дочерним элементам)
    if (e.target === e.currentTarget) {
      closeCalendar();
    }
  };

  return (
    <div className={styles.calendarModal} onClick={handleOverlayClick}>
    <div 
      className={styles.calendarContainer}
      // Предотвращаем всплытие события клика от контейнера календаря
      onClick={(e) => e.stopPropagation()}
    >
        <header className={styles.calendarHeader}>
          <button
            className={`${styles.arrowButton} ${currentYear === today.getFullYear() ? styles.disabled : ""}`}
            onClick={goToPreviousYear}
            disabled={currentYear === today.getFullYear()}
          >
            &laquo;
          </button>
          <button
            className={`${styles.arrowButton} ${isPreviousMonthDisabled ? styles.disabled : ""}`}
            onClick={goToPreviousMonth}
            disabled={isPreviousMonthDisabled}
          >
            &lt;
          </button>
          <span className={styles.monthYear}>
            {new Date(currentYear, currentMonth).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button className={styles.arrowButton} onClick={goToNextMonth}>
            &gt;
          </button>
          <button className={styles.arrowButton} onClick={goToNextYear}>
            &raquo;
          </button>
        </header>

        <div className={styles.weekdays}>
          {weekdays.map((day, index) => (
            <div key={index} className={styles.weekday}>
              {day}
            </div>
          ))}
        </div>

        <div className={styles.calendarGrid}>
          {weeks.map((week, index) => (
            <div key={index} className={styles.calendarWeek}>
              {week.map((day, dayIndex) =>
                day ? (
                  <button
                  key={dayIndex}
                  className={`${styles.calendarDay} ${day.disabled ? styles.disabled : ""}`}
                  onClick={() =>
                    !day.disabled && handleDateSelect(new Date(currentYear, currentMonth, day.day))
                  }
                  disabled={Boolean(day.disabled)} // Приводим значение к boolean
                >
                  {day.day}
                </button>
                ) : (
                  <div key={dayIndex} className={styles.calendarEmpty} />
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
