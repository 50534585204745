//src/components/modals/EditTenantModal.tsx
import React, { useState, useEffect } from 'react';
import { TenantApi, TenantListItem } from '../../../services/api/TenantApi';
import { PhoneFormatter } from '../../sign_in/services/phoneFormatter';
import { PhoneInputHandler } from '../../sign_in/services/phoneInputHandlers';
import styles from './styles/EditTenantModal.module.css';

interface EditTenantModalProps {
    isOpen: boolean;
    onClose: () => void;
    tenant: TenantListItem;
    communityId: string;
    onSave: () => void;
  }
  
  export const EditTenantModal: React.FC<EditTenantModalProps> = ({ 
    isOpen, 
    onClose,
    tenant,
    communityId,
    onSave
  }) => {
    const [formData, setFormData] = useState({
      name: '',
      lastName: '',
      jmbg: '',
      phoneNumber: '',
      apartmentNumber: '',
    });
  
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  
    useEffect(() => {
      if (tenant) {
        const formattedPhone = PhoneFormatter.format(tenant.phone, '');
        
        setFormData({
          name: tenant.name,
          lastName: tenant.surname,
          jmbg: tenant.jmbg,
          phoneNumber: formattedPhone,
          apartmentNumber: tenant.room_id,
        });
      }
    }, [tenant]);
  
    if (!isOpen) return null;
  
    const handleServerError = (error: Error) => {
      const errorMessage = error.message;
      
      const errorMap: { [key: string]: string } = {
        'BAD_JMBG_FORMAT': 'Invalid JMBG format',
        'BAD_PHONE_FORMAT': 'Invalid phone number format',
        'ALREADY_REGISTERED': 'Phone number is already registered',
        'ALREADY_ACTIVE': 'Phone number is already registered and active'
      };
  
      console.log('Server error:', {
        originalError: errorMessage,
        decodedError: errorMap[errorMessage] || errorMessage
      });
  
      if (errorMessage.includes('jmbg')) {
        setErrors(prev => ({ ...prev, jmbg: 'error' }));
      } else if (errorMessage.includes('PHONE')) {
        setErrors(prev => ({ ...prev, phoneNumber: 'error' }));
      }
    };
  
    const validateForm = () => {
      const newErrors: { [key: string]: string } = {};
  
      if (!formData.name.trim()) {
        newErrors.name = 'error';
      }
  
      if (!formData.lastName.trim()) {
        newErrors.lastName = 'error';
      }
  
      if (!formData.jmbg.trim()) {
        newErrors.jmbg = 'error';
      } else if (!/^\d{13}$/.test(formData.jmbg.trim())) {
        newErrors.jmbg = 'error';
      }
  
      const phoneDigits = formData.phoneNumber.replace(/\D/g, "");
      if (!phoneDigits) {
        newErrors.phoneNumber = 'error';
      } else if (phoneDigits.length < 11 || phoneDigits.length > 12) {
        newErrors.phoneNumber = 'error';
      }
  
      if (!formData.apartmentNumber.trim()) {
        newErrors.apartmentNumber = 'error';
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }
    
        setIsLoading(true);
        setErrors({});
    
        try {
            // Создаем очищенные данные для отправки
            const cleanedFormData = {
                ...formData,
                phoneNumber: formData.phoneNumber.replace(/\D/g, "")
            };
    
            // Здесь будет вызов API для обновления данных
            console.log('Updating tenant data:', cleanedFormData);
            
            onSave();
            onClose();
        } catch (error) {
            handleServerError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({ ...prev, [name]: value }));
      if (errors[name]) {
        setErrors(prev => ({ ...prev, [name]: '' }));
      }
    };
  
    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const formattedValue = PhoneFormatter.format(e.target.value, formData.phoneNumber);
      setFormData(prev => ({ ...prev, phoneNumber: formattedValue }));
      if (errors.phoneNumber) {
        setErrors(prev => ({ ...prev, phoneNumber: '' }));
      }
    };
  
    const handlePhoneFocus = () => {
      setIsPhoneFocused(true);
      if (!formData.phoneNumber.startsWith('+')) {
        setFormData(prev => ({ ...prev, phoneNumber: '+' }));
      }
    };
  
    const handlePhoneBlur = () => {
      setIsPhoneFocused(false);
      if (formData.phoneNumber === '+') {
        setFormData(prev => ({ ...prev, phoneNumber: '' }));
      }
    };
  
    return (
      <div className={styles.modalOverlay} onClick={onClose}>
        <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
          <button className={styles.closeButton} onClick={onClose}>×</button>
          
          <h2 className={styles.modalTitle}>
            Edit tenant data
          </h2>
  
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Information</h3>
  
            <form onSubmit={handleSubmit} noValidate>
              <div className={styles.formGrid}>
                <div className={`${styles.inputContainer} ${errors.name ? styles.error : ''}`}>
                  <label htmlFor="name" className={styles.inputLabel}>
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className={styles.inputField}
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
  
                <div className={`${styles.inputContainer} ${errors.lastName ? styles.error : ''}`}>
                  <label htmlFor="lastName" className={styles.inputLabel}>
                    Last name
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    className={styles.inputField}
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
  
                <div className={`${styles.inputContainer} ${errors.jmbg ? styles.error : ''}`}>
                  <label htmlFor="jmbg" className={styles.inputLabel}>
                    JMBG
                  </label>
                  <input
                    id="jmbg"
                    name="jmbg"
                    type="text"
                    className={styles.inputField}
                    value={formData.jmbg}
                    onChange={handleChange}
                    maxLength={13}
                  />
                </div>
  
                <div className={`${styles.inputContainer} ${errors.phoneNumber ? styles.error : ''}`}>
                  <label htmlFor="phoneNumber" className={styles.inputLabel}>
                    Phone number
                  </label>
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    className={styles.inputField}
                    value={formData.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onFocus={handlePhoneFocus}
                    onBlur={handlePhoneBlur}
                    onKeyDown={(e) => PhoneInputHandler.handlePhoneKeyDown(e, formData.phoneNumber, 
                      (value) => setFormData(prev => ({ ...prev, phoneNumber: value })))}
                    maxLength={16}
                  />
                </div>
  
                <div className={`${styles.inputContainer} ${errors.apartmentNumber ? styles.error : ''}`}>
                  <label htmlFor="apartmentNumber" className={styles.inputLabel}>
                    Apartment number
                  </label>
                  <input
                    id="apartmentNumber"
                    name="apartmentNumber"
                    type="text"
                    className={styles.inputField}
                    value={formData.apartmentNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
  
              <div className={styles.buttonContainer}>
                <button 
                  type="button" 
                  className={styles.cancelButton}
                  onClick={onClose}
                >
                  Back without saving
                </button>
                <button 
                  type="submit" 
                  className={styles.saveButton}
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save changes'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };