// TenantsBlock.tsx
import React, { useState, useEffect } from "react";
import { TenantApi, TenantListItem } from "../../../services/api/TenantApi";
import { useBuildings } from "../../context/BuildingContext";
import { NewTenantModal } from "./NewTenantModal";
import styles from "./styles/ListOfTenants.module.css";
import searchIcon from "../assets/images/search-icon-grey.png";
import plusIcon from "../assets/images/plus-icon-white.png";
import { EditTenantModal } from "./EditTenantModal";
import { DeleteTenantModal } from "./DeleteTenantModal";
import { useSorting } from "../comm_components/hooks/useSorting";
import { useSearch } from "../comm_components/hooks/useSearch";

import downArrowEdit from "../svg_images/downArrowEdit.svg";
import upArrowEdit from "../svg_images/upArrowEdit.svg";

const ListOfTenants: React.FC = () => {
    const [tenants, setTenants] = useState<TenantListItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState<TenantListItem | null>(null);
    const { selectedBuilding } = useBuildings();
    const [currentFilter, setCurrentFilter] = useState<'all' | 'active' | 'pending'>('all');
 

    // Конфигурация поиска
    const searchableFields: Array<keyof TenantListItem> = [
        'name',
        'surname',
        'room_id',
        'jmbg',
        'phone',
        'status'
    ];

    
    // Фильтруем по статусу
    const statusFilteredTenants = tenants.filter(tenant => 
        currentFilter === 'all' || tenant.status.toLowerCase() === currentFilter
    );

    // Применяем поиск к отфильтрованным по статусу данным
    const { 
        searchQuery, 
        setSearchQuery, 
        filteredItems: searchFilteredTenants 
    } = useSearch(statusFilteredTenants, {
        searchableFields,
        minSearchLength: 1,
        valueProcessor: (value) => {
            // Особая обработка для некоторых полей, если нужно
            return String(value).toLowerCase();
        }
    });

    // Sort options configuration
    const SORT_OPTIONS = [
        { key: 'apt', label: 'Apt.' },
        { key: 'name', label: 'Name' },
        { key: 'jmbg', label: 'JMBG' },
        { key: 'phone', label: 'Phone' },
        { key: 'status', label: 'Status' }
    ];

    const SORT_FUNCTIONS = {
        apt: (a: TenantListItem, b: TenantListItem) => a.room_id.localeCompare(b.room_id),
        name: (a: TenantListItem, b: TenantListItem) => 
            `${a.name} ${a.surname}`.localeCompare(`${b.name} ${b.surname}`),
        jmbg: (a: TenantListItem, b: TenantListItem) => a.jmbg.localeCompare(b.jmbg),
        phone: (a: TenantListItem, b: TenantListItem) => a.phone.localeCompare(b.phone),
        status: (a: TenantListItem, b: TenantListItem) => a.status.localeCompare(b.status)
    };

    // Filter tenants based on current filter and search query
    const filteredTenants = tenants.filter(tenant => {
        const matchesFilter = 
            currentFilter === 'all' || 
            tenant.status.toLowerCase() === currentFilter;
        
        const matchesSearch = 
            !searchQuery || 
            `${tenant.name} ${tenant.surname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.room_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.jmbg.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.phone.toLowerCase().includes(searchQuery.toLowerCase());

        return matchesFilter && matchesSearch;
    });

    // Use sorting hook
    const {
        sortedData: sortedTenants,
        sortBy,
        sortDirection,
        handleSortChange
    } = useSorting(filteredTenants, SORT_OPTIONS, SORT_FUNCTIONS, 'apt');


    

    // Закрываем меню при клике вне его
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(`.${styles.editBtn}`) && !target.closest(`.${styles.dropdownMenu}`)) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const fetchTenants = async () => {
        if (!selectedBuilding) {
            setError('Please select a building first');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);
            const tenantsList = await TenantApi.getTenantsList(
                selectedBuilding.community_id, 
                selectedBuilding.build_id
            );
            setTenants(tenantsList);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Error fetching tenants list');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedBuilding) {
            fetchTenants();
        }
    }, [selectedBuilding]);

    const handleEditClick = (tenant: TenantListItem) => {
        setSelectedTenant(tenant);
        setIsEditModalOpen(true);
        setOpenMenuId(null);
    };

    const handleDeleteClick = (tenant: TenantListItem) => {
        setSelectedTenant(tenant);
        setIsDeleteModalOpen(true);
        setOpenMenuId(null);
    };

    const toggleMenu = (tenantId: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setOpenMenuId(openMenuId === tenantId ? null : tenantId);
    };

    const getHeaderArrow = (columnKey: string) => {
        if (sortBy !== columnKey) return null;
        return sortDirection === 'asc' ? ' ↑' : ' ↓';
    };


    if (isLoading) return <div>Loading...</div>;
    if (!selectedBuilding) return <div>Please select a building</div>;
    if (error) return <div className={styles.error}>{error}</div>;

    const getStatusClass = (status: string) => {
        return status.toLowerCase() === 'active' ? styles.statusActive : styles.statusPending;
    };

    return (
      <div className={styles.tenantsBlock}>
            <div className={styles.topRow}>
                <div className={styles.searchBox}>
                    <img src={searchIcon} alt="Search" className={styles.searchIcon} />
                     <input 
                        type="text" 
                        placeholder="Search" 
                        className={styles.searchInput}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                <button 
                    className={styles.btnAddTenant}
                    onClick={() => setIsModalOpen(true)}
                >
                    <img src={plusIcon} alt="+" />
                    <span>Add tenants</span>
                </button>
            </div>

            <div className={styles.reportStatusFilters}>
                <button 
                    className={currentFilter === 'all' ? styles.filterSelected : styles.filterDeselected}
                    onClick={() => setCurrentFilter('all')}
                >
                    All
                </button>
                <button 
                    className={currentFilter === 'active' ? styles.filterSelected : styles.filterDeselected}
                    onClick={() => setCurrentFilter('active')}
                >
                    Active
                </button>
                <button 
                    className={currentFilter === 'pending' ? styles.filterSelected : styles.filterDeselected}
                    onClick={() => setCurrentFilter('pending')}
                >
                    Pending
                </button>
            </div>

            <div className={styles.mainContent}>
                <div className={styles.tenantsHeader}>
                    {SORT_OPTIONS.map(option => (
                        <div 
                            key={option.key}
                            onClick={() => handleSortChange(option.key)}
                            className={styles.headerCell}
                        >
                            {option.label}
                            {getHeaderArrow(option.key)}
                        </div>
                    ))}
                    <div>Actions</div>
                </div>

                <div className={styles.tenantsBody}>
                    {sortedTenants.length === 0 ? (
                        <div className={styles.noResults}>
                            {searchQuery 
                                ? `No tenants found for "${searchQuery}"`
                                : currentFilter !== 'all' 
                                    ? `No ${currentFilter} tenants found`
                                    : 'No tenants found'
                            }
                        </div>
                    ) : (
                        sortedTenants.map((tenant, index) => (
                            <div key={tenant.id} className={styles.tenantRow}>
                                <div>{tenant.room_id}</div>
                                <div>{`${tenant.name} ${tenant.surname}`}</div>
                                <div>{tenant.jmbg}</div>
                                <div>{tenant.phone}</div>
                                <div className={getStatusClass(tenant.status)}>
                                    {tenant.status}
                                </div>
                                <div className={styles.actions}>
                                    <div className={styles.dropdownContainer}>
                                        <button 
                                            className={styles.editBtn}
                                            onClick={(e) => toggleMenu(tenant.id, e)}
                                        >
                                            Edit 
                                            <img 
                                                src={openMenuId === tenant.id ? upArrowEdit : downArrowEdit}
                                                alt="arrow"
                                                className={styles.arrowIcon}
                                            />
                                        </button>
                                        {openMenuId === tenant.id && (
                                            <div className={styles.dropdownMenu}>
                                                <button 
                                                    className={styles.dropdownItem}
                                                    onClick={() => handleEditClick(tenant)}
                                                >
                                                    Edit data
                                                </button>
                                                <div className={styles.dropdownDivider} />
                                                <button 
                                                    className={`${styles.dropdownItem} ${styles.deleteItem}`}
                                                    onClick={() => handleDeleteClick(tenant)}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {index < sortedTenants.length - 1 && <div className={styles.horizontalLine} />}
                            </div>
                        ))
                    )}
                </div>
            </div>

            {selectedBuilding && (
                <>
                    <NewTenantModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        communityId={selectedBuilding.community_id}
                        buildingId={selectedBuilding.build_id}
                        buildingAddress={`${selectedBuilding.street || ''}, ${selectedBuilding.number || ''}`}
                    />
                    {selectedTenant && (
                        <>
                            <EditTenantModal
                                isOpen={isEditModalOpen}
                                onClose={() => {
                                    setIsEditModalOpen(false);
                                    setSelectedTenant(null);
                                }}
                                tenant={selectedTenant}
                                communityId={selectedBuilding.community_id}
                                onSave={fetchTenants}
                            />
                            <DeleteTenantModal
                                isOpen={isDeleteModalOpen}
                                onClose={() => {
                                    setIsDeleteModalOpen(false);
                                    setSelectedTenant(null);
                                }}
                                onConfirm={fetchTenants}
                                tenantId={selectedTenant.id}
                                tenantName={`${selectedTenant.name} ${selectedTenant.surname}`}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );

};

export default ListOfTenants;