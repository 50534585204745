// src/services/api/AlertsApi.ts
import { ApiService } from '../ApiService';
import { encryptAES, decrypt } from '../../pages/srp-lib/srp';

// Интерфейс для создания оповещения
export interface CreateAlertParams {
  title: string;
  description?: string;
  startingAt: string; // ISO формат даты
  endingAt: string;  // ISO формат даты
  alertTypes: ('electricity' | 'water' | 'other')[];
  buildings: string[];
}

// Интерфейс для краткой информации об оповещении
export interface AlertListItem {
  id: string;
  title: string;
  type: string;
  createdAt: string;
  startingAt: string;
  endingAt: string;
}

// Интерфейс для детальной информации об оповещении
export interface AlertDetailItem extends AlertListItem {
  description?: string;
}

// Константы для ошибок
export const ALERT_ERRORS = {
  THROTTLED: 'Слишком частые запросы',
  MISSING_TITLE: 'Отсутствует заголовок оповещения',
  MISSING_DATES: 'Отсутствуют даты оповещения',
  MISSING_ALERT_TYPES: 'Отсутствуют типы оповещений',
  MISSING_BUILDINGS: 'Отсутствуют здания',
  ACCESS_DENIED: 'Нет доступа к управлению сообществом'
};

export class AlertsApi {
  // Приватный метод для безопасной расшифровки
  private static safeDecrypt(K_Key: string, value: string, fieldName: string): string {
    try {
      return decrypt(K_Key, value, fieldName) || '';
    } catch (error) {
      console.error(`Decryption error for ${fieldName}:`, error);
      return '';
    }
  }

  // Создание оповещения
  static async createAlert(
    communityId: string, 
    alertData: CreateAlertParams
  ): Promise<void> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) {
      throw new Error('K_Key is missing');
    }

    // Шифруем все необходимые поля
    const encryptedData = {
      title: encryptAES(K_Key, alertData.title) || '',
      description: alertData.description 
        ? encryptAES(K_Key, alertData.description) || '' 
        : undefined,
      starting_at: encryptAES(K_Key, alertData.startingAt) || '',
      ending_at: encryptAES(K_Key, alertData.endingAt) || '',
      alert_types: alertData.alertTypes.map(type => 
        encryptAES(K_Key, type) || ''
      ),
      buildings: alertData.buildings.map(building => 
        encryptAES(K_Key, building) || ''
      )
    };

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/alert/create",
      method: 'POST',
      headers: {
        'community-id': encryptAES(K_Key, communityId) || ''
      },
      body: encryptedData
    });

    if (!response.success) {
      const errorMessage = ALERT_ERRORS[response.error as keyof typeof ALERT_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  }

  // Получение списка оповещений для здания
  static async getAlertsList(
    communityId: string, 
    buildingId: string
  ): Promise<AlertListItem[]> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) {
      throw new Error('K_Key is missing');
    }

    const response = await ApiService.request<any[]>({
      url: "https://mileva.rs/admin/alert/get/list",
      method: 'GET',
      headers: {
        'community-id': encryptAES(K_Key, communityId) || '',
        'building-id': encryptAES(K_Key, buildingId) || ''
      }
    });

    if (!response.success) {
      const errorMessage = ALERT_ERRORS[response.error as keyof typeof ALERT_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }

    return response.data.map(item => ({
      id: this.safeDecrypt(K_Key, item.id, 'id'),
      title: this.safeDecrypt(K_Key, item.title, 'title'),
      type: this.safeDecrypt(K_Key, item.ttype, 'ttype'),
      createdAt: this.safeDecrypt(K_Key, item.created_at, 'created_at'),
      startingAt: this.safeDecrypt(K_Key, item.starting_at, 'starting_at'),
      endingAt: this.safeDecrypt(K_Key, item.ending_at, 'ending_at')
    }));
  }

  // Получение детальной информации об оповещении
  static async getAlertDetails(
    communityId: string, 
    alertId: string
  ): Promise<AlertDetailItem> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) {
      throw new Error('K_Key is missing');
    }

    const response = await ApiService.request<any>({
      url: "https://mileva.rs/admin/alert/get/one",
      method: 'GET',
      headers: {
        'community-id': encryptAES(K_Key, communityId) || '',
        'alert-id': encryptAES(K_Key, alertId) || ''
      }
    });

    if (!response.success) {
      const errorMessage = ALERT_ERRORS[response.error as keyof typeof ALERT_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }

    const data = response.data;
    return {
      id: this.safeDecrypt(K_Key, data.id, 'id'),
      title: this.safeDecrypt(K_Key, data.title, 'title'),
      description: data.description 
        ? this.safeDecrypt(K_Key, data.description, 'description') 
        : undefined,
      type: this.safeDecrypt(K_Key, data.ttype, 'ttype'),
      createdAt: this.safeDecrypt(K_Key, data.created_at, 'created_at'),
      startingAt: this.safeDecrypt(K_Key, data.starting_at, 'starting_at'),
      endingAt: this.safeDecrypt(K_Key, data.ending_at, 'ending_at')
    };
  }

  // Редактирование оповещения
  static async editAlert(
    communityId: string, 
    alertId: string, 
    updateData: {
      title?: string, 
      description?: string
    }
  ): Promise<void> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) {
      throw new Error('K_Key is missing');
    }

    const encryptedData: any = {
      alert_id: encryptAES(K_Key, alertId) || '',
      title: updateData.title 
        ? encryptAES(K_Key, updateData.title) || '' 
        : undefined,
      description: updateData.description 
        ? encryptAES(K_Key, updateData.description) || '' 
        : undefined
    };

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/alert/edit",
      method: 'POST',
      headers: {
        'community-id': encryptAES(K_Key, communityId) || ''
      },
      body: encryptedData
    });

    if (!response.success) {
      const errorMessage = ALERT_ERRORS[response.error as keyof typeof ALERT_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  }
}