import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Auth.module.css';
import { useAuth } from '../context/AuthorizationContext';
import { ReactComponent as ShowIcon } from './icons/show-icon.svg';
import { ReactComponent as HideIcon } from './icons/hide-icon.svg';

import { AuthHandler } from './services/authHandler';
import { PhoneFormatter } from './services/phoneFormatter';
import { FormValidator } from './services/formValidation';
import { PhoneInputHandler } from './services/phoneInputHandlers';
import { ForgotPasswordModal } from './ForgotPasswordModal';

export const SignInForm: React.FC = () => {
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const { setTmpToken: setContextTmpToken, 
          setPhoneNumber: setPhoneNumberContext,
          setK_Key: setK_KeyContext,
          setBackCounter: setBackCounterContext} = useAuth(); // Access the context

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePhoneFocus = () => {
    setIsPhoneFocused(true);

    // Добавляем "+" в начало строки, если его нет
    if (!phoneNumber.startsWith('+')) {
      setPhoneNumber('+');
    }

    // Перемещаем курсор в конец строки только при фокусе
    const input = document.getElementById('phone') as HTMLInputElement;
    if (input) {
      input.setSelectionRange(phoneNumber.length, phoneNumber.length);
    }
  };

  const handlePhoneBlur = () => {
    setIsPhoneFocused(false);

    // Если в поле остался только "+", очищаем поле
    if (phoneNumber === '+') {
      setPhoneNumber('');
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = PhoneFormatter.format(e.target.value, phoneNumber);
    setPhoneNumber(formattedValue);
  };

  const handlePhoneKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    PhoneInputHandler.handlePhoneKeyDown(e, phoneNumber, setPhoneNumber);
  };
    
  const handlePhoneSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;

    // Если выделение начинается с плюса, сбрасываем выделение
    if (input.selectionStart !== null && input.selectionStart === 0) {
      input.setSelectionRange(1, input.selectionEnd);
    }
  };

  const validateForm = () => {
    const result = FormValidator.validateLoginForm(phoneNumber, password);
    
    setHasError(!result.isValid);
    setErrorMessage(result.errorMessage || '');
    
    if (result.formattedPhone) {
      setPhoneNumber(result.formattedPhone);
    }
    
    return result.isValid;
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const result = await AuthHandler.handleAuthentication(
      phoneNumber, 
      password,
      {
        onSuccess: () => {
          // Можно оставить пустым, так как всё необходимое делается после проверки result
        },
        onError: (error) => {
          setHasError(true);
          setErrorMessage(error);
        }
      }
    );

    if (result.success && result.tmpToken && result.K_Key) {
      setContextTmpToken(result.tmpToken);
      setPhoneNumberContext(result.phoneNumber!);
      setK_KeyContext(result.K_Key);
      setBackCounterContext(result.timeout || 300);
      navigate("/confirm_code");
    }
  };
  
  const handleFocus = () => {
    setHasError(false); // Сбрасываем общий флаг ошибки
    setErrorMessage(''); // Убираем сообщение об ошибке
  };

  return (
    <>
    <form className={styles.signInForm} onSubmit={handleSubmit}>
      <h1 className={styles.signInTitle}>Sign in</h1>

      <div className={styles.formFields}>
        {/* Поле ввода телефона */}
        <div className={styles.inputContainer}>
          <label htmlFor="phone" className={styles.inputLabel}>
            Phone number
          </label>
          <input
            id="phone"
            type="tel"
            placeholder={isPhoneFocused ? '' : '+XXX-XXX-XXX-XXX'}
            className={`${styles.inputField} ${hasError ? styles.inputError : ''}`}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={handlePhoneFocus}
            onBlur={handlePhoneBlur}
            onKeyDown={handlePhoneKeyDown}
            onSelect={handlePhoneSelect} // Запрещаем выделение плюса
            maxLength={16}
          />
        </div>

        {/* Поле ввода пароля */}
        <div className={styles.passwordContainer}>
          <label htmlFor="password" className={styles.passwordLabel}>
            Password
          </label>
          <div className={styles.passwordWrapper}>
            <input
              id="password"
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="Enter your password"
              className={`${styles.passwordField} ${hasError ? styles.inputError : ''}`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={handleFocus}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={styles.visibilityButton}
              aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
            >
              {isPasswordVisible ? <ShowIcon /> : <HideIcon />}
            </button>
          </div>
        </div>

        {/* Объединённое сообщение об ошибке */}
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

        <button 
          type="button" 
          className={styles.forgotPassword}
          onClick={() => setIsForgotPasswordOpen(true)}
        >
          Forgot your password?
        </button>

        <button type="submit" className={styles.signInButton}>
          Sign in
        </button>
      </div>
    </form>
    <ForgotPasswordModal 
        isOpen={isForgotPasswordOpen}
        onClose={() => setIsForgotPasswordOpen(false)}
      />
    </>
  );
};
