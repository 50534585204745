//AlertsMain.tsx
import React, { useState, useEffect, useMemo } from "react";
import styles from "./modules/AlertsMain.module.css";
import NewAlertMessage  from "./NewAlert";
import AlertDetails from './AlertDetails';
import searchIcon from "./images/SearchIcon.png";
import plusAddButtonIcon from "../svg_images/plusAddButton.svg";
import AlertsList, { Alert, AlertType } from "./AlertsList";
import { useBuildings } from '../../context/BuildingContext';
import { SortSection } from "../comm_components/SortSection";
import { useSorting } from "../comm_components/hooks/useSorting";
import { AlertsApi } from "../../../services/api/AlertsApi";

const AlertMessagesMain: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [currentFilter, setCurrentFilter] = useState<'all' | 'active' | 'finished'>('all');
  const { selectedBuilding } = useBuildings();

  const [selectedAlertId, setSelectedAlertId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const SORT_OPTIONS = [
    { key: 'topic', label: 'Topic' },
    { key: 'date', label: 'Date of creation' },
    { key: 'type', label: 'Type' }
  ];

  const SORT_FUNCTIONS = {
    topic: (a: Alert, b: Alert) => a.title.localeCompare(b.title),
    date: (a: Alert, b: Alert) => b.creationDate.getTime() - a.creationDate.getTime(),
    type: (a: Alert, b: Alert) => a.type.localeCompare(b.type)
  };

  const mapTypeToAlertType = (type: string): AlertType => {
    switch (type.toLowerCase()) {
      case 'electricity':
        return 'electricity';
      case 'water':
        return 'water';
      default:
        return 'other';
    }
  };

  const fetchAlerts = async () => {
    if (!selectedBuilding) return;

    setIsLoading(true);
    setError(null);

    try {
      const alertsList = await AlertsApi.getAlertsList(
        selectedBuilding.community_id, 
        selectedBuilding.build_id
      );

      const processedAlerts: Alert[] = alertsList.map(alert => {
        const startDate = new Date(alert.startingAt);
        const endDate = new Date(alert.endingAt);
        const creationDate = new Date(alert.createdAt);
        const currentDate = new Date();

        return {
          id: alert.id,
          title: alert.title,
          type: mapTypeToAlertType(alert.type),
          creationDate,
          startDate,
          endDate,
          status: currentDate > endDate ? 'ended' : 'active',
          description: "",
        };
      });

      setAlerts(processedAlerts);
    } catch (error) {
      console.error("Error fetching alerts:", error);
      setError(error instanceof Error ? error.message : 'Failed to fetch alerts');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, [selectedBuilding]);

  
  const filteredAlerts = alerts
    .filter(alert => {
      if (currentFilter === 'all') return true;
      return alert.status === (currentFilter === 'active' ? 'active' : 'ended');
    })
    .filter(alert => {
      if (!searchQuery) return true;
      return alert.title.toLowerCase().includes(searchQuery.toLowerCase());
    });

  // Используем хук для сортировки
  const {
    sortedData,
    sortBy,
    sortDirection,
    handleSortChange
  } = useSorting(filteredAlerts, SORT_OPTIONS, SORT_FUNCTIONS);

  const handleAlertClick = (id: string) => {
    setSelectedAlertId(id);
  };

  const handleBackClick = () => {
    setSelectedAlertId(null);
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div style={{color: 'red'}}>{error}</div>;
  }

  if (selectedAlertId && selectedBuilding) {
    return (
      <AlertDetails 
        alertId={selectedAlertId}
        communityId={selectedBuilding.community_id}
        onBack={handleBackClick}
      />
    );
  }

  return (
    <div className={styles.mainContainer}>
     
      <div className={styles.topRow}>
        <div className={styles.searchBox}>
          <img src={searchIcon} alt="Search" className={styles.searchIcon} />
          <input type="text" placeholder="Search" className={styles.searchInput} />
        </div>

        <button className={styles.btnAdd} onClick={() => setIsModalOpen(true)}>
          <img src={plusAddButtonIcon} alt="+" />
          <span>Create new alert</span>
        </button>
      </div>

      <div className={styles.StatusFilters}>
        <button 
          className={currentFilter === 'all' ? styles.filterSelected : styles.filterDeselected}
          onClick={() => setCurrentFilter('all')}
        >
          All
        </button>
        <button 
          className={currentFilter === 'active' ? styles.filterSelected : styles.filterDeselected}
          onClick={() => setCurrentFilter('active')}
        >
          Active
        </button>
        <button 
          className={currentFilter === 'finished' ? styles.filterSelected : styles.filterDeselected}
          onClick={() => setCurrentFilter('finished')}
        >
          Finished
        </button>
      </div>

      <div className={styles.sortBySection}>
        <SortSection 
          options={SORT_OPTIONS}
          onSortChange={handleSortChange}
          initialSortBy="date"
          currentSortBy={sortBy}
          currentDirection={sortDirection}
        />
      </div>

      <div className={styles.alertContainer}>
        <AlertsList 
          alerts={sortedData} 
          onAlertClick={handleAlertClick}
        />
      </div>
      

      {isModalOpen && (
        <NewAlertMessage 
          onClose={() => setIsModalOpen(false)} 
          onAlertCreated={fetchAlerts}
        />
      )}
    </div>
  );
};

export default AlertMessagesMain;