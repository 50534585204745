// src/services/auth/authHandler.ts

import { User } from '../../../pages/srp-lib/srp';
import { AuthApi, AuthStatus } from '../../../services/api/AuthApi';
import base64 from 'base64-js';

export interface AuthResult {
  success: boolean;
  tmpToken?: string;
  phoneNumber?: string;
  K_Key?: string;
  timeout?: number;
  error?: string;
}

interface AuthHandlerOptions {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export class AuthHandler {
  static async handleAuthentication(
    phoneNumber: string, 
    password: string,
    options?: AuthHandlerOptions
  ): Promise<AuthResult> {
    try {
      // Форматируем номер телефона
      const username = phoneNumber.replace(/\D/g, "");

      // Инициализируем SRP пользователя
      const user = new User(username, password);
      const A = user.getPublicEphemeral();
      const A_64 = base64.fromByteArray(Buffer.from(A.toString(16).padStart(256, "0"), "hex"));

      // Шаг 1: Получаем salt и публичный ключ сервера
      const step1Response = await AuthApi.signInStep1(username, A_64);
      
      // Декодируем полученные данные
      const decodedS = Buffer.from(step1Response.s, "base64");
      const decodedB = BigInt(`0x${Buffer.from(step1Response.B, "base64").toString("hex")}`);

      // Генерируем M
      const M = user.processChallenge(decodedS, decodedB);
      const M_base64 = M.toString("base64");

      // Получаем K_Key
      const K_Key = user.getSessionKey().toString("base64");

      // Шаг 2: Отправляем M и получаем результат
      const step2Response = await AuthApi.signInStep2({
        phoneNumber: username,
        M: M_base64,
        K_Key
      });

      // Проверяем HAMK
      const HAMK_server = Buffer.from(step2Response.HAMK, "base64");
      const isValid = user.verifySession(HAMK_server);

      if (isValid) {
        switch (step2Response.status) {
          case AuthStatus.OTP_SENDED:
            options?.onSuccess?.();
            return {
              success: true,
              tmpToken: step2Response.tmp_token,
              phoneNumber: username,
              K_Key: K_Key,
              timeout: step2Response.timeout
            };

          case AuthStatus.AUTH_FAILED:
            const error = "Phone number or password is incorrect";
            options?.onError?.(error);
            return { success: false, error };

          case AuthStatus.THROTTLED:
            const throttledError = "Please try again later";
            options?.onError?.(throttledError);
            return { success: false, error: throttledError };

          default:
            const defaultError = `Authentication error: ${step2Response.status}`;
            options?.onError?.(defaultError);
            return { success: false, error: defaultError };
        }
      } else {
        const error = "Authentication failed. Please try again";
        options?.onError?.(error);
        return { success: false, error };
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Authentication error. Please try again";
      options?.onError?.(errorMessage);
      return { success: false, error: errorMessage };
    }
  }
}