// src/services/ApiService.ts

// Интерфейс для успешного ответа
export interface ApiSuccessResponse<T> {
  success: true;
  data: T;
  error?: never;
}

// Интерфейс для ответа с ошибкой
export interface ApiErrorResponse {
  success: false;
  data?: never;
  error: string;
}

// Объединенный тип для всех возможных ответов
export type ApiResponse<T> = ApiSuccessResponse<T> | ApiErrorResponse;

export class ApiService {
  private static getToken(): string | null {
    return localStorage.getItem("authToken");
  }

  static async request<T>({
    url, 
    method = 'GET', 
    headers = {}, 
    body = null,
    requiresAuth = true  // Новый параметр
  }: {
    url: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    headers?: Record<string, string>;
    body?: any;
    requiresAuth?: boolean;  // Добавляем в тип
  }): Promise<ApiResponse<T>> {
    
    const requestHeaders: Record<string, string> = {
      ...headers,
      'Content-Type': 'application/json'
    };

    // Добавляем токен только если требуется авторизация
    if (requiresAuth) {
      const token = this.getToken();
      if (!token) {
        return { 
          success: false, 
          error: 'Unauthorized' 
        };
      }
      requestHeaders['token'] = token;
    }

    try {
      const requestConfig: RequestInit = {
        method,
        headers: requestHeaders
      };

      if (body) {
        requestConfig.body = JSON.stringify(body);
      }

      const response = await fetch(url, requestConfig);

      if (!response.ok) {
        const errorText = await response.text();
        
        return { 
          success: false, 
          error: errorText 
        };
      }

      const data = await response.json();

      return { 
        success: true, 
        data 
      };

    } catch (error) {
      console.error('API Request Error:', error);
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Unknown error' 
      };
    }
  }
}