// UniversalEditor.tsx
import React, { useEffect, useState, useMemo } from "react"
import { useEditor, EditorContent } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { Node, mergeAttributes, InputRule, Editor } from "@tiptap/core"
import { Node as ProseMirrorNode } from 'prosemirror-model';
import styles from "./UniversalEditor.module.css"

import { parseInitialTextWithPlaceholders } from "./parsePlaceholders"
import { PlaceholderPosthocExtension } from "./PlaceholderPosthocExtension"
import { EnterBehaviorExtension } from "./EnterBehaviorExtension"

import { EditableTextProps } from './types';
/**
 * Типы пропсов
 */


/**
 * Функция, которая обходит все ноды документа и формирует «реальный» текст
 * с учётом подстановок (подставляя parameterValues в место [type] и т.д.).
 */
function getSubstitutedText(doc: ProseMirrorNode, placeholdersMap: Record<string, string>): string {
    let finalStr = "";
  
    doc.descendants((node: ProseMirrorNode) => {
      if (node.isText) {
        finalStr += node.text;
      } else if (node.type.name === "variable") {
        const varKey = node.attrs.varKey;
        const paramVal = placeholdersMap[varKey]?.trim();
        finalStr += paramVal || `[${varKey}]`;
      } else if (node.type.name === "hardBreak") {
        finalStr += '\n';
      }
    });
  
    return finalStr;
  }
/**
 * 1) Экстеншен PlaceholderVar:
 *    - Делает [type], [start], [end] атомарными узлами
 *    - При вводе "ловит" [type] через InputRule
 *    - При рендере подставляет реальные значения (через displayValue)
 */
interface PlaceholderVarOptions {
  placeholders: Record<string, string>
}

const PlaceholderVar = Node.create<PlaceholderVarOptions>({
  name: "variable",
  inline: true,
  group: "inline",
  atom: true,

  addOptions() {
    return {
      placeholders: {},
    }
  },

  addAttributes() {
    return {
        varKey: { default: null },
        displayValue: { default: "" },
        isAtStart: { default: false }
      }
  },

  parseHTML() {
    return [
      {
        tag: "span[data-variable]",
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    const varKey = node.attrs.varKey;
    const displayValue = node.attrs.displayValue;
    let finalValue = displayValue || `[${varKey}]`;
  
    // Добавляем специальный атрибут для отслеживания позиции
    const isStart = node.attrs.isAtStart || false;
    
    if (isStart) {
      finalValue = finalValue.charAt(0).toUpperCase() + finalValue.slice(1);
    }
  
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        "data-variable": varKey,
        class: styles.highlight,
      }),
      finalValue,
    ]
  },

  // По умолчанию getText() вернёт именно этот текст, а не подстановку:
  renderText({ node }) {
    return `[${node.attrs.varKey}]`
  },

  addInputRules() {
    // Раньше было жестко задано (type|start|end)
    const keys = Object.keys(this.options.placeholders);
    if (keys.length === 0) return [];  // Защита от undefined
    
    // Создаем паттерн из всех ключей
    const pattern = new RegExp(`\\[(${keys.join('|')})\\]$`);
      
    return [
      new InputRule({
        find: pattern,
        handler: ({ match, range, chain }) => {
          const varKey = match[1]
          chain()
            .deleteRange(range)
            .insertContent({
              type: this.name,
              attrs: { 
                varKey,
                displayValue: `[${varKey}]` // Добавляем начальное отображение
              }
            })
            .run()
        },
      }),
    ]
  },
})

/**
 * 2) Основной компонент
 */
const UniversalEditor: React.FC<EditableTextProps> = ({
    parameters=[],
    onDescriptionChange,
    placeholder = "Enter your text here...",
    initialTemplate="",
    readOnly = false,
    className = '',
    placeholderClassName = '',
    maxLength,  
    onMaxLengthExceeded,  
  }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isEmptyContent, setIsEmptyContent] = useState(false);
     
    // Формируем словарь для подстановки из массива параметров
    const placeholdersMap = useMemo(() => 
        parameters.reduce<Record<string, string>>(
          (acc, { key, value }) => ({
            ...acc,
            [key]: value
          }), 
          {}
        ),
        [parameters]
      );
  
    
// Обработка обновления с проверкой длины
const handleUpdate = ({ editor }: { editor: Editor }) => {
    const isEmpty = !editor.getText().trim();
    setIsEmptyContent(isEmpty);
  
    const doc = editor.state.doc;
    const finalText = getSubstitutedText(doc, placeholdersMap);
    const currentLength = finalText.length;
  
    if (maxLength && currentLength > maxLength) {
      // Собираем массив всех нод и их позиций
      const nodes: { node: ProseMirrorNode; pos: number }[] = [];
      doc.descendants((node, pos) => {
        nodes.push({ node, pos });
      });
  
      // Находим последнюю текстовую ноду
      for (let i = nodes.length - 1; i >= 0; i--) {
        const { node, pos } = nodes[i];
        if (node.isText) {
          const tr = editor.state.tr;
          const textLength = node.text?.length || 0;
          const excessLength = currentLength - maxLength;
          
          // Обрезаем текст с конца на нужное количество символов
          const newText = node.text?.slice(0, Math.max(0, textLength - excessLength)) || '';
          tr.replaceWith(pos, pos + textLength, editor.schema.text(newText));
          
          editor.view.dispatch(tr);
          break;
        }
      }
  
      onMaxLengthExceeded?.();
      return;
    }
  
    onDescriptionChange(finalText, currentLength);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!editor || !maxLength) return;

    const currentLength = getSubstitutedText(editor.state.doc, placeholdersMap).length;
    
    // Разрешаем специальные клавиши всегда
    if (
      event.key === 'Backspace' || 
      event.key === 'Delete' || 
      event.key === 'ArrowLeft' || 
      event.key === 'ArrowRight' || 
      event.key === 'ArrowUp' || 
      event.key === 'ArrowDown' ||
      event.ctrlKey || 
      event.metaKey
    ) {
      return;
    }

    // Если достигнут лимит, предотвращаем ввод
    if (currentLength >= maxLength) {
      event.preventDefault();
      onMaxLengthExceeded?.();
    }
  };

  

  // Инициализируем редактор
  const editor = useEditor({
    extensions: [
      StarterKit,
      PlaceholderVar.configure({
        placeholders: placeholdersMap,
      }),
      PlaceholderPosthocExtension.configure({
        variables: parameters
      }),
      EnterBehaviorExtension
    ],
    content: parseInitialTextWithPlaceholders(initialTemplate, parameters),
    editable: !readOnly,
    onUpdate: handleUpdate,
  });

  // При изменении props -> обновляем placeholderVar-ноды (displayValue)
  useEffect(() => {
    if (!editor) return;
  
    const { state, view } = editor;
    const tr = state.tr;
  
    state.doc.descendants((node, pos) => {
      if (node.type.name === "variable") {
        const $pos = state.doc.resolve(pos);
        const isAtStart = $pos.parentOffset === 0;
        
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          displayValue: placeholdersMap[node.attrs.varKey] || `[${node.attrs.varKey}]`,
          isAtStart
        });
      }
    });
  
    if (tr.docChanged) {
      view.dispatch(tr);
    }
  }, [parameters, editor]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(!readOnly);
    }
  }, [editor, readOnly]);


  useEffect(() => {
    if (editor && maxLength) {
      const editorElement = editor.view.dom;
      editorElement.addEventListener('keydown', handleKeyDown);
      
      return () => {
        editorElement.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [editor, maxLength]);

  if (!editor) {
    return <div>Loading Editor...</div>
  }

  const handleWrapperClick = () => {
    if (editor && !isFocused && !readOnly) {
        editor.commands.focus()
    }
  }

  return (
    <div 
      className={`${styles.editableWrapper} ${readOnly ? styles.readOnly : ''} ${className}`}
      onClick={handleWrapperClick}
    >
      <EditorContent
        editor={editor}
        data-placeholder={placeholder}
        className={`${styles.tiptapEditor} ${
          isEmptyContent && !isFocused ? `${styles.showPlaceholder} ${placeholderClassName}` : ""
        }`}
        onFocus={() => !readOnly && setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  )
}

export default UniversalEditor;
