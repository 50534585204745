import React, { useState, useEffect } from "react";
import styles from "./styles/PollsListContainer.module.css";
import searchIcon from "../alerts/images/SearchIcon.png";
import plusAddButtonIcon from "../svg_images/plusAddButton.svg";
import PollsList from "./PollsList";
import PollDetails from "./PollDetails";
import { useBuildings } from '../../context/BuildingContext';
import { useAuth } from '../../context/AuthorizationContext';
import { SortSection } from "../comm_components/SortSection";
import { useSorting } from "../comm_components/hooks/useSorting";
import { VotingApi, VotingListItem } from '../../../services/api/VotingApi';
import PollModal, { PollData } from "./NewPollModal";

interface Poll {
  id: string;
  title: string;
  status: 'Open' | 'Closed';  // Без 'Stopped'
  creationDate: Date;
  votingEndsAt: Date;
  respondsCount: number;
}

interface PollWithDetails extends Poll {
  description?: string;
  questions: Array<{
    title: string;
    description?: string;
  }>;
  votingResults?: {
    votesYes: number;
    votesNo: number;
    votesSkip: number;
  };
  whoVoted?: { apt: number, tenant: string }[];
}

const PollsListContainer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState<PollWithDetails | null>(null);
  const [currentFilter, setCurrentFilter] = useState<'all' | 'open' | 'closed'>('all');
  const { selectedBuilding } = useBuildings();
  const { token, K_Key } = useAuth();
  const [polls, setPolls] = useState<Poll[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isNewPollModalOpen, setIsNewPollModalOpen] = useState(false);

  const SORT_OPTIONS = [
    { key: 'date', label: 'Date of creation' },
    { key: 'topic', label: 'Topic' },    
  ];

  const SORT_FUNCTIONS = {
    date: (a: Poll, b: Poll) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime(),
    topic: (a: Poll, b: Poll) => a.title.localeCompare(b.title),    
  };

  const mapVotingToPoll = (voting: VotingListItem): Poll => ({
    id: voting.id,
    title: voting.title,
    status: (voting.state === 'Open' ? 'Open' : 'Closed'),
    creationDate: new Date(voting.createdAt),
    votingEndsAt: new Date(voting.endingAt),
    respondsCount: 0
  });



  const fetchPollResults = async (pollId: string): Promise<number> => {
    try {
      const results = await VotingApi.getVotingResults(
        selectedBuilding!.community_id,
        pollId
      );
      return results.votesYes + results.votesNo + results.votesSkip;
    } catch (error) {
      if (error instanceof Error && error.message.includes('VOTING_RESULT_NOT_FOUND')) {
        return 0; // Для голосований без результатов возвращаем 0
      }
      console.error('Error fetching poll results:', error);
      return 0;
    }
  };

  const fetchPolls = async () => {
    if (!selectedBuilding) {
      setError('Please select a building first');
      return;
    }
  
    try {
      setIsLoading(true);
      setError(null);
      const response = await VotingApi.getVotingsList(selectedBuilding.community_id, selectedBuilding.build_id);
      
      // Проверяем, что response - массив
      if (!Array.isArray(response)) {
        console.error('Invalid response format:', response);
        setError('Received invalid data format from server');
        setPolls([]);
        return;
      }
      
      const pollsWithResults = await Promise.all(
        response.map(async (voting) => {
          const respondsCount = await fetchPollResults(voting.id);
          const poll = mapVotingToPoll(voting);
          return { ...poll, respondsCount };
        })
      );
  
      setPolls(pollsWithResults);
    } catch (err) {
      console.error('Error fetching polls:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch polls');
      setPolls([]); // Устанавливаем пустой массив при ошибке
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBuilding) {
      fetchPolls();
    }
  }, [selectedBuilding]);

  const filteredPolls = polls
  .filter(poll => {
    if (currentFilter === 'all') return true;
    if (currentFilter === 'open') return poll.status === 'Open';
    return poll.status === 'Closed';
  })
  .filter(poll => {
    if (!searchQuery) return true;
    return poll.title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const {
    sortedData,
    sortBy,
    sortDirection,
    handleSortChange
  } = useSorting(filteredPolls, SORT_OPTIONS, SORT_FUNCTIONS);

  const handlePollClick = async (poll: Poll) => {
    try {
      const results = await VotingApi.getVotingResults(
        selectedBuilding!.community_id,
        poll.id
      );
      
      const pollWithDetails: PollWithDetails = {
        ...poll,
        questions: [{ title: "" }],
        votingResults: {
          votesYes: results.votesYes,
          votesNo: results.votesNo,
          votesSkip: results.votesSkip
        }
      };
      setSelectedPoll(pollWithDetails);
    } catch (error) {
      console.error('Error fetching poll details:', error);
      // При ошибке создаем объект без результатов
      const pollWithDetails: PollWithDetails = {
        ...poll,
        questions: [{ title: "" }],
        votingResults: {
          votesYes: 0,
          votesNo: 0,
          votesSkip: 0
        }
      };
      setSelectedPoll(pollWithDetails);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPoll(null);
  };

  const handleStopVoting = async () => {
    if (!selectedPoll || !selectedBuilding) return;
    
    try {
      await VotingApi.updateVotingStatus(
        selectedBuilding.community_id, 
        selectedPoll.id, 
        'Stopped'
      );
      await fetchPolls();
    } catch (error) {
      console.error('Error stopping voting:', error);
      setError(error instanceof Error ? error.message : 'Failed to stop voting');
    }
  };

  const handleClosePoll = async () => {
    if (!selectedPoll || !selectedBuilding) return;
    
    try {
      await VotingApi.updateVotingStatus(
        selectedBuilding.community_id, 
        selectedPoll.id, 
        'Closed'
      );
      await fetchPolls();
    } catch (error) {
      console.error('Error closing poll:', error);
      setError(error instanceof Error ? error.message : 'Failed to close poll');
    }
  };

  const handleCreatePoll = () => {
    setIsNewPollModalOpen(true);
  };

  const handleCloseNewPollModal = () => {
    setIsNewPollModalOpen(false);
  };

  
  if (isLoading) return <div>Загрузка...</div>;
  if (error) return <div style={{color: 'red'}}>{error}</div>;
  if (!selectedBuilding) return <div>Please select a building</div>;

  return (
    <div className={styles.mainContainer}>
      {isModalOpen && selectedPoll ? (
        <PollDetails
          onBack={handleCloseModal}
          poll={selectedPoll}
          onStopVoting={handleStopVoting}
          onClosePoll={handleClosePoll}
        />
      ) : (
        <>
          <div className={styles.topRow}>
            <div className={styles.searchBox}>
              <img src={searchIcon} alt="Search" className={styles.searchIcon} />
              <input 
                type="text" 
                placeholder="Search" 
                className={styles.searchInput} 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
              />
            </div>

            <button className={styles.btnAdd} onClick={handleCreatePoll}>
              <img src={plusAddButtonIcon} alt="+" />
              <span>Add new poll</span>
            </button>
          </div>

          <div className={styles.StatusFilters}>
            <button 
              className={currentFilter === 'all' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('all')}
            >
              All
            </button>
            <button 
              className={currentFilter === 'open' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('open')}
            >
              Open
            </button>
            <button 
              className={currentFilter === 'closed' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('closed')}
            >
              Closed
            </button>
          </div>

          <div className={styles.sortBySection}>
            <SortSection 
              options={SORT_OPTIONS}
              onSortChange={handleSortChange}
              initialSortBy="date"
              currentSortBy={sortBy}
              currentDirection={sortDirection}
            />
          </div>

          <div className={styles.pollContainer}>
            <PollsList 
              polls={sortedData}
              onPollClick={handlePollClick}
              onCreatePoll={handleCreatePoll}
            />
          </div>
        </>
      )}

      <PollModal
        isOpen={isNewPollModalOpen}
        onClose={handleCloseNewPollModal}

      />
    </div>
  );
};

export default PollsListContainer;