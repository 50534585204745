// useModalLock.ts
import { useEffect, useCallback } from 'react';

export const useModalLock = (isOpen: boolean) => {
  // Сохраняем scrollY в замыкании
  let scrollPosition = 0;

  const lockScroll = useCallback(() => {
    // Сохраняем текущую позицию прокрутки
    scrollPosition = window.scrollY;
    
    // Добавляем стили для блокировки
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '15px'; // Компенсация скроллбара
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%';
  }, []);

  const unlockScroll = useCallback(() => {
    // Убираем все стили блокировки
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    
    // Восстанавливаем позицию прокрутки
    window.scrollTo(0, scrollPosition);
  }, []);

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    }

    // Очистка при размонтировании или изменении isOpen
    return () => {
      if (isOpen) {
        unlockScroll();
      }
    };
  }, [isOpen, lockScroll, unlockScroll]);
};