import React, { useState, useEffect, createContext } from "react";
import styles from "./modules/AdminPanelPage.module.css";
import ListOfTenants from "./list_of_tenants/ListOfTenants";
import Reports from "./reports/ReportsMain";
import PollsListContainer from "./polls/PollsListContainer";
import CustomMessagesMain from "./alerts/AlertsMain";
import BuildingSelect from "./BuildingSelect";
import ManageBlock from "./ManageBlock";

import { useNavigate } from "react-router-dom"; // Для навигации
import { useAuth } from '../context/AuthorizationContext';
import { useBuildings, Building } from '../context/BuildingContext';

import { ReactComponent as AlertsIcon } from "./svg_images/alerts.svg";
import { ReactComponent as DiscussionsIcon } from "./svg_images/discussions.svg";
import { ReactComponent as FinancesIcon } from "./svg_images/finances.svg";
import { ReactComponent as ListOfTenantsIcon } from "./svg_images/listOfTenants.svg";
import { ReactComponent as PollsIcon } from "./svg_images/polls.svg";
import { ReactComponent as ReportsIcon } from "./svg_images/reports.svg";
import { ReactComponent as AdminIcon } from "./svg_images/adminIcon.svg";
import { ReactComponent as LogOutIcon } from "./svg_images/logOutIcon.svg";

import { AdminAccountApi } from '../../services/api/AdminAccountApi';
import { CommunityApi } from '../../services/api/CommunityApi';
import { AuthApi } from '../../services/api/AuthApi';

export default function AdminPanelPage() {
  const [activeIndex, setActiveIndex] = useState(() => {   
    const savedIndex = localStorage.getItem('activeIndex');
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [name, setName] = useState("Admin");
  const [surname, setSurname] = useState("Admin");
  const { token, K_Key  } = useAuth(); // Access the context
  const navigate = useNavigate(); // Хук для перенаправления
  
  const { setBuildings, setSelectedBuilding } = useBuildings();

  useEffect(() => {
    const checkAuth = async () => {
      const savedToken = localStorage.getItem("authToken");
      const savedKKey = localStorage.getItem("K_Key");

      if (!savedToken || !savedKKey) {
        console.log("No auth credentials found, redirecting to login");
        navigate("/");
        return;
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const nameData = await AdminAccountApi.getName();
        setName(nameData.name);
        setSurname(nameData.surname);
      } catch (error) {
        console.error("Error fetching user name:", error);
        // Если ошибка связана с сессией, выполняем выход
        if (error instanceof Error && error.message.includes('SESSION_NOT_FOUND')) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("K_Key");
          navigate("/");
        }
        //navigate("/");
      }
    };
  
    fetchUserName();
  }, [navigate]);

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const apiBuildings = await CommunityApi.getList();
        
        // Преобразуем в формат Building из контекста
        const buildings: Building[] = apiBuildings.flatMap(community => 
          community.comm_buildings.map(building => ({
            build_id: building.build_id,
            community_id: community.id,
            street: building.street,
            number: building.number,
            fullAddress: `${building.street} ${building.number}`
          }))
        );
  
        setBuildings(buildings);
  
        // Попытка восстановить ранее выбранное здание
        const savedCommunityId = localStorage.getItem('selectedCommunityId');
        const savedBuildId = localStorage.getItem('selectedBuildId');
  
        if (savedCommunityId && savedBuildId) {
          const savedBuilding = buildings.find(
            b => b.community_id === savedCommunityId && b.build_id === savedBuildId
          );
  
          if (savedBuilding) {
            setSelectedBuilding(savedBuilding);
          } else if (buildings.length > 0) {
            // Если сохраненное здание не найдено, выбираем первое
            setSelectedBuilding(buildings[0]);
          }
        } else if (buildings.length > 0) {
          // Если нет сохраненного здания, выбираем первое
          setSelectedBuilding(buildings[0]);
        }
  
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };
  
    fetchBuildings();
  }, [setBuildings, setSelectedBuilding]);
  // Выход
  const handleLogout = async () => {
    try {
      await AuthApi.signOut();
      // Перенаправляем на страницу логина
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Создаем обработчик изменения activeIndex
  const handleActiveIndexChange = (index: number) => {
    setActiveIndex(index);
    localStorage.setItem('activeIndex', index.toString());
  };  

  const menuItems = [
    { name: "List of tenants", Icon: ListOfTenantsIcon },
    { name: "Reports", Icon: ReportsIcon },
    { name: "Polls", Icon: PollsIcon },
    { name: "Discussions", Icon: DiscussionsIcon },
    { name: "Finances", Icon: FinancesIcon },
    { name: "Alerts", Icon: AlertsIcon },
  ];

  return (
  
      <div className={styles.layout}>
        <div className={styles.topGradient} />
        <header className={styles.header}>
          <div className={styles.leftHeader}>
            <div className={styles.logo}>Mileva</div>
            <div className={styles.managingGroup}>
              <div className={styles.managingBuilding}>Managing building:</div>
              <BuildingSelect />
            </div>
          </div>
          <div className={styles.rightHeader}>
            <div className={styles.userInfo}>
              <span className={styles.userName}>
                {name} {surname}
              </span>
              <AdminIcon className={styles.adminIcon} />
            </div>
            <button className={styles.logOutButton} onClick={handleLogout}>
              <span className={styles.logOutText}>Log out</span>
              <LogOutIcon className={styles.logOutIcon} />
            </button>
          </div>
        </header>

        <div className={styles.mainBlock}>
          <div className={styles.mainManageBlock}>
            <ManageBlock
              menuItems={menuItems}
              activeIndex={activeIndex}
              onMenuClick={handleActiveIndexChange}
            />
          </div>
          <div className={styles.mainContentBlock}>
            {activeIndex === 0 && <ListOfTenants />}
            {activeIndex === 1 && <Reports />}
            {activeIndex === 2 && <PollsListContainer />}
            {activeIndex === 5 && <CustomMessagesMain />}
          </div>
        </div>
      </div>
 
  );
}
