import React, { createContext, useState, useContext, useEffect } from 'react';

// Константы для ключей localStorage
const STORAGE_KEYS = {
  AUTH_TOKEN: 'authToken',
  K_KEY: 'K_Key'
} as const;

interface AuthorizationContextProps {
  tmpToken: string;
  setTmpToken: (value: string) => void;
  token: string;
  setToken: (value: string) => void;
  K_Key: string;
  setK_Key: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  backCounter: number;
  setBackCounter: (value: number) => void;
}

const AuthtorizationContext = createContext<AuthorizationContextProps | undefined>(undefined);

export const AuthtorizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tmpToken, setTmpToken] = useState('');
  const [token, setToken] = useState(() => localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN) || '');
  const [K_Key, setK_Key] = useState(() => localStorage.getItem(STORAGE_KEYS.K_KEY) || '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [backCounter, setBackCounter] = useState(0);

  // Сохраняем token в Local Storage при его изменении
  useEffect(() => {
    if (token) {
      localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, token);
    } else {
      localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
    }
  }, [token]);

  // Сохраняем K_Key в Local Storage при его изменении
  useEffect(() => {
    if (K_Key) {
      localStorage.setItem(STORAGE_KEYS.K_KEY, K_Key);
    } else {
      localStorage.removeItem(STORAGE_KEYS.K_KEY);
    }
  }, [K_Key]);

  return (
    <AuthtorizationContext.Provider
      value={{
        tmpToken,
        setTmpToken,
        token,
        setToken,
        K_Key,
        setK_Key,
        phoneNumber,
        setPhoneNumber,
        backCounter,
        setBackCounter,
      }}
    >
      {children}
    </AuthtorizationContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthtorizationContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Экспортируем константы для использования в других файлах
export { STORAGE_KEYS };