// useSearch.ts
import { useState, useMemo } from 'react';

// Базовый тип для значений, которые можно искать
type SearchableValue = string | number | boolean | null | undefined;

interface UseSearchOptions<T> {
    // Список полей, по которым нужно искать
    searchableFields?: Array<keyof T>;
    // Минимальная длина строки поиска
    minSearchLength?: number;
    // Функция для кастомной обработки значения поля при поиске
    valueProcessor?: (value: unknown) => string;
}

export const useSearch = <T extends Record<string, any>>(
    items: T[],
    options: UseSearchOptions<T> = {}
) => {
    const [searchQuery, setSearchQuery] = useState('');
    
    const {
        searchableFields,
        minSearchLength = 1,
        valueProcessor = (value: unknown) => String(value).toLowerCase()
    } = options;

    const filteredItems = useMemo(() => {
        if (!searchQuery || searchQuery.length < minSearchLength) {
            return items;
        }

        const normalizedQuery = searchQuery.toLowerCase().trim();

        return items.filter(item => {
            // Если указаны конкретные поля для поиска, ищем только по ним
            const fieldsToSearch = searchableFields || Object.keys(item);

            return fieldsToSearch.some(field => {
                const value = item[field];

                // Обработка массивов
                if (Array.isArray(value)) {
                    return value.some((v: unknown) => 
                        valueProcessor(v).includes(normalizedQuery)
                    );
                }

                // Обработка одиночных значений
                return valueProcessor(value).includes(normalizedQuery);
            });
        });
    }, [items, searchQuery, searchableFields, minSearchLength, valueProcessor]);

    return {
        searchQuery,
        setSearchQuery,
        filteredItems,
        isSearching: searchQuery.length >= minSearchLength
    };
};