import React from 'react';
import { format } from 'date-fns';
import styles from './modules/AlertsList.module.css';

// Импорт иконок
import electricityIcon from './images/electricityIcon.png';
import waterIcon from './images/whaterIcon.png';
import otherWorksIcon from './images/otherWorksIcon.png';
import rightArrowIcon from '../svg_images/rightGreenArrowIcon.svg';

export type AlertType = 'electricity' | 'water' | 'other';
export type AlertStatus = 'active' | 'ended';

export interface Alert {
  id: string;
  creationDate: Date;
  title: string;
  type: AlertType;
  startDate: Date;
  endDate: Date;
  status: AlertStatus;
  description?: string;
}

interface AlertsListProps {
  alerts: Alert[];
  onAlertClick: (id: string) => void;
}

interface AlertItemProps {
  alert: Alert;
  onAlertClick: (id: string) => void;
}

const AlertItem: React.FC<AlertItemProps> = ({ alert, onAlertClick }) => {
  const getTypeIcon = (type: AlertType) => {
    switch (type) {
      case 'electricity':
        return electricityIcon;
      case 'water':
        return waterIcon;
      case 'other':
        return otherWorksIcon;
    }
  };

  const getTypeText = (type: AlertType) => {
    switch (type) {
      case 'electricity':
        return 'Electricity outage';
      case 'water':
        return 'Water outage';
      case 'other':
        return 'Other technical works';
    }
  };

  // Определяем актуальный статус на основе даты и времени окончания и существующего статуса
  const currentTimestamp = new Date().getTime();
  const endTimestamp = new Date(alert.endDate).getTime();
  const effectiveStatus: AlertStatus = currentTimestamp > endTimestamp ? 'ended' : alert.status;

  return (
    <div className={styles.alertContainer}  onClick={() => onAlertClick(alert.id)}>
      <h3 className={styles.alertTitle}>
        {alert.title}{alert.description && `. ${alert.description}`}
      </h3>
      
      <div className={styles.infoGrid}>
        <div className={styles.creationDate}>
          {format(alert.creationDate, 'dd.MM.yyyy')}
        </div>
        
        <div className={styles.statusAndDates}>
          <span className={effectiveStatus === 'active' ? styles.statusActive : styles.statusEnded}>
            {effectiveStatus === 'active' ? 'Active' : 'Ended'}
          </span>
          <span className={effectiveStatus === 'active' ? styles.statusActive : styles.statusEnded}>
            , {format(alert.startDate, 'dd.MM.yyyy, HH:mm')} — {format(alert.endDate, 'dd.MM.yyyy, HH:mm')}
          </span>
        </div>
        
        <div className={styles.typeContainer}>
          <img 
            src={getTypeIcon(alert.type)} 
            alt={alert.type} 
            className={styles.typeIcon}
          />
          <span className={styles.typeText}>{getTypeText(alert.type)}</span>
        </div>
       
          <img 
            src={rightArrowIcon} 
            alt="Details"
            className={styles.chevronIcon}
          />
        
      </div>
    </div>
  );
};

const AlertsList: React.FC<AlertsListProps> = ({ alerts, onAlertClick }) => {
  return (
    <div className={styles.alertsList}>
      {alerts.length > 0 ? (
        alerts.map(alert => (
          <AlertItem 
            key={alert.id}
            alert={alert}
            onAlertClick={onAlertClick}
          />
        ))
      ) : (
        <div className={styles.noAlerts}>
          No sent alerts yet
        </div>
      )}
    </div>
  );
};

export default AlertsList;