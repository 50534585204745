//RecipientPicker.tsx
import React, { useEffect, useState } from "react";
import styles from "./RecipientPicker.module.css";
import { useBuildings, Building } from "../../pages/context/BuildingContext";

interface RecipientModalProps {
  onClose: () => void;
  onAddRecipients: (recipients: { address: string; buildId: string; communityId: string }[]) => void;
  currentRecipients: string[];
}

const RecipientModal: React.FC<RecipientModalProps> = ({
  onClose,
  onAddRecipients,
  currentRecipients
}) => {
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const { buildings } = useBuildings();

  

  const availableBuildings = buildings.filter(
    building => !currentRecipients.includes(building.fullAddress)
  );

  const handleRecipientClick = (address: string) => {
    setSelectedRecipients((prev) =>
      prev.includes(address)
        ? prev.filter((item) => item !== address)
        : [...prev, address]
    );
  };

  const handleAddClick = () => {
    const selectedData = buildings
      .filter((building: Building) => selectedRecipients.includes(building.fullAddress))
      .map((building: Building) => ({
        address: building.fullAddress,
        buildId: building.build_id,
        communityId: building.community_id,
      }));
    
    onAddRecipients(selectedData);
    onClose();
  };

  useEffect(() => {
    const list = document.querySelector(`.${styles.addressList}`);
    if (list) {
      list.scrollTop = list.scrollHeight / 4;
    }
  }, []);

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div 
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.addressList}>
          {availableBuildings.map((building) => (
            <div
              key={`${building.community_id}_${building.build_id}`}
              className={`${styles.addressItem} ${
                selectedRecipients.includes(building.fullAddress) ? styles.selected : ""
              }`}
              onClick={() => handleRecipientClick(building.fullAddress)}
            >
              {building.fullAddress}
            </div>
          ))}
        </div>
        <button 
          className={styles.addButton} 
          onClick={handleAddClick}
          disabled={selectedRecipients.length === 0}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default RecipientModal;