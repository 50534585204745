// NewPollModal.tsx
import React, { useState, useRef, useEffect } from 'react';
import styles from './styles/NewPollModal.module.css';
import stylesNewForm from '../../../styles/NewFormModal.module.css'
import { ReactComponent as PlusIcon } from '../../../images/svg/pluse_1a.svg';
import { ReactComponent as CrossIcon } from '../../../images/svg/cross_1a.svg';
import RecipientPicker from '../../../modules/recipient_picker/RecipientPicker';
import { useBuildings } from '../../context/BuildingContext'; 
import { useModalLock } from "../../../services/hooks/useModalLock";
import DateTimePickerContainer from '../alerts/DateTimePickerContainer';
import { VotingApi, CreateVotingParams } from '../../../services/api/VotingApi';


interface Recipient {
  id: string;
  address: string;
}

export interface PollData {
  question: string;
  title: string;
  description: string;
  endDate: string;
  endTime: string;
  recipients: Recipient[]; // Теперь храним и id, и адрес
}

interface PollModalProps {
  isOpen: boolean;
  onClose: () => void;

}

interface FormattedData {
  title: string;
  description: string;
  starting_at: string;
  ending_at: string;
  questions: [string, string][];
 }

const LIMITS = {
  question: 1000,
  title: 90,
  description: 1000
};

interface FormErrors {
  title: string;
  description: string;
  question: string;
  endDateTime: string;
  recipients: string;
}

const PollModal: React.FC<PollModalProps> = ({ isOpen, onClose }) => {
  useModalLock(true);
  const [currentPicker, setCurrentPicker] = useState<'date' | 'time' | null>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [wasChainStarted, setWasChainStarted] = useState(false);
  const [isRecipientPickerOpen, setIsRecipientPickerOpen] = useState(false);
  const { selectedBuilding } = useBuildings();

  const [formErrors, setFormErrors] = useState<FormErrors>({
    title: "",
    description: "",
    question: "",
    endDateTime: "",
    recipients: ""
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isPickerOpen) {
          setIsPickerOpen(false);
          setCurrentPicker(null);
        } else if (isRecipientPickerOpen) {
          setIsRecipientPickerOpen(false);
        } else {
          setWasChainStarted(false);
          onClose();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);    
    return () => {     
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPickerOpen, isRecipientPickerOpen, onClose]);

  useEffect(() => {
    return () => {
      // Убеждаемся, что прокрутка восстановлена при размонтировании
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, []);

 
 
  
  // Обработчик для модального окна получателей
  const handleOpenRecipientPicker = () => {
    setIsRecipientPickerOpen(true);
    setFormErrors(prev => ({ ...prev, recipients: "" }));
  }

  // Обработчики для выбора даты и времени
  const handleOpenDatePicker = () => {
    if (!wasChainStarted) {
      // Запускаем цепочку только при первом открытии
      setIsFirstClick(true);
      setWasChainStarted(true);
      setCurrentPicker('date');
      setIsPickerOpen(true);
    } else {
      setIsFirstClick(false);
      setCurrentPicker('date');
      setIsPickerOpen(true);
    }
    setFormErrors(prev => ({ ...prev, endDateTime: "" }));
  };
  
  const handleDateSelect = (date: string) => {
    setFormData(prev => ({...prev, endDate: date}));
    
    if (isFirstClick) {
      // Если это часть цепочки, переходим к выбору времени
      setCurrentPicker('time');
    } else {
      setIsPickerOpen(false);
    }
  };
  
  const handleTimeSelect = (time: string) => {
    setFormData(prev => ({...prev, endTime: time}));
    setIsFirstClick(false);
    setIsPickerOpen(false);
  };

  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric"
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
   };

  const validateForm = () => {
    const currentDateTime = new Date();
    const endDateTime = formData.endDate && formData.endTime 
      ? new Date(`${formData.endDate}T${formData.endTime}`) 
      : null;
    
    const errors = {
      title: !formData.title.trim() ? "Enter title" : "",
      description: !formData.description.trim() ? "Enter description" : "",
      question: !formData.question.trim() ? "Enter question" : "",
      endDateTime: !endDateTime ? "Set ending date and time" :
        endDateTime <= currentDateTime ? "End date must be in the future" : "",
      recipients: formData.recipients.length === 0 ? "Add at least one recipient" : ""
    };
    
    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  }; 

  const [formData, setFormData] = useState<PollData>({
    question: '',
    title: '',
    description: '',
    endDate: '',
    endTime: '',
    recipients: selectedBuilding ? [{
      id: selectedBuilding.build_id,
      address: selectedBuilding.fullAddress
    }] : []
  });

  const handleAddRecipients = (newRecipients: { address: string; buildId: string; communityId: string }[]) => {
    setFormData(prev => ({
      ...prev,
      recipients: [...prev.recipients, ...newRecipients.map(r => ({
        id: r.buildId,
        address: r.address
      }))]
    }));
    setIsRecipientPickerOpen(false);
  };
 
 
  // Refs для текстовых полей
  const questionRef = useRef<HTMLTextAreaElement>(null);
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  // Функция для автоматической настройки высоты textarea
  const adjustTextAreaHeight = (textArea: HTMLTextAreaElement) => {
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  // Проверка, достигнут ли лимит для поля
  const isLimitReached = (name: string) => {
    const limit = LIMITS[name as keyof typeof LIMITS];
    const length = formData[name as keyof PollData]?.length || 0;
    return length >= limit;
  };

  // Обработчик изменения для текстовых полей
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const limit = LIMITS[name as keyof typeof LIMITS];
    
    if (!limit || value.length <= limit) {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      // Сбрасываем ошибку для данного поля
      setFormErrors(prev => ({
        ...prev,
        [name]: ""
      }));
      adjustTextAreaHeight(e.target);
    }
   };

  // Обработчик клавиш для переноса строки
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Ничего не делаем, позволяем браузеру обработать Shift+Enter
        return;
      }
      // Предотвращаем перенос строки по обычному Enter
      e.preventDefault();
    }
  };

  // Настраиваем начальную высоту textarea при открытии модального окна
  useEffect(() => {
    if (isOpen) {
      setFormData({
        question: '',
        title: '',
        description: '',
        endDate: '',
        endTime: '',
        recipients: selectedBuilding ? [{
          id: selectedBuilding.build_id,
          address: selectedBuilding.fullAddress
        }] : []
      });
    
      // ... остальной код эффекта остается тем же
    }
  }, [isOpen, selectedBuilding]);

  const handleRemoveRecipient = (recipientId: string) => {
    setFormData(prev => ({
      ...prev,
      recipients: prev.recipients.filter(r => r.id !== recipientId)
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    if (!selectedBuilding) {
      console.error("No building selected");
      return;
    }

    try {
      const endDateTime = new Date(`${formData.endDate}T${formData.endTime}`);
      
      const apiData: CreateVotingParams = {
        question: formData.question,
        title: formData.title,
        description: formData.description,
        ending_at: endDateTime.toISOString(),
        buildings: formData.recipients.map(r => r.id) // Отправляем только ID
      };

      console.log('Prepared API data:', {
        apiData,
        communityId: selectedBuilding.community_id
      });

      await VotingApi.createVoting(selectedBuilding.community_id, apiData);
      
     
      setWasChainStarted(false);
      onClose();
    } catch (error) {
      console.error('Error creating voting:', error);
      setFormErrors(prev => ({
        ...prev,
        title: error instanceof Error ? error.message : 'Failed to create voting'
      }));
    }
  };
    
 
  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setWasChainStarted(false);
      onClose();
    }
  };

 

  return (
    <div className={stylesNewForm.modalOverlay} onClick={handleOverlayClick}>
      <div 
        className={stylesNewForm.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
       
        <div className={stylesNewForm.modalTitle}>New poll</div>

        <form onSubmit={handleSubmit}>
          <div className={styles.section}>
            <h3 className={`${styles.sectionTitle} ${isLimitReached('question') ? styles.limitReached : ''}`}>
              Main question of the poll {formErrors.question && <span className={styles.errorMessage}>{formErrors.question}</span>}
            </h3>
            <p className={styles.textAreaTitle}>
              Please formulate the question clearly, taking into account that tenants will vote in the format of 
              "Yes, I agree", "No, I oppose" and "Abstain".
            </p>
            <div className={styles.inputWrapper}>
              <textarea
                ref={questionRef}
                name="question"
                value={formData.question}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Example: do you agree that we need to repair the elevator in our building?"
                className={styles.textArea}
                rows={1}
              />             
            </div>
          </div>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Information</h3>
            <div className={styles.formGroup}>
              <p className={`${styles.textAreaTitle} ${isLimitReached('title') ? styles.textAreaTitleWarning : ''}`}>
                Title {formErrors.title && <span className={styles.errorMessage}>{formErrors.title}</span>}
              </p>
              <div className={styles.inputWrapper}>
                <textarea
                  ref={titleRef}
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter title of the poll, example: Elevator repair"
                  className={styles.textArea}
                  rows={1}
                />               
              </div>
            </div>

            <div className={styles.formGroup}>
              <p className={`${styles.textAreaTitle} ${isLimitReached('description') ? styles.textAreaTitleWarning : ''}`}>
                Description ({formData.description.length}/{LIMITS.description} symb.)  {formErrors.description && <span className={styles.errorMessage}>{formErrors.description}</span>}
              </p>
              <div className={styles.inputWrapper}>
                <textarea
                  ref={descriptionRef}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Describe in details what the poll will be about"
                  className={styles.textArea}
                  rows={1}
                />                
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Date</h3>
            <p className={styles.textAreaTitle}>
              Ends {formErrors.endDateTime &&  <span className={styles.errorMessage}>{formErrors.endDateTime}</span>}
            </p>
            <div className={styles.dateTimeGroup}>
              <button 
                type="button" 
                className={`${styles.baseDateTimeButton} ${styles.dateButton} ${!formData.endDate ? styles.dateTimePlaceholder : ''}`}
                onClick={handleOpenDatePicker}
              >
                 {formData.endDate ? formatDate(formData.endDate) : 'Choose date'}
              </button>
              <button 
                type="button"                
                className={`${styles.baseDateTimeButton} ${styles.timeButton} ${!formData.endTime ? styles.dateTimePlaceholder : ''}`}
                onClick={() => {
                  setIsFirstClick(false); // Отключаем цепочку при прямом клике на время
                  setCurrentPicker('time');
                  setIsPickerOpen(true);
                }}                 
              >
                 {formData.endTime || '12:00'}
              </button>
            </div>
          </div>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>
              Recipients {formErrors.recipients && <span className={styles.errorMessage}>{formErrors.recipients}</span>}
            </h3>
            <div className={styles.recipientGroup}>
              {formData.recipients.map((recipient) => (
                <div key={recipient.id} className={styles.recipientTag}>
                  <div className={styles.recipientAddress}>{recipient.address}</div>
                  <button 
                    type="button" 
                    className={styles.delRecipientButton}
                    onClick={() => handleRemoveRecipient(recipient.id)}
                  >
                    <CrossIcon/>
                  </button>
                </div>
              ))}
              <button type="button" className={styles.addRecipientButton} onClick={handleOpenRecipientPicker}>
                <PlusIcon />
              </button>
            </div>
          </div>

          <div className={stylesNewForm.buttonSection}>            
            <button type="submit" className={stylesNewForm.submitButton}>
              Start the poll
            </button>
          </div>
        </form>
      </div>

      <DateTimePickerContainer
        isOpen={isPickerOpen}
        currentPicker={currentPicker}
        calendarType="end"
        startDate={undefined} // у нас нет даты начала в опросе
        endDate={formData.endDate}
        selectedDate={formData.endDate} // используем endDate для определения blockEarlyTime
        onClose={() => {
          setIsPickerOpen(false);
          setCurrentPicker(null);
          setIsFirstClick(false);
        }}
        onDateSelect={handleDateSelect}
        onTimeSelect={handleTimeSelect}
      />

      {isRecipientPickerOpen && (
        <RecipientPicker
          onClose={() => setIsRecipientPickerOpen(false)}
          onAddRecipients={handleAddRecipients}         
          currentRecipients={formData.recipients.map(r => r.id)}
        />
      )}
    </div>
  );
};

export default PollModal;