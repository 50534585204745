// src/utils/formValidation.ts
import { PhoneFormatter } from './phoneFormatter';

export interface ValidationResult {
  isValid: boolean;
  errorMessage?: string;
  formattedPhone?: string;
}

export class FormValidator {
  static validateLoginForm(phoneNumber: string, password: string): ValidationResult {
    let isValid = true;
    let errorMessage = '';
    let formattedPhone = undefined;

    const digitCount = phoneNumber.replace(/\D/g, '');
    
    if (digitCount.length < 11) {
      isValid = false;
      errorMessage = 'Phone number or password is incorrect';
    } else if (digitCount.length === 12) {
      formattedPhone = digitCount.replace(/^(\d{3})(\d{3})(\d{3})(\d{3})$/, '+$1-$2-$3-$4');
    }

    if (password.length < 6) {
      isValid = false;
      errorMessage = 'Phone number or password is incorrect';
    }

    return {
      isValid,
      errorMessage,
      formattedPhone
    };
  }
}