// src/components/modals/NewTenantModal.tsx
import React, { useState } from 'react';
import { TenantApi } from '../../../services/api/TenantApi';
import { PhoneFormatter } from '../../sign_in/services/phoneFormatter';
import { PhoneInputHandler } from '../../sign_in/services/phoneInputHandlers';
import styles from './styles/NewTenantModal.module.css';

interface NewTenantModalProps {
  isOpen: boolean;
  onClose: () => void;
  communityId: string;
  buildingId: string;
  buildingAddress: string; // Added buildingAddress prop
}

export const NewTenantModal: React.FC<NewTenantModalProps> = ({ 
  isOpen, 
  onClose,
  communityId,
  buildingId,
  buildingAddress,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    jmbg: '',
    phoneNumber: '',
    apartmentNumber: '',
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState('');
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  if (!isOpen) return null;

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last name is required';
    }

    if (!formData.jmbg.trim()) {
      newErrors.jmbg = 'JMBG is required';
    } else if (!/^\d{13}$/.test(formData.jmbg.trim())) {
      newErrors.jmbg = 'JMBG must be exactly 13 digits';
    }

    const phoneDigits = formData.phoneNumber.replace(/\D/g, "");
    if (!phoneDigits) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (phoneDigits.length < 11 || phoneDigits.length > 12) {
      newErrors.phoneNumber = 'Please enter a valid phone number';
    }

    if (!formData.apartmentNumber.trim()) {
      newErrors.apartmentNumber = 'Apartment number is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setServerResponse('');
    setErrors({});

    try {
      const requestData = {
        name: formData.name,
        surname: formData.lastName,
        jmbg: formData.jmbg,
        phone: formData.phoneNumber.replace(/\D/g, ""),
        build_id: buildingId,
        room_id: formData.apartmentNumber
      };

      console.log('Sending registration request with data:', {
        communityId,
        ...requestData
        
      });

      await TenantApi.registerTenant(communityId, requestData);

      setServerResponse('Tenant successfully registered');
      setTimeout(() => {
        onClose();
        setFormData({
          name: '',
          lastName: '',
          jmbg: '',
          phoneNumber: '',
          apartmentNumber: ''
        });
        setServerResponse('');
      }, 2000);
      
    } catch (error) {
      handleServerError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = PhoneFormatter.format(e.target.value, formData.phoneNumber);
    setFormData(prev => ({ ...prev, phoneNumber: formattedValue }));
    if (errors.phoneNumber) {
      setErrors(prev => ({ ...prev, phoneNumber: '' }));
    }
  };

  const handlePhoneFocus = () => {
    setIsPhoneFocused(true);
    if (!formData.phoneNumber.startsWith('+')) {
      setFormData(prev => ({ ...prev, phoneNumber: '+' }));
    }
  };

  const handlePhoneBlur = () => {
    setIsPhoneFocused(false);
    if (formData.phoneNumber === '+') {
      setFormData(prev => ({ ...prev, phoneNumber: '' }));
    }
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleServerError = (error: Error) => {
    const errorMessage = error.message;
    
   
  
    // Логируем расшифрованную ошибку
    console.log('Server error:', {
      originalError: errorMessage     
    });
  
    // В интерфейсе подсвечиваем поля с ошибками
    if (errorMessage.includes('jmbg')) {
      setErrors(prev => ({ ...prev, jmbg: 'error' }));
    } else if (errorMessage.includes('PHONE')) {
      setErrors(prev => ({ ...prev, phoneNumber: 'error' }));
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <h2 className={styles.modalTitle}>
          New tenant at <span className={styles.address}>{buildingAddress}</span>
        </h2>

        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Information</h3>

          <form onSubmit={handleSubmit} noValidate>
            <div className={styles.formGrid}>
              <div className={`${styles.inputContainer} ${errors.name ? styles.error : ''}`}>
                <label htmlFor="name" className={styles.inputLabel}>
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div className={`${styles.inputContainer} ${errors.lastName ? styles.error : ''}`}>
                <label htmlFor="lastName" className={styles.inputLabel}>
                  Last name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter last name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>

              <div className={`${styles.inputContainer} ${errors.jmbg ? styles.error : ''}`}>
                <label htmlFor="jmbg" className={styles.inputLabel}>
                  JMBG
                </label>
                <input
                  id="jmbg"
                  name="jmbg"
                  type="text"
                  className={styles.inputField}
                  placeholder="0012345678900"
                  value={formData.jmbg}
                  onChange={handleChange}
                  maxLength={13}
                />
              </div>

              <div className={`${styles.inputContainer} ${errors.phoneNumber ? styles.error : ''}`}>
                <label htmlFor="phoneNumber" className={styles.inputLabel}>
                  Phone number
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  className={styles.inputField}
                  placeholder={isPhoneFocused ? '' : '+XXX-XXX-XXX-XXX'}
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  onFocus={handlePhoneFocus}
                  onBlur={handlePhoneBlur}
                  onKeyDown={(e) => PhoneInputHandler.handlePhoneKeyDown(e, formData.phoneNumber, 
                    (value) => setFormData(prev => ({ ...prev, phoneNumber: value })))}
                  maxLength={16}
                />
              </div>

              <div className={`${styles.inputContainer} ${errors.apartmentNumber ? styles.error : ''}`}>
                <label htmlFor="apartmentNumber" className={styles.inputLabel}>
                  Apartment number
                </label>
                <input
                  id="apartmentNumber"
                  name="apartmentNumber"
                  type="text"
                  className={styles.inputField}
                  placeholder="001"
                  value={formData.apartmentNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <button 
                type="submit" 
                className={styles.addButton}
                disabled={isLoading}
              >
                {isLoading ? 'Adding...' : 'Add'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};