import { ApiService } from '../ApiService';
import { encryptAES, decrypt } from '../../pages/srp-lib/srp';

// Обновляем интерфейсы согласно документации
export interface VotingQuestion {
  title: string;
  description?: string;
}

export interface CreateVotingParams {
  question: string;      // Основной вопрос голосования
  title: string;        // Заголовок
  description?: string; // Описание (опционально)
  ending_at: string;    // Дата окончания в ISO формате
  buildings: string[];  // Список ID зданий
}

// Обновлено в соответствии с документацией (/admin/voting/get/list)
export interface VotingListItem {
  id: string;
  title: string;
  endingAt: string;
  createdAt: string;
  state: 'Open' | 'Stopped' | 'Closed';
}

export interface VotingChoice {
  id: string;
  label: string;
}

export interface AddVotingResultParams {
  votingId: string;
  votesYes: number;
  votesNo: number;
  votesSkip: number;
}

// Обновлено в соответствии с документацией (/admin/voting/result/get)
export interface VotingResult {
  voting: string;
  votesYes: number;
  votesNo: number;
  votesSkip: number;
  addedAt: string;
}

// Расширяем список ошибок согласно документации
export const VOTING_ERRORS = {
  THROTTLED: 'Слишком частые запросы',
  BAD_REQUEST: 'Неверный запрос',
  MISSING_TOKEN: 'Отсутствует токен',
  MISSING_TITLE: 'Отсутствует заголовок голосования',
  MISSING_ENDING_AT: 'Отсутствуют даты голосования',
  MISSING_BUILDINGS: 'Отсутствует список зданий',
  MISSING_QUESTION: 'Отсутствует вопрос',
  ACCESS_DENIED: 'Нет доступа к управлению сообществом',
  BAD_ENCRYPTED_QUESTION: 'Ошибка расшифровки вопроса',
  BAD_ENCRYPTED_TITLE: 'Ошибка расшифровки заголовка',
  BAD_ENCRYPTED_DESCRIPTION: 'Ошибка расшифровки описания',
  BAD_ENCRYPTED_ENDING_AT: 'Ошибка расшифровки даты окончания',
  BAD_ENCRYPTED_BUILDINGS: 'Ошибка расшифровки списка зданий',
  VOTING_NOT_FOUND: 'Голосование не найдено',
  WRONG_STATE: 'Некорректный статус голосования',
  REFUSED: 'Статус может быть изменен только "вперед"'
};

export class VotingApi {
  private static safeDecrypt(K_Key: string, value: string, fieldName: string): string {
    try {
      return decrypt(K_Key, value, fieldName) || '';
    } catch (error) {
      console.error(`Decryption error for ${fieldName}:`, error);
      return '';
    }
  }

  static async createVoting(communityId: string, votingData: CreateVotingParams): Promise<void> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) throw new Error('K_Key is missing');

    console.log('Creating voting with data:', {
      communityId,
      rawData: votingData
    });

    const encryptedData = {
      question: encryptAES(K_Key, votingData.question),
      title: encryptAES(K_Key, votingData.title),
      description: votingData.description ? encryptAES(K_Key, votingData.description) : undefined,
      ending_at: encryptAES(K_Key, votingData.ending_at),
      buildings: votingData.buildings.map(id => encryptAES(K_Key, id))
    };

    console.log('Encrypted data:', {
      encryptedData,
      headers: {
        token: localStorage.getItem("authToken") ? 'present' : 'missing',
      }
    });

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/voting/create",
      method: 'POST',
      headers: {
        'token': localStorage.getItem("authToken") || ''
      },
      body: encryptedData
    });

    console.log('Server response:', response);

    if (!response.success) {
      console.error('Server error details:', {
        error: response.error,
        rawError: response,
        originalData: votingData
      });
      throw new Error(JSON.stringify({
        error: response.error,
        details: VOTING_ERRORS[response.error as keyof typeof VOTING_ERRORS]
      }));
    }
}

  static async getVotingsList(communityId: string, buildingId: string): Promise<VotingListItem[]> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) throw new Error('K_Key is missing');
  
    const response = await ApiService.request<any>({
      url: "https://mileva.rs/admin/voting/get/list",
      method: 'GET',
      headers: {
        'token': localStorage.getItem("authToken") || '',
        'community-id': encryptAES(K_Key, communityId) || '',
        'building-id': encryptAES(K_Key, buildingId) || ''
      }
    });
  
    if (!response.success) {
      throw new Error(VOTING_ERRORS[response.error as keyof typeof VOTING_ERRORS] || response.error);
    }
  
    return response.data.map((item: any) => ({
      id: this.safeDecrypt(K_Key, item.id, 'id'),
      title: this.safeDecrypt(K_Key, item.title, 'title'),
      endingAt: this.safeDecrypt(K_Key, item.ending_at, 'ending_at'),
      createdAt: this.safeDecrypt(K_Key, item.created_at, 'created_at'),
      state: this.safeDecrypt(K_Key, item.state, 'state') as 'Open' | 'Stopped' | 'Closed'
    }));
  }
  
  static async addVotingResults(communityId: string, resultData: AddVotingResultParams): Promise<void> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) throw new Error('K_Key is missing');

    const encryptedData = {
      voting_id: encryptAES(K_Key, resultData.votingId) || '',
      votes_yes: encryptAES(K_Key, String(resultData.votesYes)) || '',
      votes_no: encryptAES(K_Key, String(resultData.votesNo)) || '',
      votes_skip: encryptAES(K_Key, String(resultData.votesSkip)) || ''
    };

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/voting/result/add",
      method: 'POST',
      headers: {
        'token': localStorage.getItem("authToken") || '',
        'community-id': encryptAES(K_Key, communityId) || ''
      },
      body: encryptedData
    });

    if (!response.success) {
      throw new Error(VOTING_ERRORS[response.error as keyof typeof VOTING_ERRORS] || response.error);
    }
  }

  static async getVotingResults(communityId: string, votingId: string): Promise<VotingResult> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) throw new Error('K_Key is missing');

    const response = await ApiService.request<any>({
      url: "https://mileva.rs/admin/voting/result/get",
      method: 'GET',
      headers: {
        'token': localStorage.getItem("authToken") || '',
        'community-id': encryptAES(K_Key, communityId) || '',
        'voting-id': encryptAES(K_Key, votingId) || ''
      }
    });

    if (!response.success) {
      throw new Error(VOTING_ERRORS[response.error as keyof typeof VOTING_ERRORS] || response.error);
    }

    return {
      voting: this.safeDecrypt(K_Key, response.data.voting, 'voting'),
      votesYes: parseInt(this.safeDecrypt(K_Key, response.data.votes_yes, 'votes_yes'), 10),
      votesNo: parseInt(this.safeDecrypt(K_Key, response.data.votes_no, 'votes_no'), 10),
      votesSkip: parseInt(this.safeDecrypt(K_Key, response.data.votes_skip, 'votes_skip'), 10),
      addedAt: this.safeDecrypt(K_Key, response.data.added_at, 'added_at')
    };
  }

  static async updateVotingStatus(
    communityId: string,
    votingId: string,
    newState: 'Open' | 'Stopped' | 'Closed'
  ): Promise<void> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) throw new Error('K_Key is missing');

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/voting/update",
      method: 'POST',
      headers: {
        'token': localStorage.getItem("authToken") || '',
        'community-id': encryptAES(K_Key, communityId) || ''
      },
      body: {
        voting_id: encryptAES(K_Key, votingId) || '',
        new_state: encryptAES(K_Key, newState) || ''
      }
    });

    if (!response.success) {
      throw new Error(VOTING_ERRORS[response.error as keyof typeof VOTING_ERRORS] || response.error);
    }
  }
}