// src/services/api/TenantApi.ts
import { ApiService } from '../ApiService';
import { encryptAES, decrypt } from '../../pages/srp-lib/srp';

// Интерфейсы для параметров и ответов
export interface TenantRegisterParams {
  phone: string | number;
  name?: string;
  surname?: string;
  jmbg?: string | number;
  build_id: string | number;
  room_id: string | number;
}

export interface TenantListItem {
  id: string;
  name: string;
  surname: string;
  jmbg: string;
  phone: string;
  avatar?: string | null;
  build_id: string;
  room_id: string;
  status: string;
}

// Константы для ошибок
export const TENANT_ERRORS = {
  THROTTLED: 'Частые запросы (не чаще одного раза в 10 секунд)',
  BAD_REQUEST: 'Неверный метод запроса',
  MISSING_TOKEN: 'Отсутствует или пустой токен',
  MISSING_COMMUNITY_ID: 'Отсутствует или пустой ID сообщества',
  MISSING_PHONE: 'Отсутствует или пустой номер телефона',
  MISSING_BUILD_ID: 'Отсутствует или пустой ID здания',
  MISSING_ROOM_ID: 'Отсутствует или пустой ID комнаты',
  BAD_ENCRYPTED_COMMUNITY_ID: 'Не удалось расшифровать ID сообщества',
  BAD_ENCRYPTED_PHONE: 'Не удалось расшифровать телефон',
  BAD_ENCRYPTED_NAME: 'Не удалось расшифровать имя',
  BAD_ENCRYPTED_SURNAME: 'Не удалось расшифровать фамилию',
  BAD_ENCRYPTED_JMBG: 'Не удалось расшифровать JMBG',
  BAD_ENCRYPTED_BUILD_ID: 'Не удалось расшифровать ID здания',
  BAD_ENCRYPTED_ROOM_ID: 'Не удалось расшифровать ID комнаты',
  BAD_ID_FORMAT: 'Неверный формат ID сообщества или комнаты',
  BAD_PHONE_FORMAT: 'Неверный формат номера телефона',
  BAD_JMBG_FORMAT: 'Неверный формат JMBG',
  SESSION_NOT_FOUND: 'Не найдена сессия администратора по токену',
  ACCESS_DENIED: 'У администратора нет доступа к управлению данным сообществом',
  BUILDING_NOT_FOUND: 'Здание не найдено',
  ALREADY_REGISTERED: 'Номер телефона уже зарегистрирован (аккаунт не активирован)',
  ALREADY_ACTIVE: 'Номер телефона уже зарегистрирован (аккаунт активен)'
};

export class TenantApi {
  // Получаем приватные ключи
  private static getKKey(): string | null {
    return localStorage.getItem("K_Key");
  }

  private static getToken(): string | null {
    return localStorage.getItem("authToken");
  }

  // Метод регистрации арендатора
  static async registerTenant(
    communityId: string, 
    tenantData: TenantRegisterParams
  ): Promise<void> {
    const K_Key = this.getKKey();
    const token = this.getToken();

    if (!K_Key) {
      throw new Error('K_Key is missing');
    }

    if (!token) {
      throw new Error('Admin token is missing');
    }

    // Шифруем все необходимые поля
    const encryptedData: TenantRegisterParams = {
      phone: encryptAES(K_Key, String(tenantData.phone)) || '',
      build_id: encryptAES(K_Key, String(tenantData.build_id)) || '',
      room_id: encryptAES(K_Key, String(tenantData.room_id)) || ''
    };

    // Добавляем опциональные зашифрованные поля
    if (tenantData.name) {
      encryptedData.name = encryptAES(K_Key, tenantData.name) || '';
    }

    if (tenantData.surname) {
      encryptedData.surname = encryptAES(K_Key, tenantData.surname) || '';
    }

    if (tenantData.jmbg) {
      encryptedData.jmbg = encryptAES(K_Key, String(tenantData.jmbg)) || '';
    }

    // Шифруем ID сообщества
    const encryptedCommunityId = encryptAES(K_Key, communityId);
    if (!encryptedCommunityId) {
      throw new Error('Failed to encrypt community ID');
    }

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/tenant/register",
      method: 'POST',
      headers: {
        'community-id': encryptedCommunityId
      },
      body: encryptedData
    });

    if (!response.success) {
      const errorMessage = TENANT_ERRORS[response.error as keyof typeof TENANT_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  }

  private static decodeAvatar(encryptedAvatar: string): string | null {
    try {
      const K_Key = this.getKKey();
      if (!K_Key) return null;
  
      // Расшифровываем аватар
      const decryptedAvatar = decrypt(K_Key, encryptedAvatar, 'avatar');
      
      // Проверяем, что decryptedAvatar не undefined и не пустая строка
      if (!decryptedAvatar) return null;
  
      // Заменяем символы и декодируем base64
      const base64Avatar = decryptedAvatar
        .replace(/-/g, '+')
        .replace(/_/g, '/');
  
      return base64Avatar;
    } catch (error) {
      console.error('Avatar decryption error:', error);
      return null;
    }
  }

static async getTenantsList(
    communityId: string, 
    buildingId: string
): Promise<TenantListItem[]> {
    const K_Key = this.getKKey();
    const token = this.getToken();
    
    if (!K_Key) {
        throw new Error('K_Key is missing');
    }

    if (!token) {
        throw new Error('Admin token is missing');
    }

    const encryptedCommunityId = encryptAES(K_Key, communityId);
    const encryptedBuildingId = encryptAES(K_Key, buildingId);

    if (!encryptedCommunityId || !encryptedBuildingId) {
        throw new Error('Failed to encrypt IDs');
    }

    const response = await ApiService.request<any[]>({
        url: "https://mileva.rs/admin/tenant/get/list",
        method: 'GET',
        headers: {
            'community-id': encryptedCommunityId,
            'building-id': encryptedBuildingId
        }
    });

    if (!response.success) {
        throw new Error(response.error || 'Failed to fetch tenants list');
    }

    return response.data.map(item => {
        const safeDecrypt = (value: string, fieldName: string) => {
            try {
                return decrypt(K_Key!, value, fieldName) || '';
            } catch (error) {
                console.error(`Decryption error for ${fieldName}:`, error);
                return '';
            }
        };

        const decryptedId = safeDecrypt(item.id, 'id');

        return {
            id: decryptedId,
            name: safeDecrypt(item.name, 'name'),
            surname: safeDecrypt(item.surname, 'surname'),
            jmbg: safeDecrypt(item.jmbg, 'jmbg'),
            phone: safeDecrypt(item.phone, 'phone'),
            room_id: safeDecrypt(item.apt, 'apt'),
            status: safeDecrypt(item.status, 'status'),
            avatar: item.avatar 
                ? this.decodeAvatar(item.avatar)
                : undefined,
            build_id: '' // Если нужно, добавьте логику получения build_id
        };
    });
}
}