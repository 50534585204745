import { ApiService } from '../ApiService';
import { decrypt, encryptAES } from '../../../src/pages/srp-lib/srp';
import crypto from 'crypto';

// Interface updates to match documentation
export interface Issue {
  id: string;
  title: string;
  state: string; 
  created_at: string;
  tenant: string;
  tenant_name: string;
  tenant_surname: string;
  rating: string;
  msg_count: string;
}

export interface IssueDetailed extends Omit<Issue, 'tenant_name' | 'tenant_surname'> {
  file_ids: string[];
  description?: string;
  closed_at?: string;
}

export interface FileMetadata {
  filename: string;
  description: string;
  tag: 'Before' | 'After';
}

export interface CreateIssueParams {
  communityId: string;
  title: string;
  description?: string;
  tenantId: string; // Updated to match documentation requirement
}

export interface EditIssueParams {
  communityId: string;
  issueId: string;
  title: string;
  description?: string;
}

export interface UpdateIssueStateParams {
  communityId: string;
  issueId: string;
  newState: 'To Do' | 'In Process' | 'Done' | 'Cancelled';
}

export interface UploadImageParams {
  communityId: string;
  issueId: string;
  file: File;
  description?: string;
  tag?: 'Before' | 'After';
}

// Updated error constants to match documentation
export const ISSUES_ERRORS = {
  THROTTLED: 'Слишком частые запросы',
  BAD_REQUEST: 'Неверный метод запроса или формат данных',
  MISSING_TOKEN: 'Отсутствует токен',
  MISSING_COMMUNITY_ID: 'Отсутствует ID сообщества',
  MISSING_TENANT_ID: 'Отсутствует ID жильца',
  MISSING_TITLE: 'Отсутствует заголовок',
  MISSING_ISSUE_ID: 'Отсутствует ID задачи',
  MISSING_FILE_ID: 'Отсутствует ID файла',
  BAD_ENCRYPTED_COMMUNITY_ID: 'Ошибка расшифровки ID сообщества',
  BAD_ENCRYPTED_TENANT_ID: 'Ошибка расшифровки ID жильца',
  BAD_ENCRYPTED_TITLE: 'Ошибка расшифровки заголовка',
  BAD_ENCRYPTED_DESCRIPTION: 'Ошибка расшифровки описания',
  BAD_ID_FORMAT: 'ID содержит недопустимые символы',
  SESSION_NOT_FOUND: 'Сессия не найдена',
  ACCESS_DENIED: 'Нет доступа к управлению сообществом',
  ISSUE_NOT_FOUND: 'Задача не найдена',
  NO_LONGER_EDITABLE: 'Время редактирования истекло (24 часа)',
  WRONG_STATE: 'Некорректный статус задачи',
  REFUSED: 'Статус может быть изменен только "вперед"',
  INVALID_IMAGE: 'Неверный формат изображения',
  ONLY_JPG_PNG: 'Поддерживаются только JPG и PNG файлы',
  NO_ISSUES_FOUND: 'Задачи не найдены',
  FILE_NOT_FOUND: 'Файл не найден',
  SERVER_MISSING_FILE: 'Ошибка сервера при отправке файла',
  WRONG_TAG: 'Неверный тег изображения'
};

// Добавим утилитные функции для работы с файлами
export class FileUtils {
    static async encryptFile(K_Key: string, file: File): Promise<Uint8Array | null> {
      try {
        // Преобразуем base64 ключ в Buffer и паддинг до 16 байт
        const K = Buffer.from(K_Key, "base64");
        const paddedKey = Buffer.concat([K, Buffer.alloc(16)]).subarray(0, 16);
  
        // Генерируем nonce (IV)
        const nonce = crypto.randomBytes(16);
        
        // Создаем шифр
        const cipher = crypto.createCipheriv("aes-128-gcm", paddedKey, nonce);
        
        // Шифруем данные
        const fileData = await file.arrayBuffer();
        const encrypted = cipher.update(Buffer.from(fileData));
        const final = cipher.final();
        
        // Получаем тег аутентификации
        const tag = cipher.getAuthTag();
  
        // Собираем все части вместе: nonce + tag + шифротекст
        return new Uint8Array(Buffer.concat([nonce, tag, encrypted, final]));
      } catch (error) {
        console.error('File encryption error:', error);
        return null;
      }
    }
  
    static decryptFile(K_Key: string, encryptedFile: Uint8Array): Uint8Array | null {
      const K = Buffer.from(K_Key, "base64");
      if (!K) {
        console.error("Session key not available");
        return null;
      }
  
      // Pad or truncate the session key to 16 bytes (128 bits)
      const paddedKey = Buffer.concat([K, Buffer.alloc(16)]).subarray(0, 16);
  
      try {
        // Extract nonce, tag, and ciphertext
        const nonce = encryptedFile.subarray(0, 16);
        const tag = encryptedFile.subarray(16, 32);
        const ciphertext = encryptedFile.subarray(32);
  
        // Create decipher for AES-128-GCM
        const decipher = crypto.createDecipheriv("aes-128-gcm", paddedKey, nonce);
        decipher.setAuthTag(tag);
       
        let decrypted = decipher.update(ciphertext);
        const finalBuffer = decipher.final();
        
        return new Uint8Array(Buffer.concat([decrypted, finalBuffer]));
      } catch (error) {    
        console.error('File decryption error:', error);
        return null;
      }
    }
  }

  export class IssuesApi {
    static async createIssue({ communityId, title, description, tenantId }: CreateIssueParams): Promise<void> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const response = await ApiService.request<{ response: string }>({
        url: "https://mileva.rs/admin/issue/create",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || ''
        },
        body: {
          title: encryptAES(K_Key, title),
          description: description ? encryptAES(K_Key, description) : undefined,
          tenant_id: encryptAES(K_Key, tenantId)
        }
      });
  
      if (!response.success) {
        throw new Error(ISSUES_ERRORS[response.error as keyof typeof ISSUES_ERRORS] || response.error);
      }
    }
  
    static async getIssuesList(communityId: string): Promise<Issue[]> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const response = await fetch("https://mileva.rs/admin/issue/get/list", {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || ''
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(ISSUES_ERRORS[errorData.error as keyof typeof ISSUES_ERRORS] || errorData.error || 'Failed to fetch reports');
      }
  
      const data = await response.json();
      
      return data.map((item: any) => ({
        id: decrypt(K_Key, item.id, "id") || '',
        title: decrypt(K_Key, item.title, "title") || '',
        state: decrypt(K_Key, item.state, "state") || '',
        created_at: decrypt(K_Key, item.created_at, "created_at") || '',
        tenant: decrypt(K_Key, item.tenant, "tenant") || '',
        tenant_name: decrypt(K_Key, item.tenant_name, "tenant_name") || '',
        tenant_surname: decrypt(K_Key, item.tenant_surname, "tenant_surname") || '',
        rating: decrypt(K_Key, item.rating, "rating") || '0',
        msg_count: decrypt(K_Key, item.msg_count, "msg_count") || '0'
      }));
    }
    
  
    static async getIssueDetails(communityId: string, issueId: string): Promise<IssueDetailed> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const response = await ApiService.request<IssueDetailed>({
        url: "https://mileva.rs/admin/issue/get/one",
        method: 'GET',
        headers: {
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || '',
          'issue-id': encryptAES(K_Key, issueId) || ''
        }
      });
  
      if (!response.success || !response.data) {
        throw new Error(ISSUES_ERRORS[response.error as keyof typeof ISSUES_ERRORS] || response.error);
      }
  
      const data = response.data;
      return {
        id: decrypt(K_Key, data.id, 'id') || '',
        title: decrypt(K_Key, data.title, 'title') || '',
        state: decrypt(K_Key, data.state, 'state') || '',
        created_at: decrypt(K_Key, data.created_at, 'created_at') || '',
        closed_at: data.closed_at ? decrypt(K_Key, data.closed_at, 'closed_at') : undefined,
        description: data.description ? decrypt(K_Key, data.description, 'description') : undefined,
        tenant: decrypt(K_Key, data.tenant, 'tenant') || '',
        rating: decrypt(K_Key, data.rating, 'rating') || '',
        msg_count: decrypt(K_Key, data.msg_count, 'msg_count') || '',
        file_ids: data.file_ids.map(id => decrypt(K_Key, id, 'file_id') || '')
      };
    }
  
    static async editIssue({ communityId, issueId, title, description }: EditIssueParams): Promise<void> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const response = await ApiService.request<{ response: string }>({
        url: "https://mileva.rs/admin/issue/edit",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || ''
        },
        body: {
          issue_id: encryptAES(K_Key, issueId),
          title: encryptAES(K_Key, title),
          description: description ? encryptAES(K_Key, description) : undefined
        }
      });
  
      if (!response.success) {
        throw new Error(ISSUES_ERRORS[response.error as keyof typeof ISSUES_ERRORS] || response.error);
      }
    }
  
    static async updateIssueState({ communityId, issueId, newState }: UpdateIssueStateParams): Promise<void> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const response = await ApiService.request<{ response: string }>({
        url: "https://mileva.rs/admin/issue/update",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || ''
        },
        body: {
          issue_id: encryptAES(K_Key, issueId),
          new_state: encryptAES(K_Key, newState)
        }
      });
  
      if (!response.success) {
        throw new Error(ISSUES_ERRORS[response.error as keyof typeof ISSUES_ERRORS] || response.error);
      }
    }
  
    static async uploadImage({ communityId, issueId, file, description, tag }: UploadImageParams): Promise<void> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const encryptedFile = await FileUtils.encryptFile(K_Key, file);
      if (!encryptedFile) {
        throw new Error('Failed to encrypt file');
      }
  
      const formData = new FormData();
      formData.append('issue_id', encryptAES(K_Key, issueId) || '');
      formData.append('file', new Blob([encryptedFile]));
      
      if (description) {
        formData.append('description', encryptAES(K_Key, description) || '');
      }
      
      if (tag) {
        formData.append('tag', encryptAES(K_Key, tag) || '');
      }
  
      const response = await fetch("https://mileva.rs/admin/issue/upload", {
        method: 'POST',
        headers: {
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || ''
        },
        body: formData
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(ISSUES_ERRORS[errorData.error as keyof typeof ISSUES_ERRORS] || 'Upload failed');
      }
    }
  
    static async downloadImage(communityId: string, issueId: string, fileId: string): Promise<{
      data: string;
      metadata: FileMetadata;
    }> {
      const K_Key = localStorage.getItem("K_Key");
      if (!K_Key) throw new Error('K_Key is missing');
  
      const response = await fetch("https://mileva.rs/admin/issue/download", {
        headers: {
          'token': localStorage.getItem("authToken") || '',
          'community-id': encryptAES(K_Key, communityId) || '',
          'issue-id': encryptAES(K_Key, issueId) || '',
          'file-id': encryptAES(K_Key, fileId) || ''
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(ISSUES_ERRORS[errorData.error as keyof typeof ISSUES_ERRORS] || 'Download failed');
      }
  
      const imageDataEncrypted = await response.arrayBuffer();
      const decryptedImageData = FileUtils.decryptFile(K_Key, new Uint8Array(imageDataEncrypted));
  
      if (!decryptedImageData) {
        throw new Error('Failed to decrypt image');
      }
  
      const base64Image = btoa(
        new Uint8Array(decryptedImageData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
  
      const metadata: FileMetadata = {
        filename: decrypt(K_Key, response.headers.get('X-Filename') || '', 'filename') || '',
        description: decrypt(K_Key, response.headers.get('X-Description') || '', 'description') || '',
        tag: (decrypt(K_Key, response.headers.get('X-Tag') || '', 'tag') || 'Before') as 'Before' | 'After'
      };
  
      return {
        data: base64Image,
        metadata
      };
    }
  }