import React, { useState, useEffect } from 'react';
import styles from './ReportDetails.module.css';
import { decrypt, encryptAES } from '../../srp-lib/srp';
import backIcon from '../svg_images/backIcon.svg';
import { useAuth } from '../../context/AuthorizationContext';
import crypto from "crypto";
import editIcon from '../svg_images/editIcon.svg';
import UserImage from './images/UserImage.png';
import ImageModal from './ImageModal';
import StatusDropdown from './StatusDropdown';
import { RatingDisplay } from './RatingComponent';

import { IssuesApi } from '../../../services/api/IssuesApi';

interface ReportDetails {
  id: string;
  title: string;
  state: string;
  createdAt: Date;
  tenantName: string;
  tenantSurname: string;
  rating: number;
  messageCount: number;
  description?: string;
  files: IssueFile[];
}

interface IssueFile {
  filename: string;
  description: string;
  tag: 'Before' | 'After';
  data: string;
}

interface ReportDetailsProps {
  report: ReportDetails; // Изменено: теперь принимаем готовый объект отчета
  communityId: string;
  onBack: () => void;
}

const ReportDetails: React.FC<ReportDetailsProps> = ({ report, communityId, onBack }) => {
  const [files, setFiles] = useState<IssueFile[]>([]);
  const { token, K_Key } = useAuth();
  const [description, setDescription] = useState<string>('');
  const [localRating] = useState<number>(report.rating);
  const [selectedImage, setSelectedImage] = useState<IssueFile | null>(null);

  const mapApiStatusToUiStatus = (apiStatus: string): string => {
    const statusMap = {
      'To Do': 'pending',
      'In Process': 'in-process',
      'Done': 'done',
      'Cancelled': 'archived'
    };
    return statusMap[apiStatus as keyof typeof statusMap] || apiStatus;
  };

  const [localReport, setLocalReport] = useState({
    ...report,
    state: mapApiStatusToUiStatus(report.state)
  });
   


  const handleStatusChange = async (newStatus: string) => {
    try {
      await IssuesApi.updateIssueState({
        communityId,
        issueId: report.id,
        newState: newStatus as 'To Do' | 'In Process' | 'Done' | 'Cancelled'
      });

      setLocalReport(prev => ({
        ...prev,
        state: newStatus
      }));
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  

  const fetchDetails = async () => {
    try {
      // Получаем детальную информацию о задаче
      const details = await IssuesApi.getIssueDetails(communityId, report.id);
      setDescription(details.description || '');

      // Загружаем все файлы
      const filePromises = details.file_ids.map(async (fileId) => {
        try {
          const { data, metadata } = await IssuesApi.downloadImage(
            communityId,
            report.id,
            fileId
          );

          return {
            filename: metadata.filename,
            description: metadata.description,
            tag: metadata.tag,
            data: data
          };
        } catch (error) {
          console.error(`Error loading file ${fileId}:`, error);
          return null;
        }
      });

      const loadedFiles = (await Promise.all(filePromises)).filter((file): file is IssueFile => file !== null);
      setFiles(loadedFiles);
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [report.id, communityId]);

  const formatDate = (date: Date) => {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} at ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={onBack}>
        <img src={backIcon} alt="Back" />
        Back
      </button>

      <div className={styles.createdBy}>Created by</div>
      
      <div className={styles.header}>
        <div className={styles.userInfo}>
          <img src={UserImage} alt="User" className={styles.userImage} />
          <div className={styles.userDetails}>
            <div className={styles.userName}>
              {`${localReport.tenantName} ${localReport.tenantSurname}`}
            </div>
            <div className={styles.createdAt}>
              {formatDate(localReport.createdAt)}
            </div>
          </div>
        </div>
        
        <div className={styles.statusContainer}>
            <div className={`${styles.statusBadge} ${styles[localReport.state.toLowerCase().replace('-', '')]}`}>
                <span className={styles.statusIndicator} />
                {localReport.state === "pending" ? "Pending" : 
                localReport.state === "in-process" ? "In Process" : 
                localReport.state === "done" ? "Done" : 
                "Archived"}
            </div>
            <StatusDropdown
                currentStatus={localReport.state}
                issueId={localReport.id}
                communityId={communityId}
                onStatusChange={handleStatusChange}
            />
        </div>
      </div>

      <h1 className={styles.title}>{localReport.title}</h1>

      <div className={styles.description}>
        <p>{description}</p>
      </div>

      <div className={styles.issueImages}>
        {files.map((file, index) => (
          <div
            key={`${localReport.id}-${index}`}
            className={styles.imageContainer}
            onClick={() => setSelectedImage(file)}
          >
            <img
              src={`data:image/jpeg;base64,${file.data}`}
              alt={file.description || 'Report image'}
              className={styles.issueImage}
            />
            <div className={styles.imageTag}>{file.tag}</div>
          </div>
        ))}
      </div>

      <ImageModal
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        imageUrl={selectedImage ? `data:image/jpeg;base64,${selectedImage.data}` : ''}
        altText={selectedImage?.description}
      />

      <div className={styles.ratingContainer}>
        <RatingDisplay rating={localRating} />
      </div>

      <div className={styles.comments}>
        <h2 className={styles.commentsTitle}>
          Comments ({localReport.messageCount})
        </h2>
        <input 
          type="text" 
          className={styles.commentInput} 
          placeholder="Add a comment"
        />
      </div>
    </div>
  );
};

export default ReportDetails;