//src/components/modals/DeleteTenantModal.tsx
import React, { useState } from 'react';
import styles from './styles/DeleteTenantModal.module.css';

interface DeleteTenantModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  tenantId: string;
  tenantName: string;
}

export const DeleteTenantModal: React.FC<DeleteTenantModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  tenantId,
  tenantName
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleDelete = async () => {
    setIsLoading(true);
    
    try {
      // Здесь будет API запрос на удаление
      console.log('Deleting tenant:', {
        tenantId,
        tenantName
      });
      
      // После успешного удаления
      onConfirm();
      onClose();
    } catch (error) {
      console.error('Error deleting tenant:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2 className={styles.modalTitle}>
          Are you sure that you want to delete this tenant?
        </h2>

        <div className={styles.buttonContainer}>
          <button 
            type="button" 
            className={styles.cancelButton}
            onClick={onClose}
            disabled={isLoading}
          >
            No, cancel
          </button>
          <button 
            type="button" 
            className={styles.deleteButton}
            onClick={handleDelete}
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : 'Yes, delete'}
          </button>
        </div>
      </div>
    </div>
  );
};