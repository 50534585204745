import React from 'react';
import styles from './Auth.module.css';
import { SignInForm } from './SignInForm'; 

export const AuthPage: React.FC = () => {
  return (
    <div className={styles.authContainer}>
      <header className={styles.header}>
      
        <div className={styles.brandContainer}>
          <h1 className={styles.brandName}>Mileva</h1>
          <p className={styles.brandTagline}>for managers</p>
        </div>
        <div className={styles.ellipse}></div>
      </header>
      <SignInForm />
    </div>
  );
};

