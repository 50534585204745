import React, { useEffect } from 'react';
import styles from './modules/DateTimePickerContainer.module.css';
import DatePicker from '../../../modules/date_picker/DatePicker';
import TimePicker from '../../../modules/time_picker/TimePicker';

interface DateTimePickerContainerProps {
  isOpen: boolean;
  currentPicker: 'date' | 'time' | null;
  calendarType: "start" | "end";
  startDate?: string;
  endDate?: string;
  startTime?: string;  
  endTime?: string;
  selectedDate?: string;
  onClose: () => void;
  onDateSelect: (date: string) => void;
  onTimeSelect: (time: string) => void;
}

const DateTimePickerContainer: React.FC<DateTimePickerContainerProps> = ({
  isOpen,
  currentPicker,
  calendarType,
  startDate,
  endDate,
  startTime,  
  endTime, 
  onClose,
  onDateSelect,
  onTimeSelect
}) => {
  

  // Добавляем useEffect для отладки
  useEffect(() => {
    console.log('DateTimePickerContainer mounted/updated with:', {
      currentPicker,
      calendarType,
      startDate,
      endDate,
      startTime,  
      endTime,    
      blockEarlyTime: getBlockEarlyTime()
    });
  }, [currentPicker, calendarType, startDate, endDate]);

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const getBlockEarlyTime = () => {
    // Определяем, какую дату проверять на основе типа календаря
    const dateToCheck = calendarType === "start" ? startDate : endDate;
    console.log('Checking date:', dateToCheck);
  
    if (!dateToCheck) {
      console.log('No date to check');
      return false;
    }
  
    try {
      const selectedDate = new Date(dateToCheck);
      const today = new Date();
      
      // Сбрасываем время для сравнения только дат
      const selectedDateOnly = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      
      const todayDateOnly = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
  
      // Проверяем, не является ли дата окончания той же, что и дата начала
      if (calendarType === "end" && startDate) {
        const startDateOnly = new Date(
          new Date(startDate).getFullYear(),
          new Date(startDate).getMonth(),
          new Date(startDate).getDate()
        );
  
        // Если даты совпадают и дата начала - сегодня, блокируем ранние часы
        if (selectedDateOnly.getTime() === startDateOnly.getTime() && 
            startDateOnly.getTime() === todayDateOnly.getTime()) {
          const shouldBlock = true;
          console.log('End date matches start date and is today:', { shouldBlock });
          return shouldBlock;
        }
      } else {
        // Для даты начала оставляем прежнюю логику
        const shouldBlock = selectedDateOnly.getTime() === todayDateOnly.getTime();
        console.log('Date comparison:', {
          selectedDateOnly: selectedDateOnly.toISOString(),
          todayDateOnly: todayDateOnly.toISOString(),
          shouldBlock
        });
        return shouldBlock;
      }
  
      return false;
    } catch (e) {
      console.error('Error comparing dates:', e);
      return false;
    }
  };

  const blockEarlyTime = getBlockEarlyTime();

  console.log('Final blockEarlyTime value:', blockEarlyTime); // Отладочный вывод

  if (!isOpen) return null;
  return (
    <div className={styles.pickerOverlay} onClick={handleOverlayClick}>
      <div className={styles.pickerContent} onClick={(e) => e.stopPropagation()}>
        {currentPicker === 'date' && (
          <DatePicker
            closeCalendar={() => {}}
            setDate={onDateSelect}
            calendarType={calendarType}
            startDate={calendarType === "end" ? startDate : undefined}
          />
        )}
        {currentPicker === 'time' && (
          <TimePicker
            onClose={() => {}}
            onTimeSelect={onTimeSelect}
            blockEarlyTime={blockEarlyTime}
            initialTime={calendarType === "start" ? startTime : endTime}
          />
        )}
      </div>
    </div>
  );
};

export default DateTimePickerContainer;