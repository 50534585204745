import React from 'react';
import styles from './RatingComponent.module.css';

interface RatingDisplayProps {
  rating: number;
}

export const RatingDisplay: React.FC<RatingDisplayProps> = ({ rating }) => {
  const getRatingDisplay = () => {
    const isNegative = rating < 0;
    const isPositive = rating > 0;
    const absoluteValue = Math.abs(rating);
    
    let className = styles.ratingBox;
    if (isNegative) className += ` ${styles.negativeRating}`;
    if (isPositive) className += ` ${styles.positiveRating}`;
    
    return (
      <div className={className}>
        {rating !== 0 && (isNegative ? '-' : '+')}
        {absoluteValue}
      </div>
    );
  };

  return (
    <div className={styles.ratingContainer}>
      <div 
        className={styles.upvote} 
        aria-label="Upvote" 
      />
      {getRatingDisplay()}
      <div 
        className={styles.downvote} 
        aria-label="Downvote" 
      />
    </div>
  );
};