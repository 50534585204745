//ReportsMain.tsx
import React, { useState, useEffect } from "react";
import styles from "./ReportsMain.module.css";
import { useAuth } from '../../context/AuthorizationContext';
import { useBuildings } from '../../context/BuildingContext';
import { encryptAES, decrypt } from '../../srp-lib/srp';
import ReportDetails from './ReportDetails';
import searchIcon from "./images/SearchIcon.png";
import plusAddButtonIcon from "../svg_images/plusAddButton.svg";
import UserImage from "./images/UserImage.png";
import { RatingDisplay } from './RatingComponent';
import { SortSection } from "../comm_components/SortSection";
import { useSorting } from "../comm_components/hooks/useSorting";
import { IssuesApi } from "../../../services/api/IssuesApi";


interface IssueFile {
  filename: string;
  description: string;
  tag: 'Before' | 'After';
  data: string;
}

interface Report {
  id: string;
  title: string;
  state: string;
  createdAt: Date;
  tenantName: string;
  tenantSurname: string;
  rating: number;
  messageCount: number;
  description?: string;
  files: IssueFile[];
}

const ReportsMain: React.FC = () => {
  const { token, K_Key } = useAuth();
  const { selectedBuilding } = useBuildings();
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFilter, setCurrentFilter] = useState<'all' | 'pending' | 'in-process' | 'done'>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [isReportDetailsVisible, setIsReportDetailsVisible] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const [selectedCommunityId, setSelectedCommunityId] = useState<string | null>(null);

  const SORT_OPTIONS = [
    { key: 'topic', label: 'Topic' },
    { key: 'author', label: 'Author' },
    { key: 'date', label: 'Date' },
    { key: 'rating', label: 'Rating' }
  ];

  const SORT_FUNCTIONS = {
    topic: (a: Report, b: Report) => a.title.localeCompare(b.title),
    author: (a: Report, b: Report) => 
      `${a.tenantName} ${a.tenantSurname}`.localeCompare(
        `${b.tenantName} ${b.tenantSurname}`
      ),
    date: (a: Report, b: Report) => b.createdAt.getTime() - a.createdAt.getTime(),
    rating: (a: Report, b: Report) => b.rating - a.rating
  };



  const mapApiStatusToUiStatus = (apiStatus: string): string => {
    const statusMap: Record<string, string> = {
      'To Do': 'pending',
      'In Process': 'in-process',
      'Done': 'done',
      'Cancelled': 'archived'
    };
    return statusMap[apiStatus] || apiStatus;
  };

  const STATUS_COLORS = {
    'pending': '#FFC107',      // желтый
    'in-process': '#F28900',   // оранжевый
    'done': '#28A745',         // зеленый
    'archived': '#6C757D'      // серый
  };

 

  const fetchReports = async () => {
    if (!selectedBuilding || !token || !K_Key) {
      console.error("Missing required data:", {
        hasSelectedBuilding: !!selectedBuilding,
        hasToken: !!token,
        hasK_Key: !!K_Key
      });
      setIsLoading(false);
      return;
    }
  
    try {
      console.log("Fetching reports for community:", selectedBuilding.community_id);
      
      const issuesList = await IssuesApi.getIssuesList(selectedBuilding.community_id);
      
      console.log("Raw issues list:", issuesList);
      
      const transformedReports: Report[] = issuesList.map(issue => ({
        id: issue.id,
        title: issue.title,
        state: mapApiStatusToUiStatus(issue.state),
        createdAt: new Date(issue.created_at),
        tenantName: issue.tenant_name,
        tenantSurname: issue.tenant_surname,
        rating: parseInt(issue.rating),
        messageCount: parseInt(issue.msg_count),
        files: []
      }));
  
      console.log('Transformed reports:', transformedReports);
      setReports(transformedReports);
    } catch (error) {
      console.error("Error fetching reports:", {
        error,
        message: error instanceof Error ? error.message : 'Unknown error',
        selectedBuildingId: selectedBuilding?.community_id
      });
      setReports([]); 
    } finally {
      setIsLoading(false);
    }
  };


  // Фильтрация перед сортировкой
  const filteredReports = reports
    .filter(report => 
      currentFilter === 'all' || report.state.toLowerCase() === currentFilter
    )
    .filter(report => 
      !searchQuery || 
      report.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      `${report.tenantName} ${report.tenantSurname}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

  // Используем хук для сортировки
  const {
    sortedData,
    sortBy,
    sortDirection,
    handleSortChange
  } = useSorting(filteredReports, SORT_OPTIONS, SORT_FUNCTIONS);

  // Остальные методы остаются без изменений
  const handleIssueOpen = (reportId: string) => {
    setSelectedReportId(reportId);
    if (selectedBuilding) {
      setSelectedCommunityId(selectedBuilding.community_id);
    } else {
      setSelectedCommunityId(null);
    }
    setIsReportDetailsVisible(true);
  };

  const handleBackToReportsList = () => {
    setIsReportDetailsVisible(false);
  };


 

  useEffect(() => {
    fetchReports();
  }, [selectedBuilding, token, K_Key]);

  if (isLoading) {
    return <div className={styles.loadingContainer}>Loading...</div>;
  }

  return (
    <div className={styles.mainContainer}>
      {isReportDetailsVisible && selectedReportId && selectedCommunityId ? (
        <ReportDetails
        report={reports.find(report => report.id === selectedReportId)!}       
        communityId={selectedCommunityId}
        onBack={handleBackToReportsList}
      />
      ) : (
        <>

          <div className={styles.topRow}>
            <div className={styles.searchBox}>
              <img src={searchIcon} alt="Search" className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search"
                className={styles.searchInput}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <button className={styles.btnAdd}>
              <img src={plusAddButtonIcon} alt="+" />
              <span>Add report</span>
            </button>
          </div>

          <div className={styles.reportStatusFilters}>
            <button 
              className={currentFilter === 'all' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('all')}
            >
              All
            </button>
            <button 
              className={currentFilter === 'pending' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('pending')}
            >
              Pending
            </button>
            <button 
              className={currentFilter === 'in-process' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('in-process')}
            >
              In process
            </button>
            <button 
              className={currentFilter === 'done' ? styles.filterSelected : styles.filterDeselected}
              onClick={() => setCurrentFilter('done')}
            >
              Done
            </button>
          </div>

          <div className={styles.sortBySection}>          
            <SortSection 
              options={SORT_OPTIONS}
              onSortChange={handleSortChange}
              initialSortBy="date"
              currentSortBy={sortBy}
              currentDirection={sortDirection}
            />
          </div>

          {sortedData.map((report) => (
            <div 
              className={styles.issueBlock} 
              key={report.id} 
              onClick={() => handleIssueOpen(report.id)}
            >
              <div className={styles.issueHeader}>{report.title}</div>
              <div className={styles.issueInfo}>
                <div className={styles.userInfo}>
                  <img src={UserImage} alt="User" className={styles.userImage} />
                  <div className={styles.issueUsername}>
                    {`${report.tenantName} ${report.tenantSurname}`}
                  </div>
                </div>

                <div className={styles.issueDate}>
                  {report.createdAt.toLocaleDateString()}
                </div>

                <div className={styles.issueRating}>
                  <RatingDisplay  rating={report.rating} />                   
                </div>

                <div className={styles.issueMessage}>
                  <div className={styles.messageLogo} />
                  <span className={styles.messageCounter}>{report.messageCount}</span>
                </div>

                <div className={styles.issueStatus}>
                  <div 
                    className={styles.statusMarker} 
                    style={{ 
                      backgroundColor: STATUS_COLORS[report.state as keyof typeof STATUS_COLORS] || '#ADB5BD',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      marginRight: '8px'
                    }} 
                  />
                  <span 
                    className={`${styles.statusText} ${styles[report.state.replace('-', '')]}`}
                  >
                    {report.state === "done" ? "Done" : 
                    report.state === "in-process" ? "In Process" : 
                    report.state === "archived" ? "Archived" : 
                    "Pending"}
                  </span>
                </div>

                <button className={styles.issueOpen}  />
              </div>

            
            </div>
          ))}

        </>
      )}
    </div>
  );
};

export default ReportsMain;