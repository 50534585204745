// parsePlaceholders.ts
import { JSONContent } from "@tiptap/core";
import { Variable } from "./types";

/**
 * Разбивает исходный текст по переносам строк => параграфы
 * и по шаблонам переменных, формируя атомарные ноды для TipTap.
 */
export function parseInitialTextWithPlaceholders(
  text: string,
  variables: Variable[]
): JSONContent {
  // Создаем регулярное выражение на основе переменных
  const variablePattern = new RegExp(
    `\\[(${variables.map(v => v.key).join('|')})\\]`,
    'g'
  );

  // Делим на строки по \n (или \r?\n)
  const paragraphs = text.split(/\r?\n/).map((line) => {
    const childNodes: JSONContent[] = [];

    // Создаем массив частей, разделенных плейсхолдерами
    const parts = line.split(variablePattern);
    
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      
      // Проверяем, является ли часть ключом переменной
      const matchingVar = variables.find(v => v.key === part);

      if (matchingVar) {
        childNodes.push({
          type: "variable",
          attrs: { 
            varKey: matchingVar.key,
            displayValue: matchingVar.value || `[${matchingVar.key}]`,
            className: matchingVar.displayFormat?.className,
            prefix: matchingVar.displayFormat?.prefix || "",
            suffix: matchingVar.displayFormat?.suffix || "",
          },
        });
      } else if (part) {
        childNodes.push({
          type: "text",
          text: part,
        });
      }
    }

    return {
      type: "paragraph",
      content: childNodes,
    };
  });

  return {
    type: "doc",
    content: paragraphs.filter(p => p.content?.length > 0),
  };
}