// src/services/api/AdminAccountApi.ts
import { ApiService } from '../ApiService';
import { decrypt } from '../../../src/pages/srp-lib/srp';

export interface AdminNameResponse {
  name: string;
  surname: string;
}

export const ADMIN_ACCOUNT_ERRORS = {
  THROTTLED: 'Слишком частые запросы (не чаще одного раза в 10 секунд)',
  BAD_REQUEST: 'Неверный метод запроса',
  MISSING_TOKEN: 'Отсутствует или пустой токен',
  SESSION_NOT_FOUND: 'Не найдена сессия администратора по токену'
};

export class AdminAccountApi {
    private static getKKey(): string | null {
      return localStorage.getItem("K_Key");
    }
  
    static async getName(): Promise<AdminNameResponse> {
      const K_Key = this.getKKey();
      if (!K_Key) {
        throw new Error('K_Key is missing');
      }
    
      const response = await ApiService.request<{name?: string, surname?: string}>({
        url: "https://mileva.rs/admin/account/get/name",
        method: 'GET'
      });
    
      if (!response.success || !response.data) {
        const errorMessage = ADMIN_ACCOUNT_ERRORS[response.error as keyof typeof ADMIN_ACCOUNT_ERRORS] 
          || response.error 
          || 'Неизвестная ошибка';
        
        throw new Error(errorMessage);
      }
      
      return {
        name: response.data.name && K_Key 
          ? (decrypt(K_Key, response.data.name, 'name') || 'Unknown')
          : 'Unknown',
        surname: response.data.surname && K_Key 
          ? (decrypt(K_Key, response.data.surname, 'surname') || 'User')
          : 'User'
      };
    }
  }