import React from "react";
import styles from "./modules/ManageBlock.module.css";

interface MenuItem {
  name: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

interface ManageBlockProps {
  menuItems: MenuItem[];
  activeIndex: number;
  onMenuClick: (index: number) => void;
}

const ManageBlock: React.FC<ManageBlockProps> = ({
  menuItems,
  activeIndex,
  onMenuClick,
}) => {
  return (    
      <div className={styles.manageBlock}>
        <h4 className={styles.manageTitle}>Manage</h4>
        <ul className={styles.menuList}>
            {menuItems.map((item, index) => {
            const { Icon } = item;
            const isActive = index === activeIndex;

            return (
                <li
                key={index}
                className={`${styles.menuItem} ${isActive ? styles.active : ""}`}
                onClick={() => onMenuClick(index)}
              >
                <div className={styles.menuContent}>
                  <Icon className={`${styles.menuIcon} ${isActive ? styles.activeIcon : ""}`} />
                  <div>{item.name}</div>
                </div>
                {isActive && <div className={styles.marker}></div>} {/* Маркер справа */}
              </li>
            );
            })}
        </ul>
      </div>
   
  );
};

export default ManageBlock;
