// SortSection.tsx
import React from 'react';
import styles from './styles/SortSection.module.css';

interface SortOption {
  key: string;
  label: string;
}

interface SortSectionProps {
  options: SortOption[];
  onSortChange: (sortBy: string) => void;
  initialSortBy?: string;
  currentSortBy?: string;
  currentDirection?: 'asc' | 'desc';
}

export const SortSection: React.FC<SortSectionProps> = ({ 
  options, 
  onSortChange, 
  initialSortBy = options[0].key,
  currentSortBy,
  currentDirection
}) => {
  return (
    <div className={styles.sortSection}>
      <span className={styles.sortByHeader}>Sort by:</span>
      {options.map((option) => (
        <button
          key={option.key}
          className={
            currentSortBy === option.key 
              ? styles.sortByTextActive 
              : styles.sortByText
          }
          onClick={() => onSortChange(option.key)}
        >
          {option.label}
          {currentSortBy === option.key && 
            (currentDirection === 'asc' ? ' ↑' : ' ↓')}
        </button>
      ))}
    </div>
  );
};