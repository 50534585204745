import React from 'react';
import styles from './styles/VotingResultsCircle.module.css';

interface VotingResult {
  votesYes: number;
  votesNo: number;
  votesSkip: number;
}

interface VotingResultsCircleProps {
  results: VotingResult;
}

const VotingResultsCircle: React.FC<VotingResultsCircleProps> = ({ results }) => {
    const total = results.votesYes + results.votesNo + results.votesSkip;
  
    // Calculate percentages
    const yesPercentage = total > 0 ? (results.votesYes / total) * 100 : 0;
    const noPercentage = total > 0 ? (results.votesNo / total) * 100 : 0;
    const skipPercentage = total > 0 ? (results.votesSkip / total) * 100 : 0;
  
    // SVG parameters - теперь в реальных пикселях
    const svgSize = 500;            // Физический размер SVG в пикселях
    const center = svgSize / 2;     // Центр окружности
    const radius = 125;            // Радиус кольца
    const strokeWidth = 50;         // Толщина кольца
    const labelDistance = radius + strokeWidth/2 + 20; // Отступ текста от кольца
  
    // Calculate positions for segments and labels
    const circumference = 2 * Math.PI * radius;
    const yesStroke = (yesPercentage / 100) * circumference;
    const noStroke = (noPercentage / 100) * circumference;
    const skipStroke = (skipPercentage / 100) * circumference;
  
    // Calculate angles for label placement
    const yesEndAngle = (yesPercentage / 100) * 2 * Math.PI;
    const noEndAngle = yesEndAngle + (noPercentage / 100) * 2 * Math.PI;
    const skipEndAngle = noEndAngle + (skipPercentage / 100) * 2 * Math.PI;
  
    // Calculate label positions with flipped Y coordinates
    const getLabelPosition = (startAngle: number, endAngle: number) => {
      const angle = startAngle + (endAngle - startAngle) / 2;
      const degrees = (angle * 180 / Math.PI) % 360;
      
      return {
        x: center + labelDistance * Math.cos(angle),
        y: center - labelDistance * Math.sin(angle),
        angle: degrees,
        textAnchor: getTextAnchor(angle)
      };
    };
  
    // Helper function for text anchor based on angle
    const getTextAnchor = (angle: number) => {
      const degrees = ((angle * 180 / Math.PI)) % 360;
      if (Math.abs(degrees - 90) < 5 || Math.abs(degrees - 270) < 5) {
        return 'middle';
      }
      if (degrees > 90 && degrees < 270) return 'end';
      return 'start';
    };
  
    const yesLabel = getLabelPosition(0, yesEndAngle);
    const noLabel = getLabelPosition(yesEndAngle, noEndAngle);
    const skipLabel = getLabelPosition(noEndAngle, skipEndAngle);
  
    return (
      <div className={styles.container}>
        <svg 
          width={svgSize} 
          height={svgSize} 
          viewBox={`0 0 ${svgSize} ${svgSize}`}
          className={styles.svg}
        >
          <g transform={`scale(1, -1) translate(0, -${svgSize})`}>
            {/* Background circle */}
            <circle
              cx={center}
              cy={center}
              r={radius}
              fill="none"
              stroke="#E9ECEF"
              strokeWidth={strokeWidth}
            />
              
            {/* Yes votes (green) */}
            <circle
              cx={center}
              cy={center}
              r={radius}
              fill="none"
              stroke="#328343"
              strokeWidth={strokeWidth}
              strokeDasharray={`${yesStroke} ${circumference}`}
              transform={`rotate(0 ${center} ${center})`}
              className={styles.segment}
            />
              
            {/* No votes (red) */}
            <circle
              cx={center}
              cy={center}
              r={radius}
              fill="none"
              stroke="#E43E28"
              strokeWidth={strokeWidth}
              strokeDasharray={`${noStroke} ${circumference}`}
              strokeDashoffset={-yesStroke}
              transform={`rotate(0 ${center} ${center})`}
              className={styles.segment}
            />
              
            {/* Skip votes (gray) */}
            <circle
              cx={center}
              cy={center}
              r={radius}
              fill="none"
              stroke="#6C757D"
              strokeWidth={strokeWidth}
              strokeDasharray={`${skipStroke} ${circumference}`}
              strokeDashoffset={-(yesStroke + noStroke)}
              transform={`rotate(0 ${center} ${center})`}
              className={styles.segment}
            />
          </g>
  
          {/* Labels */}
          {results.votesYes > 0 && (
            <text
              x={yesLabel.x}
              y={yesLabel.y}
              textAnchor={yesLabel.textAnchor}
              dominantBaseline="middle"
              fill="#328343"
              className={styles.label}
            >
              Za — {results.votesYes} ({yesPercentage.toFixed(1)}%)
            </text>
          )}
  
          {results.votesNo > 0 && (
            <text
              x={noLabel.x}
              y={noLabel.y}
              textAnchor={noLabel.textAnchor}
              dominantBaseline="middle"
              fill="#E43E28"
              className={styles.label}
            >
              Protiv — {results.votesNo} ({noPercentage.toFixed(1)}%)
            </text>
          )}
  
          {results.votesSkip > 0 && (
            <text
              x={skipLabel.x}
              y={skipLabel.y}
              textAnchor={skipLabel.textAnchor}
              dominantBaseline="middle"
              fill="#6C757D"
              className={styles.label}
            >
              Uzdrzan — {results.votesSkip} ({skipPercentage.toFixed(1)}%)
            </text>
          )}
  
          {/* Center text */}
          <text
            x={center}
            y={center}
            textAnchor="middle"
            className={styles.totalResponds}
          >
            {total}
            <tspan dx="0.3em">responds</tspan>
          </text>
        </svg>
      </div>
    );
  };
  
  export default VotingResultsCircle;