// useSorting.ts
import { useState, useMemo } from 'react';

interface SortOption {
  key: string;
  label: string;
}

export const useSorting = <T>(
  initialData: T[], 
  options: SortOption[], 
  sortFunctions: Record<string, (a: T, b: T) => number>,
  initialSortBy: string = options[0].key
) => {
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const handleSortChange = (newSortBy: string) => {
    const newDirection = newSortBy === sortBy && sortDirection === 'desc' ? 'asc' : 'desc';
    setSortBy(newSortBy);
    setSortDirection(newDirection);
  };

  const sortedData = useMemo(() => {
    return [...initialData].sort((a, b) => {
      const sortFunction = sortFunctions[sortBy] || ((a: T, b: T) => 0);
      const comparison = sortFunction(a, b);
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  }, [initialData, sortBy, sortDirection, sortFunctions]);

  return {
    sortedData,
    sortBy,
    sortDirection,
    handleSortChange
  };
};