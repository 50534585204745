import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import styles from './Virification.module.css';
import { ReactComponent as ReplaceIcon } from './replace-icon.svg';
import { encryptAES, decrypt } from '../srp-lib/srp';
import { useAuth } from '../context/AuthorizationContext';
import { AuthApi, AuthStatus } from '../../services/api/AuthApi';

interface VerificationCodeFormProps {
  tmpToken: string;
  phoneNumber: string;
  K_Key: string;
  backCounter: number
}

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
  
  phoneNumber,
  K_Key,
  backCounter
}) => {
  

  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(backCounter); // Timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {tmpToken, 
          setTmpToken: setTmpTokenContext, 
          setToken: setTokenContext,
          setK_Key: setK_KeyContext
        } = useAuth(); // Access the context
  const navigate = useNavigate(); // Хук для безопасной навигации

   // Convert seconds to mm:ss format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Update the timer
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval); // Cleanup the interval
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setCode(value);
      setHasError(false);
      setErrorMessage('');
    }
  };

  const handleCodeFocus = () => {
    setHasError(false);
    setErrorMessage('');
  };

  const handleResendCode = async () => {
    setIsResendDisabled(true);
    setCode('');
  
    try {
      const result = await AuthApi.resendOtp(tmpToken, K_Key);
      
      if (result.status === AuthStatus.OTP_RESENDED) {
        setTimer(result.timeout);
        setHasError(false);
        setErrorMessage('');
        
        // Обновляем tmp_token в контексте
        if (result.tmp_token) {
          setTmpTokenContext(result.tmp_token);
        }
      } else {
        setErrorMessage('Failed to resend code. Please try again.');
        setHasError(true);
        setIsResendDisabled(false);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : 'Something went wrong');
      setHasError(true);
      setIsResendDisabled(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!/^\d{6}$/.test(code)) {
      setHasError(true);
      setErrorMessage('Invalid code. Must be exactly 6 digits.');
      return;
    }
  
    try {
      const result = await AuthApi.confirmOtp(tmpToken, code, K_Key);
  
      if (result.status === AuthStatus.OTP_APPROVED && result.token) {
        // Успешная авторизация
        setTmpTokenContext('');
        setTokenContext(result.token);
        setK_KeyContext(K_Key);
        localStorage.setItem('authToken', result.token);
        localStorage.setItem('K_Key', K_Key);
        navigate('/panel');
      } else {
        setHasError(true);
        setErrorMessage('Invalid code. Please try again.');
        setCode(''); // Очищаем поле ввода при неверном коде
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error instanceof Error ? error.message : 'Something went wrong');
      // В случае истечения срока действия кода или превышения попыток
      if (error instanceof Error && 
          (error.message.includes('expired') || error.message.includes('too many attempts'))) {
        setIsResendDisabled(false); // Разрешаем повторную отправку
        setTimer(0);
      }
      setCode(''); // Очищаем поле ввода при ошибке
    }
  };
  
  

  return (
    <form className={styles.signInForm} onSubmit={handleSubmit}>
      <h1 className={styles.signInTitle}>Enter SMS code</h1>
      <p className={styles.inputLabel}>
        We’ve sent an SMS with an activation code to your phone <br />
        <strong className={styles.phoneNumber}>+{phoneNumber}</strong>
      </p>

      <div className={styles.formFields}>
        {/* Code input field */}
        <div className={styles.inputContainer}>
          <label htmlFor="smsCode" className={styles.inputLabel}>
            SMS code
          </label>
          <input
            id="smsCode"
            type="text"
            placeholder="000000"
            className={`${styles.inputField} ${hasError ? styles.inputError : ''}`}
            value={code}
            onChange={handleCodeChange}
            onFocus={handleCodeFocus}
            maxLength={6}
          />
          {hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
        </div>

        {/* Timer or Resend button */}
        <p className={styles.timerContainer}>
          {timer > 0 ? (
            <span className={styles.timer}>
              Send again in {formatTime(timer)}
            </span>
          ) : (
            <button
              type="button"
              className={styles.resendButton}
              onClick={handleResendCode}
              disabled={isResendDisabled}
            >
              Resend
              <ReplaceIcon className={styles.replaceIcon} />
            </button>
          )}
        </p>

        {/* Confirm button */}
        <button type="submit" className={styles.signInButton}>
          Confirm
        </button>
      </div>
    </form>
  );
};
