import React, { createContext, useState, useEffect, useContext } from 'react';

export interface Building {
  build_id: string;       // Основной идентификатор здания
  community_id: string;   // ID сообщества
  street: string;         // Улица
  number: string;         // Номер дома
  fullAddress: string;    // Полный адрес для отображения
}

interface BuildingContextProps {
  buildings: Building[];
  selectedBuilding: Building | null;
  setBuildings: (buildings: Building[]) => void;
  setSelectedBuilding: (building: Building | null) => void; // Убедитесь, что этот метод есть
}

const BuildingContext = createContext<BuildingContextProps | undefined>(undefined);

export const BuildingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null);

  // Сохраняем выбранное здание
  useEffect(() => {
    if (selectedBuilding) {
      localStorage.setItem('selectedBuildId', selectedBuilding.build_id);
      localStorage.setItem('selectedCommunityId', selectedBuilding.community_id);
    }
  }, [selectedBuilding]);

  // Загружаем сохранённое здание при получении списка зданий
  useEffect(() => {
    const savedBuildId = localStorage.getItem('selectedBuildId');
    const savedCommunityId = localStorage.getItem('selectedCommunityId');

    if (savedBuildId && savedCommunityId && buildings.length > 0) {
      const savedBuilding = buildings.find(
        building => building.build_id === savedBuildId && 
                   building.community_id === savedCommunityId
      );

      if (savedBuilding) {
        console.log('Found saved building:', savedBuilding);
        setSelectedBuilding(savedBuilding);
      } else {
        console.log('No saved building found, selecting first building');
        setSelectedBuilding(buildings[0]);
      }
    } else if (buildings.length > 0 && !selectedBuilding) {
      // Если нет сохранённого здания, выбираем первое
      console.log('No saved building, selecting first building');
      setSelectedBuilding(buildings[0]);
    }
  }, [buildings, selectedBuilding]);

  return (
    <BuildingContext.Provider 
      value={{ 
        buildings, 
        setBuildings,
        selectedBuilding,
        setSelectedBuilding, // Убедитесь, что метод добавлен в value
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
};

export const useBuildings = () => {
  const context = useContext(BuildingContext);
  if (!context) {
    throw new Error('useBuildings must be used within a BuildingProvider');
  }
  return context;
};

export default BuildingContext;