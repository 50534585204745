import React, { useState } from "react";
import styles from "./modules/BuildingSelect.module.css";
import { useBuildings } from "../context/BuildingContext";
import { Building } from "../context/BuildingContext";

const BuildingSelect: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { buildings, selectedBuilding, setSelectedBuilding } = useBuildings();

  const handleToggleDropdown = () => setIsDropdownOpen(prev => !prev);

  const handleSelectBuilding = (building: Building) => {
    setSelectedBuilding(building);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.container}>
      <button
        className={`${styles.button} ${isDropdownOpen ? styles.open : ""}`}
        onClick={handleToggleDropdown}
      >
        <span>{selectedBuilding?.fullAddress || "Select a building"}</span>
        <img
          src={require("./assets/images/arrow-down-green.png")}
          alt="↓"
          className={styles.arrow}
        />
      </button>
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          {buildings.map((building) => (
            <div
              key={`${building.community_id}_${building.build_id}`}
              className={`${styles.option} ${
                selectedBuilding?.build_id === building.build_id && 
                selectedBuilding?.community_id === building.community_id 
                  ? styles.selected 
                  : ""
              }`}
              onClick={() => handleSelectBuilding(building)}
            >
              {building.fullAddress}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BuildingSelect;