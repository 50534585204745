import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthorizationContext';
import { encryptAES } from '../../srp-lib/srp';
import editIcon from '../svg_images/editIcon.svg';
import styles from './StatusDropdown.module.css';

interface StatusDropdownProps {
  currentStatus: string;
  issueId: string;
  communityId: string;
  onStatusChange: (newStatus: string) => void;
}

type ApiStatus = 'To Do' | 'In Process' | 'Done' | 'Cancelled';
type UiStatus = 'pending' | 'in process' | 'done' | 'archived';

const STATUS_MAP: Record<ApiStatus, UiStatus> = {
  'To Do': 'pending',
  'In Process': 'in process',
  'Done': 'done',
  'Cancelled': 'archived'
};

const DISPLAY_STATUS_MAP: Record<UiStatus, string> = {
  'pending': 'Pending',
  'in process': 'In Process',
  'done': 'Done',
  'archived': 'Archived'
};

const REVERSE_STATUS_MAP: Record<UiStatus, ApiStatus> = {
  'pending': 'To Do',
  'in process': 'In Process',
  'done': 'Done',
  'archived': 'Cancelled'
};

const STATUS_COLORS = {
  'in process': '#F28900',
  'done': '#28A745',
  'archived': '#6C757D',
  'pending': '#FFC107'
};

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  currentStatus,
  issueId,
  communityId,
  onStatusChange
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { token, K_Key } = useAuth();

  const statusOrder: ApiStatus[] = ['In Process', 'Done', 'Cancelled'];
  const currentStatusIndex = statusOrder.indexOf(REVERSE_STATUS_MAP[currentStatus as UiStatus]);

  const updateStatus = async (newStatusKey: ApiStatus) => {
    if (!token || !K_Key) return;

    try {
      const encryptedCommunityId = encryptAES(K_Key, communityId);
      const encryptedIssueId = encryptAES(K_Key, issueId);
      const encryptedNewState = encryptAES(K_Key, newStatusKey);

      const headers = new Headers({
        'token': token,
        'community-id': encryptedCommunityId!,
        'Content-Type': 'application/json'
      });

      const response = await fetch('https://mileva.rs/admin/issue/update', {
        method: 'POST',
        headers,
        body: JSON.stringify({
          issue_id: encryptedIssueId,
          new_state: encryptedNewState
        })
      });

      if (response.ok) {
        onStatusChange(STATUS_MAP[newStatusKey]);
        setIsOpen(false);
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getStatusColor = (status: ApiStatus): string => {
    const uiStatus = STATUS_MAP[status].toLowerCase() as keyof typeof STATUS_COLORS;
    return STATUS_COLORS[uiStatus];
  };

  const getStatusClassName = (status: ApiStatus): string => {
    const uiStatus = STATUS_MAP[status].toLowerCase().replace(' ', '-');
    return `${styles.menuItem} ${styles[uiStatus]} ${
      currentStatusIndex > statusOrder.indexOf(status) ? styles.disabled : ''
    } ${REVERSE_STATUS_MAP[currentStatus as UiStatus] === status ? styles.active : ''}`;
  };
  
  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <button 
        className={styles.dropdownButton} 
        onClick={() => setIsOpen(!isOpen)}
      >
        Status
        <img src={editIcon} alt="Edit" />
      </button>
  
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {statusOrder.map((status, index) => {
            const isDisabled = index < currentStatusIndex;
            const statusColor = getStatusColor(status);
            const uiStatus = STATUS_MAP[status];
            
            return (
              <button
                key={status}
                className={getStatusClassName(status)}
                onClick={() => !isDisabled && updateStatus(status)}
                disabled={isDisabled}
              >
                <span 
                  className={styles.statusIndicator} 
                  style={{ backgroundColor: isDisabled ? '#ADB5BD' : statusColor }}
                />
                {DISPLAY_STATUS_MAP[uiStatus]}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StatusDropdown;