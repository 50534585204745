import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthPage } from './pages/sign_in/AuthPage';
import { VerificationPage } from './pages/confirm_code/VerificationPage';
import { GlobalProvider } from './pages/context/GlobalProvider';
import { useAuth } from './pages/context/AuthorizationContext';
import AdminPanelPage from './pages/panel/AdminPanelPage';
import { Navigate } from 'react-router-dom';

// ProtectedRoute компонент для проверки доступа
const ProtectedRoute = ({ condition, redirectPath, children }) => {
  return condition ? children : <Navigate to={redirectPath} replace />;
};

const AppRoutes = () => {
  const { token, tmpToken } = useAuth(); // Получаем контекст для проверки

  return (
    <Routes>
      {/* Открытый маршрут */} 
      <Route path="/" element={<AuthPage />} />

      {/* Защищённый маршрут: подтверждение кода */}
      <Route
        path="/confirm_code"
        element={
          <ProtectedRoute
            condition={!!tmpToken} // Проверка tmpToken
            redirectPath="/"
          >
            <VerificationPage />
          </ProtectedRoute>
        }
      />

      {/* Защищённый маршрут: панель администратора */}
      <Route
        path="/panel"
        element={
          <ProtectedRoute
            condition={!!token } // Проверка token и K_Key
            redirectPath="/"
          >
            <AdminPanelPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

function App() {
  return (
    <GlobalProvider>
      <Router >
        <div className="App">
          <AppRoutes />
        </div>
      </Router>
    </GlobalProvider>
  );
}

export default App;
