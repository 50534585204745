import React, { useState, useEffect } from 'react';
import styles from './styles/PollDetails.module.css';
import backIcon from '../svg_images/backIcon.svg';
import { Poll } from './PollsList';
import VotingResultsCircle from './VotingResultsCircle';
import downArrowEdit from "../svg_images/downArrowEdit.svg";
import upArrowEdit from "../svg_images/upArrowEdit.svg";

interface PollDetailsProps {
  onBack: () => void;
  poll: Poll & {
    description?: string;
    questions: Array<{
      title: string;
      description?: string;
    }>;
    votingResults?: {
      votesYes: number;
      votesNo: number;
      votesSkip: number;
    };
    whoVoted?: { apt: number, tenant: string }[];
  };
  onStopVoting: () => void;
  onClosePoll: () => void;
}

const PollDetails: React.FC<PollDetailsProps> = ({ onBack, poll, onStopVoting, onClosePoll }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownContainer = document.querySelector(`.${styles.dropdownContainer}`);
      if (dropdownContainer && !dropdownContainer.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isDropdownOpen]);

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleAddOfflineVotes = () => {
  
    setIsDropdownOpen(false);
  };

  const handleStopVoting = () => {
    onStopVoting();
    setIsDropdownOpen(false);
  };

  const handleClosePoll = () => {
    onClosePoll();
    setIsDropdownOpen(false);
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-US', { 
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString('en-US', { 
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatCreatedAt = (date: Date): string => {
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) + ' at ' + date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };



  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={onBack}>
        <img src={backIcon} alt="Back" />
        Back
      </button>

      <div className={styles.header}>
        <div className={styles.createdInfo}>
          Created on {formatCreatedAt(poll.creationDate)}
        </div>
        
        <div className={styles.statusSection}>
          <div className={`${styles.statusBadge} ${styles[poll.status.toLowerCase()]}`}>
            The poll is {poll.status.toLowerCase()}
          </div>
          
          <div className={styles.actions}>
            <div className={styles.dropdownContainer}>
              <button 
                className={styles.editBtn} 
                onClick={toggleDropdown}
              >
                Edit 
                <img 
                  src={isDropdownOpen ? upArrowEdit : downArrowEdit}
                  alt="arrow"
                  className={styles.arrowIcon}
                />
              </button>
              {isDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  <button 
                    className={styles.dropdownItem}
                    onClick={handleAddOfflineVotes}
                  >
                    Add offline votes
                  </button>
                  <div className={styles.dropdownDivider} />
                  <button 
                    className={styles.dropdownItem}
                    onClick={handleStopVoting}
                  >
                    Stop online voting
                  </button>
                  <div className={styles.dropdownDivider} />
                  <button 
                    className={styles.dropdownItem}
                    onClick={handleClosePoll}
                  >
                    Close the poll
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <h1 className={styles.title}>{poll.title}</h1>

      <div className={styles.onlineVoting}>Online voting ends</div>
      <div className={styles.votingPeriod}>
        
        <div className={styles.dateTime}>
          <span className={styles.date}>{formatDate(poll.votingEndsAt)}</span>
          <span className={styles.time}>{formatTime(poll.votingEndsAt)}</span>
        </div>
      </div>

      {poll.description && (
        <div className={styles.description}>
          {poll.description}
        </div>
      )}

      <div className={styles.pollSection}>
        <h3 className={styles.questionLabel}>The question</h3>
        <p className={styles.question}>
          {poll.questions[0]?.title}
        </p>
        {poll.questions[0]?.description && (
          <p className={styles.questionDescription}>
            {poll.questions[0].description}
          </p>
        )}

        <div className={styles.resultsContainer}>
          {poll.votingResults ? (
            <VotingResultsCircle results={poll.votingResults} />
          ) : (
            <div className={styles.resultsCircle}>
              <div className={styles.respondsCount}>
                {poll.respondsCount} responds
              </div>
            </div>
          )}
        </div>

        {poll.whoVoted && poll.whoVoted.length > 0 && (
          <div className={styles.whoVotedContainer}>
            <div className={styles.whoVotedTitleContainer}>Who Voted</div>
            <div className={styles.whoVotedColumnHeader}>
                <div className={styles.whoVotedAptHeader}>Apt.</div>
                <div className={styles.whoVotedTenantHeader}>Tenant</div>
            </div>

            {poll.whoVoted.map((vote, index) => (
              <div key={index} className={styles.whoVotedItemContainer}>
                <div className={styles.whoVotedApt}>{vote.apt}</div>
                <div className={styles.whoVotedTenant}>{vote.tenant}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      
    </div>
  );
};

export default PollDetails;