// src/components/modals/ForgotPasswordModal.tsx
import React, { useState, useEffect } from 'react';
import { AuthApi } from '../../services/api/AuthApi';
import { PhoneFormatter } from './services/phoneFormatter';
import { PhoneInputHandler } from './services/phoneInputHandlers';
import styles from './ForgotPasswordModal.module.css';

interface ForgotPasswordModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  
  export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ isOpen, onClose }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneFocused, setIsPhoneFocused] = useState(false);
    const [serverResponse, setServerResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
  
    useEffect(() => {
      let timeoutId: NodeJS.Timeout;
      
      if (serverResponse) {
        timeoutId = setTimeout(() => {
          onClose();
          setPhoneNumber('');
          setServerResponse('');
          setError('');
        }, 2000);
      }
  
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [serverResponse, onClose]);
  
    if (!isOpen) return null;
  
    const validatePhone = (phoneNumber: string): boolean => {
      const digits = phoneNumber.replace(/\D/g, "");
      return digits.length >= 11 && digits.length <= 12;
    };
  
    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const formattedValue = PhoneFormatter.format(e.target.value, phoneNumber);
      setPhoneNumber(formattedValue);
    };
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      
      if (!validatePhone(phoneNumber)) {
        setError('Please enter a valid phone number');
        return;
      }
    
      setError('');
      setServerResponse('');
      setIsLoading(true);
    
      try {
        const username = phoneNumber.replace(/\D/g, "");
        await AuthApi.recoverPassword(username);
        setServerResponse('Password recovery request has been sent to administrator');
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Failed to send recovery request');
      } finally {
        setIsLoading(false);
      }
    };
  
    const handlePhoneFocus = () => {
      setIsPhoneFocused(true);
      if (!phoneNumber.startsWith('+')) {
        setPhoneNumber('+');
      }
      setError(''); // Очищаем ошибку при фокусе
    };
  
    const handlePhoneBlur = () => {
      setIsPhoneFocused(false);
      if (phoneNumber === '+') {
        setPhoneNumber('');
      }
    };
  
    const handleOverlayClick = (e: React.MouseEvent) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };
  
    return (
      <div className={styles.modalOverlay} onClick={handleOverlayClick}>
        <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
          <button className={styles.closeButton} onClick={onClose}>×</button>
          
          <h2 className={styles.modalTitle}>Forgot Password</h2>
          
          <form onSubmit={handleSubmit} noValidate>
            <div className={styles.inputContainer}>
              <label htmlFor="recovery-phone" className={styles.inputLabel}>
                Phone number
              </label>
              <input
                id="recovery-phone"
                type="tel"
                placeholder={isPhoneFocused ? '' : '+XXX-XXX-XXX-XXX'}
                className={`${styles.inputField} ${error ? styles.inputError : ''}`}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onFocus={handlePhoneFocus}
                onBlur={handlePhoneBlur}
                onKeyDown={(e) => PhoneInputHandler.handlePhoneKeyDown(e, phoneNumber, setPhoneNumber)}
                maxLength={16}
                autoComplete="tel"
              />
            </div>
  
            {error && <p className={styles.errorMessage}>{error}</p>}
            {serverResponse && <p className={styles.successMessage}>{serverResponse}</p>}
  
            <button 
              type="submit" 
              className={styles.sendButton}
              disabled={isLoading || !phoneNumber || phoneNumber === '+'}
            >
              {isLoading ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      </div>
    );
  };