// src/utils/phoneFormatter.ts
export class PhoneFormatter {
    static format(value: string, currentNumber: string): string {
      const digits = value.replace(/\D/g, "");
    
      if (digits.length > 12) {
        return currentNumber;
      }
    
      const formattedPhone = digits.replace(
        /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})$/,
        (_, p1, p2, p3, p4) =>
          [p1 && `+${p1}`, p2, p3, p4].filter(Boolean).join("-")
      );
    
      return formattedPhone.startsWith("+") ? formattedPhone : `+${formattedPhone}`;
    }
  
    static validate(phoneNumber: string): boolean {
      const phoneRegex = /^\+\d{3}-\d{3}-\d{3}-\d{3}$/;
      const digitCount = phoneNumber.replace(/\D/g, '');
      return digitCount.length >= 11 && phoneRegex.test(phoneNumber);
    }
  }