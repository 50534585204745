import React, { useState, useRef, useEffect } from "react";
import styles from "./TimePicker.module.css";

interface TimePickerProps {
  onClose: () => void;
  onTimeSelect: (time: string) => void;
  /**
   * При `true` – нижняя граница выбора блокируется: 
   * минимальное время = (текущее время, округлённое вверх до 15 минут) + 15 минут.
   * При `false` или если не передано – нижняя граница не блокируется, можно выбирать любое время с 00:00.
   */
  blockEarlyTime?: boolean;
  initialTime?: string;
}

const MINUTE_VALUES = [0, 15, 30, 45];
const MAX_HOUR = 23;
const MAX_MINUTE = 45;
const MAX_TOTAL = MAX_HOUR * 60 + MAX_MINUTE; // 23:45 -> 1380 + 45 = 1425

/**
 * Вычисляет "нижнюю границу" выбора (current + 15 мин, с округлением до 15 мин).
 * Если `blockEarlyTime=false`, возвращаем 0 (полночь), 
 * что означает «никакой блокировки снизу нет».
 */
function getEarliestTotalNow(block: boolean): number {
  if (!block) {
    // если не надо блокировать – возвращаем 0 (00:00)
    return 0;
  }
  // иначе считаем «текущее время + 15 минут (округление)»
  const now = new Date();
  let totalMins = now.getHours() * 60 + now.getMinutes();
  // Округляем вверх до ближайшего шага 15 минут
  totalMins = Math.ceil(totalMins / 15) * 15;
  // Добавляем ещё 15 минут "запаса"
  totalMins += 15;
  // Если «вылезли» за 23:45, ограничиваем
  if (totalMins > MAX_TOTAL) {
    totalMins = MAX_TOTAL;
  }
  return totalMins;
}

const TimePicker: React.FC<TimePickerProps> = ({
  onClose,
  onTimeSelect,
  blockEarlyTime = false, // по умолчанию не блокируем
  initialTime
}) => {
  // Часы и минуты, выбранные пользователем
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);

  // Инициализация — ставим "курсор" на нижнюю границу (либо текущее +15м), если blockEarlyTime=true,
  // или на текущее время, если blockEarlyTime=false. (Можно изменить логику, если нужно.)
  useEffect(() => {
    const earliestTotal = getEarliestTotalNow(blockEarlyTime);
    
    if (initialTime) {
      // Парсим initialTime (формат "HH:mm")
      const [initHour, initMinute] = initialTime.split(':').map(Number);
      const initialTotal = initHour * 60 + initMinute;
      
      if (!blockEarlyTime || initialTotal >= earliestTotal) {
        // Если нет блокировки или initialTime не нарушает ограничения - используем его
        setHour(initHour);
        setMinute(initMinute);
      } else {
        // Если initialTime нарушает ограничения - используем earliestTotal
        setHour(Math.floor(earliestTotal / 60));
        setMinute(earliestTotal % 60);
      }
    } else {
      if (!blockEarlyTime) {
        // Если нет начального времени и нет блокировки - ставим 12:00
        setHour(12);
        setMinute(0);
      } else {
        // Если нет начального времени, но есть блокировка - используем earliestTotal
        setHour(Math.floor(earliestTotal / 60));
        setMinute(earliestTotal % 60);
      }
    }
  }, [blockEarlyTime, initialTime]);

  /**
   * Обработчик прокрутки колесом (up/down) для часов/минут.
   */
  const handleScroll = (
    direction: "up" | "down",
    type: "hour" | "minute"
  ) => {
    // Считаем нижнюю границу
    const earliestTotal = getEarliestTotalNow(blockEarlyTime);
  
    const earliestHour = Math.floor(earliestTotal / 60);
    const earliestMinute = earliestTotal % 60;
    
    if (type === "hour") {
      setHour((prevHour) => {
        // Сначала определяем, можем ли мы вообще менять текущий час
        const currentTotal = prevHour * 60 + minute;
        
        if (direction === "up") {
          // При движении вверх просто проверяем верхнюю границу
          if (prevHour < MAX_HOUR) {
            const nextHour = prevHour + 1;
            const nextTotal = nextHour * 60 + minute;
            
            if (nextTotal <= MAX_TOTAL) {
              return nextHour;
            }
          }
          return prevHour;
        } else {
          // При движении вниз
          if (prevHour > 0) {
            const nextHour = prevHour - 1;
            const nextTotal = nextHour * 60 + minute;
            
            if (!blockEarlyTime || nextTotal >= earliestTotal) {
              // Если нет ограничения или новое значение не нарушает границу
              return nextHour;
            } else {
              // Если есть ограничение и новое значение ниже границы
              if (minute > earliestMinute) {
                // Если текущие минуты больше минимальных, нужен следующий час
                return earliestHour + 1;
              }
              return earliestHour;
            }
          }
          return prevHour;
        }
      });
    } else {
      // Прокрутка минут
      setMinute((prevMinute) => {
        const currentIndex = MINUTE_VALUES.indexOf(prevMinute);
        let newIndex = currentIndex;
        let candidateHour = hour;
  
        if (direction === "up") {
          newIndex = currentIndex + 1;
          if (newIndex >= MINUTE_VALUES.length) {
            // Переход на следующий час
            if (hour < MAX_HOUR) {
              candidateHour = hour + 1;
              newIndex = 0;
            } else {
              // если уже 23:45 – блокируем
              if (hour === MAX_HOUR && prevMinute === MAX_MINUTE) {
                return prevMinute;
              }
              newIndex = MINUTE_VALUES.indexOf(MAX_MINUTE);
            }
          }
        } else {
          // direction === 'down'
          newIndex = currentIndex - 1;
          if (newIndex < 0) {
            // Переход на предыдущий час
            if (hour > 0) {
              candidateHour = hour - 1;
              newIndex = MINUTE_VALUES.length - 1;
            } else {
              // Уже 0 часов – блокируем
              return prevMinute;
            }
          }
        }
  
        const candidateMinute = MINUTE_VALUES[newIndex];
        const candidateTotal = candidateHour * 60 + candidateMinute;
  
        // Проверка нижней границы
        if (candidateTotal < earliestTotal && blockEarlyTime) {
          // Если надо блокировать - возвращаем минимально допустимые минуты
          const earliestHour = Math.floor(earliestTotal / 60);
          const earliestMinute = earliestTotal % 60;
          // Находим ближайшее значение минут из доступных (округляем вверх до ближайших 15 минут)
          const roundedEarliestMinute = Math.ceil(earliestMinute / 15) * 15;
          if (candidateHour === earliestHour) {
            return roundedEarliestMinute;
          }
          return prevMinute;
        }
  
        // Проверка верхней границы (23:45)
        if (candidateTotal > MAX_TOTAL) {
          return prevMinute;
        }
  
        // Если переход на другой час - обновляем hour
        if (candidateHour !== hour) {
          setHour(candidateHour);
        }
  
        return candidateMinute;
      });
    }
  };

  /**
   * Подтверждение выбора
   */
  const handleConfirm = () => {
    const finalHour = String(hour).padStart(2, "0");
    const finalMinute = String(minute).padStart(2, "0");
    onTimeSelect(`${finalHour}:${finalMinute}`);
    onClose();
  };

  /**
   * Соседние значения (для отображения "вверху/внизу")
   * Если выходит за допустимые границы – возвращаем "--".
   */
  const getPrevHour = () => {
    const earliestTotal = getEarliestTotalNow(blockEarlyTime);
    const candidate = hour - 1;
    if (candidate < 0) return "--";

    const candidateTotal = candidate * 60 + minute;
    // Если блокируем и результат «раньше» нижней границы
    if (blockEarlyTime && candidateTotal < earliestTotal) return "--";
    // Если «выше» верхней границы
    if (candidateTotal > MAX_TOTAL) return "--";

    return String(candidate).padStart(2, "0");
  };

  const getNextHour = () => {
    // Если уже 23:45 – дальше нельзя
    if (hour === MAX_HOUR && minute === MAX_MINUTE) {
      return "--";
    }
    const candidate = hour + 1;
    if (candidate > MAX_HOUR) return "--";

    const candidateTotal = candidate * 60 + minute;
    if (candidateTotal > MAX_TOTAL) return "--";
    return String(candidate).padStart(2, "0");
  };

  const getPrevMinute = () => {
    const earliestTotal = getEarliestTotalNow(blockEarlyTime);
    const index = MINUTE_VALUES.indexOf(minute);
    let candidateIndex = index - 1;

    if (candidateIndex < 0) {
      // «уходим» на час назад
      const candidateHour = hour - 1;
      if (candidateHour < 0) return "--";
      const candidateTotal = candidateHour * 60 + 45;
      if (blockEarlyTime && candidateTotal < earliestTotal) return "--";
      if (candidateTotal > MAX_TOTAL) return "--";
      return "45";
    }

    const candidateMinute = MINUTE_VALUES[candidateIndex];
    const candidateTotal = hour * 60 + candidateMinute;
    if (blockEarlyTime && candidateTotal < earliestTotal) return "--";
    if (candidateTotal > MAX_TOTAL) return "--";
    return String(candidateMinute).padStart(2, "0");
  };

  const getNextMinute = () => {
    // Если мы уже на 23:45
    if (hour === MAX_HOUR && minute === MAX_MINUTE) {
      return "--";
    }

    const index = MINUTE_VALUES.indexOf(minute);
    let candidateIndex = index + 1;
    if (candidateIndex >= MINUTE_VALUES.length) {
      // Переход на (hour + 1):00
      const candidateHour = hour + 1;
      if (candidateHour > MAX_HOUR) return "--";

      const candidateTotal = candidateHour * 60; // :00
      if (candidateTotal > MAX_TOTAL) return "--";
      return "00";
    }

    const candidateMinute = MINUTE_VALUES[candidateIndex];
    const candidateTotal = hour * 60 + candidateMinute;
    if (candidateTotal > MAX_TOTAL) return "--";
    return String(candidateMinute).padStart(2, "0");
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

 
  const handleOverlayClick = (e: React.MouseEvent) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };

  const modalContentRef = useRef<HTMLDivElement>(null);
  const [isLeftSide, setIsLeftSide] = useState(true);
  const autoRepeatRef = useRef<NodeJS.Timeout | null>(null);
  // Обработчик движения мыши для определения стороны
  const handleMouseMove = (e: React.MouseEvent) => {
    if (modalContentRef.current) {
      const rect = modalContentRef.current.getBoundingClientRect();
      const modalCenterX = rect.left + rect.width / 2;
      setIsLeftSide(e.clientX < modalCenterX);
    }
  };

  // Обработчик скролла для всего модального окна
  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    // Используем stopPropagation вместо preventDefault
    e.stopPropagation();
    
    const direction = e.deltaY < 0 ? "up" : "down";
    handleScroll(direction, isLeftSide ? "hour" : "minute");
  };


  // Очистка таймера при размонтировании
  useEffect(() => {
    return () => {
      if (autoRepeatRef.current) {
        clearInterval(autoRepeatRef.current);
      }
    };
  }, []);

  // Функция для создания автоповтора
  const startAutoRepeat = (direction: "up" | "down", type: "hour" | "minute") => {
    // Первая задержка дольше, чтобы пользователь мог сделать одиночный клик
    const initialDelay = 500;
    const repeatInterval = 100; // Интервал повторения

    // Очищаем предыдущий интервал если есть
    if (autoRepeatRef.current) {
      clearInterval(autoRepeatRef.current);
    }

    // Делаем первое изменение сразу
    handleScroll(direction, type);

    // Устанавливаем таймер на первое повторение
    const timeoutId = setTimeout(() => {
      // После первой задержки запускаем регулярные повторения
      const intervalId = setInterval(() => {
        handleScroll(direction, type);
      }, repeatInterval);

      autoRepeatRef.current = intervalId;
    }, initialDelay);

    autoRepeatRef.current = timeoutId;
  };

  // Функция остановки автоповтора
  const stopAutoRepeat = () => {
    if (autoRepeatRef.current) {
      clearInterval(autoRepeatRef.current);
      autoRepeatRef.current = null;
    }
  };

  // Обработчик начала клика по квадранту
  const handleQuadrantMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalContentRef.current) {
      const rect = modalContentRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      
      const isLeft = e.clientX < centerX;
      const isTop = e.clientY < centerY;
      
      const direction = isTop ? "up" : "down";
      const type = isLeft ? "hour" : "minute";
      
      startAutoRepeat(direction, type);
    }
  };
  
  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div 
        ref={modalContentRef}
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
        onMouseMove={handleMouseMove}
        // Используем onWheelCapture с опцией passive: false
        onWheelCapture={handleWheel}
      >
        {/* Добавляем квадранты */}
        <div 
          className={styles.quadrants}
          onMouseUp={stopAutoRepeat}
          onMouseLeave={stopAutoRepeat}
        >
          <div 
            className={styles.quadrant}
            onMouseDown={handleQuadrantMouseDown}
          ></div>
          <div 
            className={styles.quadrant}
            onMouseDown={handleQuadrantMouseDown}
          ></div>
          <div 
            className={styles.quadrant}
            onMouseDown={handleQuadrantMouseDown}
          ></div>
          <div 
            className={styles.quadrant}
            onMouseDown={handleQuadrantMouseDown}
          ></div>
        </div>
       <div className={styles.timePicker}>
          {/* Часы */}
          <div
            className={styles.scrollContainer}
            // onWheel={(e) =>
            //   handleScroll(e.deltaY < 0 ? "up" : "down", "hour")
            // }
          >
            <div className={styles.timeItem}>{getPrevHour()}</div>
            <div className={`${styles.timeItem} ${styles.active}`}>
              {String(hour).padStart(2, "0")}
            </div>
            <div className={styles.timeItem}>{getNextHour()}</div>
          </div>

          <span className={styles.colon}>:</span>

          {/* Минуты */}
          <div
            className={styles.scrollContainer}
            // onWheel={(e) =>
            //   handleScroll(e.deltaY < 0 ? "up" : "down", "minute")
            // }
          >
            <div className={styles.timeItem}>{getPrevMinute()}</div>
            <div className={`${styles.timeItem} ${styles.active}`}>
              {String(minute).padStart(2, "0")}
            </div>
            <div className={styles.timeItem}>{getNextMinute()}</div>
          </div>
        </div>


        <div className={styles.buttonWrapper}>
          <button className={styles.confirmButton} onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
