//NewAlert.tsx
import React, { createContext, useRef,  useState, useEffect, useMemo } from "react";
import styles from "./modules/NewAlert.module.css";
import stylesNewForm from '../../../styles/NewFormModal.module.css'

import UniversalEditor from "./components/universal_editor/UniversalEditor";
import { useBuildings } from '../../context/BuildingContext'; 

import RecipientPicker from '../../../modules/recipient_picker/RecipientPicker';

import { ReactComponent as PlusIcon } from '../../../images/svg/pluse_1a.svg';
import { ReactComponent as CrossIcon } from '../../../images/svg/cross_1a.svg';

import { submitAlert } from './alertUtils';

import { DESCRIPTION, TITLE } from './alertEditorConfig';
import { useModalLock } from "../../../services/hooks/useModalLock";
import DateTimePickerContainer from './DateTimePickerContainer';

export const DateContext = createContext({
  startDate: "",
  endDate: "",
  setStartDate: (date: string) => {},
  setEndDate: (date: string) => {},
});

interface Recipient {
  address: string;
  buildId: string;
  communityId: string;
}

interface NewCustomMessageProps {
  onClose: () => void;
  onAlertCreated: () => Promise<void>;
}

const NewCustomMessage: React.FC<NewCustomMessageProps> = ({ 
  onClose, 
  onAlertCreated 
 }) => {
  useModalLock(true);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);


  const [title, setTitle] = useState(""); 
  const [activeButtons, setActiveButtons] = useState<string[]>([]);

 
  const [timePickerType, setTimePickerType] = useState<"start" | "end">("start");

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [calendarType, setCalendarType] = useState<"start" | "end">("start");

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);
  const [recipients, setRecipients] = useState<Recipient[]>([]);

  const { selectedBuilding } = useBuildings();
  const [currentPicker, setCurrentPicker] = useState<'date' | 'time' | null>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  useEffect(() => {
    
  
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isCalendarOpen) {
          setIsCalendarOpen(false);
        } else if (isTimePickerOpen) {
          setIsTimePickerOpen(false);
        } else if (isRecipientModalOpen) {
          setIsRecipientModalOpen(false);
        } else {
          onClose();
        }
      }
    };
  
    
    document.addEventListener('keydown', handleKeyDown);    
    return () => {     
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCalendarOpen, isTimePickerOpen, isRecipientModalOpen, onClose]);

  useEffect(() => {
    return () => {
      // Убеждаемся, что прокрутка восстановлена при размонтировании
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, []);
  
  useEffect(() => {
    if (selectedBuilding) {
      setRecipients([{
        address: selectedBuilding.fullAddress,
        buildId: selectedBuilding.build_id,
        communityId: selectedBuilding.community_id
      }]);

      // Убираем ошибку recipients, если она была
      setErrors(prev => ({ ...prev, recipients: "" }));
    }
  }, []);
  
  // Логика преобразования активных кнопок для SendButton
  const sendButtonValues = useMemo(() => {
    return activeButtons.map((button) => {
      if (button === "electricity outage") return "electricity";
      if (button === "water outage") return "water";
      if (button === "other works") return "other";
      return button; // Для любых других кнопок
    });
  }, [activeButtons]);

  const [description, setDescription] = useState("");
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [titleLength, setTitleLength] = useState(0);

  const [isFirstClick, setIsFirstClick] = useState(true); // Отслеживает первый клик в поле Start date
  const [wasChainStarted, setWasChainStarted] = useState(false); // Флаг для отслеживания, была ли запущена цепочка

  const formatDate = (date: string) => {
    if (!date) return "";
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };

  const handleDescriptionChange = (text: string, length: number) => {
    setDescription(text);
    setDescriptionLength(length);

    if (errors.description && text.trim().length > 0) {
      setErrors((prev) => ({ ...prev, description: "" }));
    }
  };

  const handleTypeButtonClick = (buttonName: string) => {
    setActiveButtons((prevState) =>
      prevState.includes(buttonName)
        ? prevState.filter((name) => name !== buttonName)
        : [...prevState, buttonName]
    );

    if (errors.type && activeButtons.length === 0) {
      setErrors((prev) => ({ ...prev, type: "" }));
    }    
  };

  const handleTitleChange = (text: string, length: number) => {
    setTitle(text);
    setTitleLength(length);

    if (errors.title && text.trim().length > 0) {
      setErrors((prev) => ({ ...prev, title: "" }));
    }
  };

  const handleCloseTimePicker = () => {
    setIsTimePickerOpen(false);
  };


 // Состояния для управления цепочкой выбора
const [isChainActive, setIsChainActive] = useState(false);
const [wasAnyInteraction, setWasAnyInteraction] = useState(false);

const handleOpenCalendar = (type: "start" | "end") => {
  // Отмечаем, что было взаимодействие
  if (!wasAnyInteraction) {
    setWasAnyInteraction(true);
  }

  // Запускаем цепочку только если это первый клик и это выбор даты начала
  if (type === "start" && !wasAnyInteraction) {
    setIsChainActive(true);
  } else {
    setIsChainActive(false);
  }
  
  setCalendarType(type);
  setCurrentPicker('date');
  setIsPickerOpen(true);
};

const handleOpenTimePicker = (type: "start" | "end") => {
  // Отмечаем, что было взаимодействие
  if (!wasAnyInteraction) {
    setWasAnyInteraction(true);
    setIsChainActive(false); // Выключаем цепочку, если открываем время напрямую
  }
  
  setTimePickerType(type);
  setCalendarType(type);
  setCurrentPicker('time');
  setIsPickerOpen(true);
};

const handleDateSelect = (date: string) => {
  if (calendarType === "start") {
    setStartDate(date);
    if (errors.startDateTime && date) {
      setErrors((prev) => ({ ...prev, startDateTime: "" }));
    }
    
    if (isChainActive) {
      // Продолжаем цепочку только если она активна
      setTimePickerType("start");
      setCurrentPicker('time');
    } else {
      setIsPickerOpen(false);
    }
  } else {
    setEndDate(date);
    if (errors.endDateTime && date) {
      setErrors((prev) => ({ ...prev, endDateTime: "" }));
    }
    
    if (isChainActive) {
      // Если цепочка активна, продолжаем к выбору времени окончания
      setTimePickerType("end");
      setCurrentPicker('time');
    } else {
      setIsPickerOpen(false);
    }
  }
};

const handleTimeSelect = (time: string) => {
  if (timePickerType === "start") {
    setStartTime(time);
    if (errors.startDateTime && time) {
      setErrors((prev) => ({ ...prev, startDateTime: "" }));
    }
    
    if (isChainActive) {
      // Продолжаем цепочку к выбору даты окончания
      setTimeout(() => {
        setCalendarType('end');
        setCurrentPicker('date');
      }, 0);
    } else {
      setIsPickerOpen(false);
    }
  } else {
    setEndTime(time);
    if (errors.endDateTime && time) {
      setErrors((prev) => ({ ...prev, endDateTime: "" }));
    }
    // Цепочка завершена, отключаем её и закрываем пикер
    setIsChainActive(false);
    setIsPickerOpen(false);
  }
};
  

  const handleOpenRecipientModal = () => {
    setIsRecipientModalOpen(true);
  };

  const handleAddRecipients = (newRecipients: { address: string; buildId: string; communityId: string }[]) => {
    setRecipients((prev) => [...prev, ...newRecipients]);
    
    if (errors.recipients && newRecipients.length > 0) {
      setErrors((prev) => ({ ...prev, recipients: "" }));
    }
  };

  const handleRemoveRecipient = (buildIdToRemove: string) => {
    setRecipients((prevRecipients) => {
      const updatedRecipients = prevRecipients.filter(
        (recipient) => recipient.buildId !== buildIdToRemove
      );
  
      // Добавляем ошибку, если список стал пустым
      if (updatedRecipients.length === 0) {
        setErrors((prev) => ({ ...prev, recipients: "Add at least one recipient" }));
      }
  
      return updatedRecipients;
    });
  };

  const [errors, setErrors] = useState({
    type: "",
    title: "",
    description: "",
    startDateTime: "",
    endDateTime: "",
    recipients: "", // Новое поле для ошибки Recipients
  });

  const validateForm = () => {
    const currentDateTime = new Date();
    const startDateTime = startDate && startTime 
        ? new Date(`${startDate} ${startTime}`) 
        : null;
    const endDateTime = endDate && endTime 
        ? new Date(`${endDate} ${endTime}`) 
        : null;

    const validationErrors = {
      type: activeButtons.length === 0 ? "Choose type of the alert" : "",
      title: title.trim().length === 0 ? "Enter title" : "",
      description: description.trim().length === 0 ? "Enter description" : "",
      startDateTime: !startDate || !startTime 
        ? "Set starting date and time" 
        : startDateTime && startDateTime <= currentDateTime 
          ? "Start date must be in the future" 
          : "",
      endDateTime: !endDate || !endTime 
        ? "Set ending date and time" 
        : !startDateTime 
          ? "Set starting date and time first" 
          : endDateTime && startDateTime && endDateTime <= startDateTime 
            ? "End date must be after start date" 
            : "",
      recipients: recipients.length === 0 ? "Add at least one recipient" : "",
    };
  
    setErrors(validationErrors);
  
    // Log errors for debugging
    console.log("Validation errors:", validationErrors);
  
    // Return true if no errors
    return Object.values(validationErrors).every((error) => error === "");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
  
    try {
      await submitAlert({
        title,
        description,
        recipients,
        startDate,
        endDate,
        startTime,
        endTime,
        activeButtons: sendButtonValues,
      }, onAlertCreated);
      onClose();
    } catch (error) {
      console.error('Failed to submit alert:', error);
    }
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsChainActive(false);
      setWasAnyInteraction(false);
      onClose();
    }
  };
 
 

  return (
   
      <div className={stylesNewForm.modalOverlay} onClick={handleOverlayClick}>
        <div 
          className={stylesNewForm.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
                
        <div className={stylesNewForm.modalTitle}>New Alert</div>           

          <div className={styles.section}>
            <div className={styles.textAreaTitle}>
              Type {errors.type && <span className={styles.errorMessage}>{errors.type}</span>}
            </div>
            <div className={styles.typeBlock}>
              <button
                className={`${styles.typeButton} ${
                  activeButtons.includes("electricity outage") ? styles.active : ""
                }`}
                onClick={() => handleTypeButtonClick("electricity outage")}
              >
                <div className={`${styles.typeImage} ${styles.imageElectricity}`} />
               Electricity outage
              </button>
              <button
                className={`${styles.typeButton} ${
                  activeButtons.includes("water outage") ? styles.active : ""
                }`}
                onClick={() => handleTypeButtonClick("water outage")}
              >
                <div className={`${styles.typeImage} ${styles.imageWater}`} />
                Water outage
              </button>
              <button
                className={`${styles.typeButton} ${
                  activeButtons.includes("other works") ? styles.active : ""
                }`}
                onClick={() => handleTypeButtonClick("other works")}
              >
                <div className={`${styles.typeImage} ${styles.imageOther}`} />
                Other technical works
              </button>
            </div>
            
          </div>           

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Information</h3>
            <div className={styles.formGroup}>             
              <p className={styles.textAreaTitle} style={{ color: title.length === 90 || errors.title ? "red" : "#6c757d" }}>
                Title ({title.length}/90 symb.) {errors.title && <span className={styles.errorMessage}>{errors.title}</span>}
              </p> 
              <UniversalEditor
                parameters={TITLE.createParameters({
                  activeButtons,
                  startDate,
                  startTime,
                  endDate,
                  endTime,
                  formatDate
                })}
                initialTemplate={TITLE.template}
                onDescriptionChange={handleTitleChange}
                maxLength={90}
                readOnly={true}
                placeholder={TITLE.placeholder}
                className={`${styles.textArea} ${styles.textAreaSingleRow}`}
              />
            </div>

            <div className={styles.formGroup}>           
              <p className={styles.textAreaTitle} style={{ color: description.length === 1000 || errors.description ? "red" : "#6c757d" }}>
                Description ({description.length}/1000 symb.) {errors.description && <span className={styles.errorMessage}>{errors.description}</span>}
              </p>
              <UniversalEditor
                parameters={DESCRIPTION.createParameters({
                  activeButtons,
                  startDate,
                  startTime,
                  endDate,
                  endTime,
                  formatDate
                })}
                initialTemplate={DESCRIPTION.template}
                onDescriptionChange={handleDescriptionChange}
                maxLength={1000}
                placeholder={DESCRIPTION.placeholder}
                className={`${styles.textArea} ${styles.textAreaMultiRow}`}
              />
            </div>
          </div>

         
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Date</h3>
            <div className={styles.dateTimeBlock}>
              <div className={styles.dateTimeSmallBlock}>
                <p className={styles.textAreaTitle}>
                  Starts {errors.startDateTime && <span className={styles.errorMessage}>{errors.startDateTime}</span>}
                </p>
                <div className={styles.dateTimeGroup}>
                  <button 
                    type="button" 
                    className={`${styles.baseDateTimeButton} ${styles.dateButton} ${!startDate ? styles.dateTimePlaceholder : ''}`}
                    onClick={() => handleOpenCalendar("start")}
                  >
                    {startDate ? formatDate(startDate) : 'Choose date'}
                  </button>
                  <button 
                    type="button"                
                    className={`${styles.baseDateTimeButton} ${styles.timeButton} ${!startTime ? styles.dateTimePlaceholder : ''}`}
                    onClick={() => handleOpenTimePicker("start")}               
                  >
                    {startTime || '13:00'}
                  </button>
                </div>
              </div>
              
              <div className={styles.dateTimeSmallBlock}>
                <p className={styles.textAreaTitle}>
                  Ends {errors.endDateTime && <span className={styles.errorMessage}>{errors.endDateTime}</span>}
                </p>
                <div className={styles.dateTimeGroup}>
                  <button 
                    type="button" 
                    className={`${styles.baseDateTimeButton} ${styles.dateButton} ${!endDate ? styles.dateTimePlaceholder : ''}`}
                    onClick={() => handleOpenCalendar("end")}
                  >
                    {endDate ? formatDate(endDate) : 'Choose date'}
                  </button>
                  <button 
                    type="button"                
                    className={`${styles.baseDateTimeButton} ${styles.timeButton} ${!endTime ? styles.dateTimePlaceholder : ''}`}
                    onClick={() => handleOpenTimePicker("end")}               
                  >
                    {endTime || '13:00'}
                  </button>
                </div>
              </div>  
            </div>
          </div>

            
              
           

            <div className={styles.section}>
            <h3 className={styles.sectionTitle}>
              Recipients {errors.recipients && <span className={styles.errorMessage}>{errors.recipients}</span>}
            </h3>
            <div className={styles.recipientGroup}>
              {recipients.map((recipient, index) => (
                <div key={index} className={styles.recipientTag}>
                  <div className={styles.recipientAddress}>{recipient.address}</div>
                  <button 
                    type="button" 
                    className={styles.delRecipientButton}
                    onClick={() => handleRemoveRecipient(recipient.address)}
                  >
                    <CrossIcon/>
                  </button>
                </div>
              ))}
              <button type="button" className={styles.addRecipientButton} onClick={handleOpenRecipientModal}>
                <PlusIcon />
              </button>
            </div>
          </div>

           

            <div className={stylesNewForm.buttonSection}>            
              <button type="submit" className={stylesNewForm.submitButton} onClick={handleSubmit}>
                Send
              </button>
            </div>

          
          
        </div>

        {isRecipientModalOpen && (
          <RecipientPicker
            onClose={() => setIsRecipientModalOpen(false)}
            onAddRecipients={handleAddRecipients}
            currentRecipients={recipients.map(r => r.address)}
          />
        )}

      <DateTimePickerContainer
        isOpen={isPickerOpen}
        currentPicker={currentPicker}
        calendarType={timePickerType}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}       // Добавляем
        endTime={endTime}          // Добавляем
        selectedDate={timePickerType === "start" ? startDate : endDate}
        onClose={() => {
          setIsPickerOpen(false);
          setCurrentPicker(null);
          setIsFirstClick(false);
        }}
        onDateSelect={handleDateSelect}
        onTimeSelect={handleTimeSelect}
      />
      </div>
    
  );
};

export default NewCustomMessage;
