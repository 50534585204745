// src/services/api/CommunityApi.ts
import { ApiService } from '../ApiService';
import { decrypt, encryptAES } from '../../../src/pages/srp-lib/srp';

// Интерфейсы для типизации данных
interface Building {
  build_id: string;
  street: string;
  number: string;
}

export interface Community {
  id: string;
  comm_buildings: Building[];
}

export interface DecryptedBuilding {
  // Используем build_id как основной идентификатор
  build_id: string;       // Числовой ID здания
  community_id: string;   // Числовой ID сообщества
  street: string;        
  number: string;        
  fullAddress: string;   // Для отображения в UI
}

export interface AddBuildingParams {
  country: string;
  region?: string;
  city: string;
  street: string;
  number: string;
  liter?: string;
}

export const COMMUNITY_ERRORS = {
  THROTTLED: 'Слишком частые запросы (не чаще одного раза в 10 секунд)',
  BAD_REQUEST: 'Неверный метод запроса',
  MISSING_TOKEN: 'Отсутствует или пустой токен',
  MISSING_COMMUNITY_ID: 'Отсутствует или пустой ID сообщества',
  MISSING_COUNTRY: 'Отсутствует или пустая страна',
  MISSING_CITY: 'Отсутствует или пустой город',
  MISSING_STREET: 'Отсутствует или пустая улица',
  MISSING_NUMBER: 'Отсутствует или пустой номер',
  SESSION_NOT_FOUND: 'Не найдена сессия администратора по токену',
  ACCESS_DENIED: 'У администратора нет доступа к управлению данным сообществом'
};

export class CommunityApi {
  private static getKKey(): string | null {
    return localStorage.getItem("K_Key");
  }

  static async getList(): Promise<Community[]> {
    const K_Key = localStorage.getItem("K_Key");
    if (!K_Key) throw new Error('K_Key is missing');
  
    const response = await ApiService.request<Community[]>({
      url: "https://mileva.rs/admin/community/get/list",
      method: 'GET',
      headers: {
        'token': localStorage.getItem("authToken") || ''
      }
    });
  
    if (!response.success || !response.data) {
      throw new Error(response.error || 'Failed to fetch buildings');
    }
  
    // Преобразуем и расшифровываем данные с сохранением исходной структуры
    return response.data.map(community => {
      const decryptedCommunityId = decrypt(K_Key, community.id, "id") || '';
      
      const decryptedBuildings = community.comm_buildings.map(building => {
        const decryptedBuildId = decrypt(K_Key, building.build_id, "build_id") || '';
        const decryptedStreet = decrypt(K_Key, building.street, "street") || '';
        const decryptedNumber = decrypt(K_Key, building.number, "number") || '';
  
        return {
          build_id: decryptedBuildId,
          street: decryptedStreet,
          number: decryptedNumber
        };
      });
  
      return {
        id: decryptedCommunityId,
        comm_buildings: decryptedBuildings
      };
    });
  }

  static async addBuilding(communityId: string, buildingData: AddBuildingParams): Promise<void> {
    const K_Key = this.getKKey();
    if (!K_Key) {
      throw new Error('K_Key is missing');
    }

    // Шифруем данные здания
    const encryptedData = {
      country: encryptAES(K_Key, buildingData.country) || '',
      city: encryptAES(K_Key, buildingData.city) || '',
      street: encryptAES(K_Key, buildingData.street) || '',
      number: encryptAES(K_Key, buildingData.number) || ''
    } as AddBuildingParams;

    // Добавляем опциональные поля если они есть
    if (buildingData.region) {
      const encryptedRegion = encryptAES(K_Key, buildingData.region);
      if (encryptedRegion) {
        encryptedData.region = encryptedRegion;
      }
    }

    if (buildingData.liter) {
      const encryptedLiter = encryptAES(K_Key, buildingData.liter);
      if (encryptedLiter) {
        encryptedData.liter = encryptedLiter;
      }
    }

    const encryptedCommunityId = encryptAES(K_Key, communityId);
    if (!encryptedCommunityId) {
      throw new Error('Failed to encrypt community ID');
    }

    const response = await ApiService.request<{ response: string }>({
      url: "https://mileva.rs/admin/community/building/add",
      method: 'POST',
      headers: {
        'community-id': encryptedCommunityId
      },
      body: encryptedData
    });

    if (!response.success) {
      const errorMessage = COMMUNITY_ERRORS[response.error as keyof typeof COMMUNITY_ERRORS] 
        || response.error 
        || 'Неизвестная ошибка';
      
      throw new Error(errorMessage);
    }
  }
}