import React from 'react';
import styles from './styles/PollsList.module.css';
import rightArrowIcon from '../svg_images/rightGreenArrowIcon.svg';

export interface Poll {
  id: string;
  title: string;
  status: 'Open' | 'Closed';
  creationDate: Date;
  votingEndsAt: Date;
  respondsCount: number;
}

interface PollsListProps {
  polls: Poll[];
  onPollClick: (poll: Poll) => void;
  onCreatePoll: () => void;
}

const PollsList: React.FC<PollsListProps> = ({ polls, onPollClick, onCreatePoll }) => {
  return (
    <div className={styles.container}>
      {polls.length > 0 ? (
        polls.map(poll => (
          <div
            key={poll.id}
            className={styles.pollContainer}
            onClick={() => onPollClick(poll)}
          >
            <div className={styles.title}>{poll.title}</div>
            <div className={styles.pollItem}>
              <div className={styles.creationDate}>{poll.creationDate.toLocaleDateString()}</div>
              <div
                className={`${styles.status} ${
                  poll.status === 'Open' ? styles.statusOpen : styles.statusClosed
                }`}
              >
                {poll.status}
              </div>
              <div className={styles.votingInfo}>
                {poll.status === 'Open'
                  ? `Online voting until ${poll.votingEndsAt.toLocaleString()}`
                  : `Online voting ended ${poll.votingEndsAt.toLocaleString()}`}
              </div>
              <div className={styles.responds}>
                {`${poll.respondsCount} responds`}
              </div>
              <img src={rightArrowIcon} alt="Details" className={styles.chevronIcon} />
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noPolls}>No polls available</div>
      )}
    </div>
  );
};

export default PollsList;