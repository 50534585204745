// AlertDetails.tsx
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import styles from './modules/AlertDetails.module.css';
import { decrypt, encryptAES } from "../../srp-lib/srp";
import backIcon from '../svg_images/backIcon.svg';
import editIcon from '../svg_images/editIcon.svg';
import electricityIcon from './images/electricityIcon.png';
import waterIcon from './images/whaterIcon.png';
import otherWorksIcon from './images/otherWorksIcon.png';
import { AlertsApi } from '../../../services/api/AlertsApi';

interface AlertDetails {
  id: string;
  title: string;
  description: string;
  type: string;
  creationDate: Date;
  startDate: Date;
  endDate: Date;
}

interface AlertDetailsProps {
  alertId: string;
  communityId: string;
  onBack: () => void;
}

const AlertDetails: React.FC<AlertDetailsProps> = ({ alertId, communityId, onBack }) => {
  const [alertDetails, setAlertDetails] = useState<AlertDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getTypeIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'electricity':
        return electricityIcon;
      case 'water':
        return waterIcon;
      default:
        return otherWorksIcon;
    }
  };

  const getTypeText = (type: string) => {
    switch (type.toLowerCase()) {
      case 'electricity':
        return 'Technical Works';
      case 'water':
        return 'Water';
      default:
        return 'Technical Works';
    }
  };

  useEffect(() => {
    const fetchAlertDetails = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const alertDetail = await AlertsApi.getAlertDetails(communityId, alertId);

        const processedDetails: AlertDetails = {
          id: alertDetail.id,
          title: alertDetail.title,
          description: alertDetail.description || '',
          type: alertDetail.type,
          creationDate: new Date(alertDetail.createdAt),
          startDate: new Date(alertDetail.startingAt),
          endDate: new Date(alertDetail.endingAt)
        };

        setAlertDetails(processedDetails);
      } catch (error) {
        console.error("Error fetching alert details:", error);
        setError(error instanceof Error ? error.message : 'Failed to fetch alert details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAlertDetails();
  }, [alertId, communityId]);

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div style={{color: 'red'}}>{error}</div>;
  }

  if (!alertDetails) {
    return null;
  }


  return (
    <div className={styles.container}>
         <button className={styles.backButton} onClick={onBack}>
      <img src={backIcon} alt="Back" />
      Back
    </button>
    
    <div className={styles.infoRow}>
      <div className={styles.leftInfo}>
        <span className={styles.creationInfo}>
          Created on {format(alertDetails.creationDate, 'dd.MM.yyyy')} at {format(alertDetails.creationDate, 'HH:mm')}
        </span>
        <div className={styles.typeInfo}>
          <img 
            src={getTypeIcon(alertDetails.type)} 
            alt={alertDetails.type}
            className={styles.typeIcon}
          />
          <span className={styles.typeText}>{getTypeText(alertDetails.type)}</span>
        </div>
      </div>

      <button className={styles.editButton}>
        Edit
        <img src={editIcon} alt="Edit" />
      </button>
    </div>

    <h1 className={styles.title}>{alertDetails.title}</h1>

      <div className={styles.dateSection}>
        <div className={styles.dateBlock}>
          <h3>Starts</h3>
          <p>{format(alertDetails.startDate, 'EEEE, MMM d, yyyy   ')}
             {format(alertDetails.startDate, 'HH:mm')}</p>
        </div>

        <div className={styles.dateBlock}>
          <h3>Ends</h3>
          <p>{format(alertDetails.endDate, 'EEEE, MMM d, yyyy   ')}
             {format(alertDetails.endDate, 'HH:mm')}</p>
        </div>
      </div>

      <div className={styles.description}>
        <p>{alertDetails.description}</p>
      </div>
    </div>
  );
};

export default AlertDetails;