import React from 'react';
import Modal from './Modal';
import styles from './Modal.module.css';

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
  altText?: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, imageUrl, altText }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <img
        src={imageUrl}
        alt={altText || 'Full size image'}
        className={styles.modalImage}
      />
    </Modal>
  );
};

export default ImageModal;