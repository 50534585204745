import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Variable } from './types'

interface PlaceholderPosthocOptions {
  variables: Variable[]
}

export const PlaceholderPosthocExtension = Extension.create<PlaceholderPosthocOptions>({
  name: 'placeholderPosthoc',

  addOptions() {
    return {
      variables: [],
    }
  },

  addProseMirrorPlugins() {
    const variables = this.options.variables;

    return [
      new Plugin({
        key: new PluginKey('placeholderPosthoc'),
        appendTransaction(transactions, oldState, newState) {
          if (!transactions.some(tr => tr.docChanged)) return null;

          const { doc } = newState;
          const tr = newState.tr;

          const pattern = new RegExp(
            `\\[(${variables.map(v => v.key).join('|')})\\]`,
            'g'
          );

          doc.descendants((node, pos) => {
            if (!node.isText) return;
            const text = node.text || "";
            
            let match;
            while ((match = pattern.exec(text)) !== null) {
              const varKey = match[1];
              const matchingVar = variables.find(v => v.key === varKey);
              
              if (matchingVar) {
                const startPos = pos + match.index;
                const endPos = startPos + match[0].length;

                // Используем тот же тип ноды "variable", что и в PlaceholderVar
                tr.replaceWith(
                  startPos,
                  endPos,
                  newState.schema.nodes.variable.create({
                    varKey: matchingVar.key,
                    displayValue: matchingVar.value || `[${matchingVar.key}]`
                  })
                );
              }
            }
          });

          return tr.docChanged ? tr : null;
        },
      }),
    ]
  },
})