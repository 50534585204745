import React, { ReactNode } from 'react';
import { AuthtorizationProvider } from './AuthorizationContext';
import { BuildingProvider } from './BuildingContext';

interface GlobalProviderProps {
  children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  return (
    <AuthtorizationProvider>      
      <BuildingProvider>
        {children}
      </BuildingProvider>   
    </AuthtorizationProvider>
  );
};